import { Component, Input, OnInit } from '@angular/core';
import { AnnualWellnessVisitFormComponent } from '../annual-wellness-visit-form.component';
import { Step7, fullForm } from '../annualwellness.model';
import { ActivatedRoute } from '@angular/router';
import { AnnualWellnessService } from '../annualwellness.service';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-nutrition',
  templateUrl: './nutrition.component.html',
  styleUrls: ['./nutrition.component.scss']
})
export class NutritionComponent implements OnInit {
  @Input() isClinicView: boolean = true;

  constructor(
    private layout: AnnualWellnessVisitFormComponent,
    private AnnualWellnessService: AnnualWellnessService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router
  ) { }

  fullForm: fullForm;
  id: string = '';
  step7: Step7 = {
    eatFastfood: '',
    drinkSodas: '',
    eatFruits: '',
    lessTwoMeals: '',
    enoughMoney: '',
    nutritionComment: '',
    exercise: '',
    weekExercise: '',
    longExercise: '',
    typicalExercise: '',
    physicalComment: ''
  };

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    if(this.isClinicView){
      this.GetPatientdetails();
    }
    else{
      this.GetDataForClinic();
    }
  }

  GetPatientdetails() {
    let dateOfBirth = this.auth.getItem("dateOfBirth");
    this.AnnualWellnessService.getAWVDataForPatient(this.id, dateOfBirth).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step7 = {
        eatFastfood: results.step7.eatFastfood || '',
        drinkSodas: results.step7.drinkSodas || '',
        eatFruits: results.step7.eatFruits || '',
        lessTwoMeals: results.step7.lessTwoMeals || '',
        enoughMoney: results.step7.enoughMoney || '',
        nutritionComment: results.step7.nutritionComment || '',
        exercise: results.step7.exercise || '',
        weekExercise: results.step7.weekExercise || '',
        longExercise: results.step7.longExercise || '',
        typicalExercise: results.step7.typicalExercise || '',
        physicalComment: results.step7.physicalComment || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/questionnaires/medical-annual-wellness-visit/'+ this.id]);
      }
    );
  }

  GetDataForClinic() {
    this.AnnualWellnessService.getAWVDataForClinic(this.id).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step7 = {
        eatFastfood: results.step7.eatFastfood || '',
        drinkSodas: results.step7.drinkSodas || '',
        eatFruits: results.step7.eatFruits || '',
        lessTwoMeals: results.step7.lessTwoMeals || '',
        enoughMoney: results.step7.enoughMoney || '',
        nutritionComment: results.step7.nutritionComment || '',
        exercise: results.step7.exercise || '',
        weekExercise: results.step7.weekExercise || '',
        longExercise: results.step7.longExercise || '',
        typicalExercise: results.step7.typicalExercise || '',
        physicalComment: results.step7.physicalComment || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/forms']);
      }
    );
  }

  save() {
    this.fullForm.step7 = this.step7;
    this.AnnualWellnessService.submitAWVDataForPatient(
      this.id,
      this.fullForm
    ).subscribe(
      (results) => {
        this.layout.onNextComponentClick();

      },
      (error) => { }
    );
  }
  getData() {
    return this.step7;
  }
}

