import { Component, Input, OnInit } from '@angular/core';
import { AnnualWellnessVisitFormComponent } from '../annual-wellness-visit-form.component';
import { ActivatedRoute } from '@angular/router';
import { AnnualWellnessService } from '../annualwellness.service';
import { Step8, fullForm } from '../annualwellness.model';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home-safety',
  templateUrl: './home-safety.component.html',
  styleUrls: ['./home-safety.component.scss']
})
export class HomeSafetyComponent implements OnInit {
  @Input() isClinicView: boolean = true;

  constructor(
    private layout: AnnualWellnessVisitFormComponent,
    private AnnualWellnessService: AnnualWellnessService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router
  ) { }

  fullForm: fullForm;
  id: string = '';
  step8: Step8 = {
    carbonMonoxide: '',
    seatBealt: '',
    liveAlone: '',
    homeComment: '',
    liveWith: '',
    liveIn: '',
    liveInComment: '',
    medicalPower: '',
    advancedDirective: '',
    careDirective: '',
    careplanningComment: ''
  };

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    if(this.isClinicView){
      this.GetPatientdetails();
    }
    else{
      this.GetDataForClinic();
    }
  }

  GetPatientdetails() {
    let dateOfBirth = this.auth.getItem("dateOfBirth");
    this.AnnualWellnessService.getAWVDataForPatient(this.id, dateOfBirth).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step8 = {
        carbonMonoxide: results.step8.carbonMonoxide || '',
        seatBealt: results.step8.seatBealt || '',
        liveAlone: results.step8.liveAlone || '',
        homeComment: results.step8.homeComment || '',
        liveWith: results.step8.liveWith || '',
        liveIn: results.step8.liveIn || '',
        liveInComment: results.step8.liveInComment || '',
        medicalPower: results.step8.medicalPower || '',
        advancedDirective: results.step8.advancedDirective || '',
        careDirective: results.step8.careDirective || '',
        careplanningComment: results.step8.careplanningComment || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/questionnaires/medical-annual-wellness-visit/'+ this.id]);
      }
    );
  }

  GetDataForClinic() {
    this.AnnualWellnessService.getAWVDataForClinic(this.id).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step8 = {
        carbonMonoxide: results.step8.carbonMonoxide || '',
        seatBealt: results.step8.seatBealt || '',
        liveAlone: results.step8.liveAlone || '',
        homeComment: results.step8.homeComment || '',
        liveWith: results.step8.liveWith || '',
        liveIn: results.step8.liveIn || '',
        liveInComment: results.step8.liveInComment || '',
        medicalPower: results.step8.medicalPower || '',
        advancedDirective: results.step8.advancedDirective || '',
        careDirective: results.step8.careDirective || '',
        careplanningComment: results.step8.careplanningComment || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/forms']);
      }
    );
  }

  save() {
    this.fullForm.step8 = this.step8;
    this.AnnualWellnessService.submitAWVDataForPatient(
      this.id,
      this.fullForm
    ).subscribe(
      (results) => {
        this.layout.onNextComponentClick();
      },
      (error) => { }
    );
  }
  getData() {
    return this.step8;
  }
}

