<link href='https://fonts.googleapis.com/css?family=Noto Sans' rel='stylesheet'>
<div id="medication">
  <h2 class="blue-header">Medication adherence</h2>
  <div class="mt-3">
    <label for="" class="text-20-bold-600 label-font-14">Do you take your medications as you have been told to take
      them?</label>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationTake1" name="medicationTakeTime" [(ngModel)]="step2.medicationTakeTime" value="I_ALWAYS_TAKE">
        <label for="medicationTake1">I always take my medication as prescribed</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationTake2" name="medicationTakeTime" [(ngModel)]="step2.medicationTakeTime" value="SOMETIMES_I_TAKE">
        <label for="medicationTake2">Sometimes I take my medication as prescribed</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationTake3" name="medicationTakeTime" [(ngModel)]="step2.medicationTakeTime" value="HARD_TIME_TAKING">
        <label for="medicationTake3">I have hard time taking my medication as prescribed</label>
      </div>
    </div>
    <div class="wrap-box">
      <label for="" class="label-font-14">Comments
      </label>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" name="comment1" [(ngModel)]="step2.medicationTimeComment">
    </div>
  </div>

  <div class="mt-3">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 1.</label>
        <label for=""> Do you forget to take your medication?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationRadio11" name="forgetTakeMedication" [(ngModel)]="step2.forgetTakeMedication" value="YES">
        <label for="medicationRadio11">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationRadio12" name="forgetTakeMedication" [(ngModel)]="step2.forgetTakeMedication" value="NO">
        <label for="medicationRadio12">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 2.</label>
        <label for=""> Are confused about how to take your medication?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationRadio21" name="confusedAboutMedication" [(ngModel)]="step2.confusedAboutMedication" value="YES">
        <label for="medicationRadio21">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationRadio22" name="confusedAboutMedication" [(ngModel)]="step2.confusedAboutMedication" value="NO">
        <label for="medicationRadio22">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 3.</label>
        <label for=""> Do you have a hard time opening the bottles or to
          self administer the medication?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationRadio31" name="hardTime" [(ngModel)]="step2.hardTime" value="YES">
        <label for="medicationRadio31">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationRadio32" name="hardTime" [(ngModel)]="step2.hardTime" value="NO">
        <label for="medicationRadio32">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 4.</label>
        <label for=""> Do you know what your medication is for?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationRadio41" name="knowYourMedication" [(ngModel)]="step2.knowYourMedication" value="YES">
        <label for="medicationRadio41">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationRadio42" name="knowYourMedication" [(ngModel)]="step2.knowYourMedication" value="NO">
        <label for="medicationRadio42">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 5.</label>
        <label for="">Are you worried or are you experiencing side effects?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationRadio51" name="sideEffects" [(ngModel)]="step2.sideEffects" value="YES">
        <label for="medicationRadio51">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationRadio52" name="sideEffects" [(ngModel)]="step2.sideEffects" value="NO">
        <label for="medicationRadio52">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 6.</label>
        <label for=""> Do you find your medication too expensive?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationRadio61" name="medicationExpensive" [(ngModel)]="step2.medicationExpensive" value="YES">
        <label for="medicationRadio61">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationRadio62" name="medicationExpensive" [(ngModel)]="step2.medicationExpensive" value="NO">
        <label for="medicationRadio62">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 7.</label>
        <label for=""> Does your pharmacy run out of your medication
          sometimes?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationRadio71" name="pharmacyRunout" [(ngModel)]="step2.pharmacyRunout" value="YES">
        <label for="medicationRadio71">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="medicationRadio72" name="pharmacyRunout" [(ngModel)]="step2.pharmacyRunout" value="NO">
        <label for="medicationRadio72">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <label for="" class="label-font-14">Comments
      </label>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" name="medicationComment" [(ngModel)]="step2.medicationComment">
    </div>
  </div>

  <h2 class="blue-header">ADL functional assessment</h2>
  <div class="mt-3">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 1.</label>
        <label for=""> Can you stand from a sitting position?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio11" name="adlSitting" [(ngModel)]="step2.adlSitting" value="YES">
        <label for="ADLfRadio11">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio12" name="adlSitting" [(ngModel)]="step2.adlSitting" value="NO">
        <label for="ADLfRadio12">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 2.</label>
        <label for=""> Are you able to walk outside of the house?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio21" name="adlWalkoutside" [(ngModel)]="step2.adlWalkoutside" value="YES">
        <label for="ADLfRadio21">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio22" name="adlWalkoutside" [(ngModel)]="step2.adlWalkoutside" value="NO">
        <label for="ADLfRadio22">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 3.</label>
        <label for=""> Can you eat meals unassisted?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio31" name="adlEatmeals" [(ngModel)]="step2.adlEatmeals" value="YES">
        <label for="ADLfRadio31">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio32" name="adlEatmeals" [(ngModel)]="step2.adlEatmeals" value="NO">
        <label for="ADLfRadio32">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 4.</label>
        <label for=""> Can you bathe unassisted?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio41" name="bathUnassisted" [(ngModel)]="step2.bathUnassisted" value="YES">
        <label for="ADLfRadio41">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio42" name="bathUnassisted" [(ngModel)]="step2.bathUnassisted" value="NO">
        <label for="ADLfRadio42">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 5.</label>
        <label for=""> Are you able to organize your day?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio51" name="organizeDay" [(ngModel)]="step2.organizeDay" value="YES">
        <label for="ADLfRadio51">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio52" name="organizeDay" [(ngModel)]="step2.organizeDay" value="NO">
        <label for="ADLfRadio52">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 6.</label>
        <label for=""> Are you able to walk in the house?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio61" name="walkinHouse" [(ngModel)]="step2.walkinHouse" value="YES">
        <label for="ADLfRadio61">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio62" name="walkinHouse" [(ngModel)]="step2.walkinHouse" value="NO">
        <label for="ADLfRadio62">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 7.</label>
        <label for="">Can you get dressed unassisted?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio71" name="dressedUnassisted" [(ngModel)]="step2.dressedUnassisted" value="YES">
        <label for="ADLfRadio71">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio72" name="dressedUnassisted" [(ngModel)]="step2.dressedUnassisted" value="NO">
        <label for="ADLfRadio72">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 8.</label>
        <label for=""> Can you use the toilet unassisted?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio81" name="toiletUnassisted" [(ngModel)]="step2.toiletUnassisted" value="YES">
        <label for="ADLfRadio81">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio82" name="toiletUnassisted" [(ngModel)]="step2.toiletUnassisted" value="NO">
        <label for="ADLfRadio82">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 9.</label>
        <label for=""> If you answered "no” to one of the questions, do you
          have anyone who can assist you?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio91" name="answeredNo" [(ngModel)]="step2.answeredNo" value="YES">
        <label for="ADLfRadio91">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio92" name="answeredNo" [(ngModel)]="step2.answeredNo" value="NO">
        <label for="ADLfRadio92">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 10.</label>
        <label for="">In the past 6 months, have you experienced leaking of
          urine?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio101" name="leakingUrine" [(ngModel)]="step2.leakingUrine" value="YES">
        <label for="ADLfRadio101">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLfRadio102" name="leakingUrine" [(ngModel)]="step2.leakingUrine" value="NO">
        <label for="ADLfRadio102">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <label for="" class="label-font-14">Comments
      </label>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" name="functionalComment" [(ngModel)]="step2.functionalComment">
    </div>
  </div>

  <h2 class="blue-header">ADL instrumental assessment</h2>
  <div class="mt-3">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 1.</label>
        <label for=""> Can you or someone shop for your groceries?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLiRadio11" name="shopGroceries" [(ngModel)]="step2.shopGroceries" value="YES">
        <label for="ADLiRadio11">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLiRadio12" name="shopGroceries" [(ngModel)]="step2.shopGroceries" value="NO">
        <label for="ADLiRadio12">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 2.</label>
        <label for=""> Are you able to drive or use public transport?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLiRadio21" name="publicTransport" [(ngModel)]="step2.publicTransport" value="YES">
        <label for="ADLiRadio21">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLiRadio22" name="publicTransport" [(ngModel)]="step2.publicTransport" value="NO">
        <label for="ADLiRadio22">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 3.</label>
        <label for=""> Can you make phone calls unassisted?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLiRadio31" name="phonecallsUnassisted" [(ngModel)]="step2.phonecallsUnassisted" value="YES">
        <label for="ADLiRadio31">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLiRadio32" name="phonecallsUnassisted" [(ngModel)]="step2.phonecallsUnassisted" value="NO">
        <label for="ADLiRadio32">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 4.</label>
        <label for=""> Are you able to cook?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLiRadio41" name="ableToCook" [(ngModel)]="step2.ableToCook" value="YES">
        <label for="ADLiRadio41">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLiRadio42" name="ableToCook" [(ngModel)]="step2.ableToCook" value="NO">
        <label for="ADLiRadio42">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 5.</label>
        <label for=""> Can you do housework?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLiRadio51" name="doHousework" [(ngModel)]="step2.doHousework" value="YES">
        <label for="ADLiRadio52">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLiRadio52" name="doHousework" [(ngModel)]="step2.doHousework" value="NO">
        <label for="ADLiRadio52">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 6.</label>
        <label for=""> Can you take your medications?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLiRadio61" name="takeMedication" [(ngModel)]="step2.takeMedication" value="YES">
        <label for="ADLiRadio61">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLiRadio62" name="takeMedication" [(ngModel)]="step2.takeMedication" value="NO">
        <label for="ADLiRadio62">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for="" class="listnumber"> 7.</label>
        <label for=""> Can you handle your finances?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLiRadio71" name="handleFinances" [(ngModel)]="step2.handleFinances" value="YES">
        <label for="ADLiRadio71">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ADLiRadio72" name="handleFinances" [(ngModel)]="step2.handleFinances" value="NO">
        <label for="ADLiRadio72">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <label for="" class="label-font-14">Comments
      </label>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" name="instrumentalComment" [(ngModel)]="step2.instrumentalComment">
    </div>
  </div>
  <div class="wrap-box" *ngIf="isClinicView">
    <button class="btn-save" (click)="save()">Save and continue</button>
  </div>
</div>
