import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-rtm-pain',
  templateUrl: './rtm-pain.component.html',
  styleUrls: ['./rtm-pain.component.scss']
})
export class RtmPainComponent implements OnInit {
  components = [
    'app-rtm-pain-form'
  ];
  currentIndex = -1;
  id:string='';
  stepNumber:string ='';

  ngOnInit() {
  }
  @HostListener('window:popstate', ['$event'])

  showComponent(index: number) {
    this.currentIndex = index;
  }

  onNextComponentClick() {
    window.scroll(0, 0);
    if (this.currentIndex < this.components.length - 1) {
        this.showComponent(this.currentIndex + 1);
      }
  }
}
