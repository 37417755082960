<div id="hearing">
  <h2 class="blue-header">Hearing impairment</h2>
  <div class="mt-3">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 1.</label>
        <label for=""> Do you currently wear a hearing aid? (If yes, the
          following questions should be answered wearing the
          hearing aid.)</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio11" name="wearHearing" [(ngModel)]="step5.wearHearing" value="YES">
        <label for="HearingRadio11">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio12" name="wearHearing" [(ngModel)]="step5.wearHearing" value="NO">
        <label for="HearingRadio12">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 2.</label>
        <label for=""> Do you currently have a hearing problem or do you feel
          hearing aid is not addressing the issue?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio21" name="feelHearing" [(ngModel)]="step5.feelHearing" value="YES">
        <label for="HearingRadio21">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio22" name="feelHearing" [(ngModel)]="step5.feelHearing" value="NO">
        <label for="HearingRadio22">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 3.</label>
        <label for=""> Does a hearing problem cause you to feel embarassed
          when you meet new people?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio31" name="feelEmbarassed" [(ngModel)]="step5.feelEmbarassed" value="YES">
        <label for="HearingRadio31">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio32" name="feelEmbarassed" [(ngModel)]="step5.feelEmbarassed" value="NO">
        <label for="HearingRadio32">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 4.</label>
        <label for=""> Do you feel frustrated when talking to members of
          your family?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio41" name="feelFrustrated" [(ngModel)]="step5.feelFrustrated" value="FREQUENTLY">
        <label for="HearingRadio41">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio42" name="feelFrustrated" [(ngModel)]="step5.feelFrustrated" value="OFTEN">
        <label for="HearingRadio42">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio43" name="feelFrustrated" [(ngModel)]="step5.feelFrustrated" value="RARELY">
        <label for="HearingRadio43">Rarely</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 5.</label>
        <label for=""> Do you have a hard time hearing when someone speaks
          in a whisper?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio51" name="hardtimeHearing" [(ngModel)]="step5.hardtimeHearing" value="FREQUENTLY">
        <label for="HearingRadio51">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio52" name="hardtimeHearing" [(ngModel)]="step5.hardtimeHearing" value="OFTEN">
        <label for="HearingRadio52">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio53" name="hardtimeHearing" [(ngModel)]="step5.hardtimeHearing" value="RARELY">
        <label for="HearingRadio53">Rarely</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 6.</label>
        <label for=""> Does a hearing problem cause you difficulty when visting
          friends, relatives, or neighbors?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio61" name="visitingFriends" [(ngModel)]="step5.visitingFriends" value="FREQUENTLY">
        <label for="HearingRadio61">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio62" name="visitingFriends" [(ngModel)]="step5.visitingFriends" value="OFTEN">
        <label for="HearingRadio62">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio63" name="visitingFriends" [(ngModel)]="step5.visitingFriends" value="RARELY">
        <label for="HearingRadio63">Rarely</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 7.</label>
        <label for=""> Does a hearing problem cause you to have arguments
          with family members?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio71" name="argumentsWithFamily" [(ngModel)]="step5.argumentsWithFamily" value="FREQUENTLY">
        <label for="HearingRadio71">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio72" name="argumentsWithFamily" [(ngModel)]="step5.argumentsWithFamily" value="OFTEN">
        <label for="HearingRadio72">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio73" name="argumentsWithFamily" [(ngModel)]="step5.argumentsWithFamily" value="RARELY">
        <label for="HearingRadio73">Rarely</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 8.</label>
        <label for=""> Does a hearing problem cause you difficulty when
          listening to TV or radio?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio81" name="listeningTv" [(ngModel)]="step5.listeningTv" value="FREQUENTLY">
        <label for="HearingRadio81">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio82" name="listeningTv" [(ngModel)]="step5.listeningTv" value="OFTEN">
        <label for="HearingRadio82">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="HearingRadio83" name="listeningTv" [(ngModel)]="step5.listeningTv" value="RARELY">
        <label for="HearingRadio83">Rarely</label>
      </div>
    </div>
    <div class="wrap-box">
      <label class="label-font-14">Comments
      </label>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" name="hearingComment" [(ngModel)]="step5.hearingComment">
    </div>
  </div>
  <div class="wrap-box" *ngIf="isClinicView">
    <button class="btn-save" (click)="save()">Save and continue</button>
  </div>
</div>
