import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PatientsService } from '@app/patients/patients.service';
import { SharedService } from '@app/shared/shared.service';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { AnnualWellnessService } from '../annual-wellness/annual-wellness.service';
import { MarkedCardiacList } from '@app/annual-wellness/annual-wellness.model';
//import * as Chartist from 'chartist';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  loading = false;
  summary = {
    clinicId: 0,
    clinicCode: "",
    clinicName: "",
    noOfEnrolledPatients: 0,
    noOfRPMEnrolledPatients: 0,
    noOfActiveRPMPatients: 0,
    noOfCCMEnrolledPatients: 0,
    noOfPCMEnrolledPatients: 0,
    noOfEnrolledPatientsInPast30Days: 0,
    noOfEnrolledPatientsInPast60Days: 0,
    noOfEnrolledPatientsInPast90Days: 0,
    noOfNonCompliantRPMPatients: 0,
    noOfPatientsWithRedStatus: 0,
    noOfPatientsWithNewMeasuerements: 0,
    followUpWithRPMNonCompliantPatients: 0,
    noOfRPMPatientsRequireCommunication: 0,
    totalMinutesThisMonth: 0,
    totalMinutesPreviousMonth: 0,
    noOfPatientsWithAlerts: 0,
    noOfPatientsWithAlertsPatientIds: "",
    totalBPDeviceCount: 0,
    totalGlucometerDeviceCount: 0,
    totalPulseoxDeviceCount: 0,
    totalScaleDeviceCount: 0,
    totalThermometerDeviceCount: 0,
    billingMinutesUnder5: 0,
    billingMinutes6to10: 0,
    billingMinutes11to15: 0,
    billingMinutes16to19: 0,
    noofPendingTask: 0,
    noOfUnreadMessages: 0,
    frequentIHRCount: 0,
    billingMinutes20Above: 0,
    bookmarkedPatients: 0,
    totalIsCGMEnrolledCount: 0
  };
  dashboardSummaryBS$ = new BehaviorSubject<any>(null);
  loadSummaryDetailsSubscriber: any;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private annualWellnessService: AnnualWellnessService
  ) {

  }

  markedCardiacData: MarkedCardiacList = {
      form: '',
      dateOfBirth: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      markedCardiacScreeningCount: 0
  }

  ngOnInit() {
    this.getDashboardSummary();
    this.getMarkedCardiacCount();
  }

  getDashboardSummary() {
    this.loading = true;

    this.loadSummaryDetailsSubscriber = this.sharedService.dashboardSummaryBS$.subscribe(x => {
      if (x) {
        this.summary = x;
      }
      this.loading = false;
    });
  }

  getMarkedCardiacCount(){
    this.annualWellnessService.markedCardiacScreening().subscribe(
      (re) => {
        this.markedCardiacData = re;
      },
      (error) => {
      }
    );
  }
}
