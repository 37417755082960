import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '@app/users/user.service';
import { CardiacScreenFormData ,CardiacFormHeading} from './cardiac-screening.model';


@Injectable({
  providedIn: 'root'
})
export class CardiacScreeningService {
  formApiUrl: string;

  constructor(private http: HttpClient, private userService: UserService) {
    this.formApiUrl = environment.formApiUrl;
  }


  // save cardiac screen data for for patient
  SaveCardiacform(id: string, formData: CardiacScreenFormData): Observable<CardiacScreenFormData> {
    var url = `${this.formApiUrl}/forms/response/cardiac-screening/post/${id}`
    return this.http.post<CardiacScreenFormData>(url, formData);
  }

  // get cardiac screen data for patient
  GetCardiacform(id: string, dateofbirth: string): Observable<CardiacScreenFormData> {
    var url = `${this.formApiUrl}/forms/response/cardiac-screening/get/${id}`
    let patient = {
      "patient": {
        "dateOfBirth": dateofbirth
        }
      }
    return this.http.post<CardiacScreenFormData>(url, patient);
  }

  GetHeadingData(id: string): Observable<CardiacFormHeading> {
    var url = `${this.formApiUrl}/forms/head/cardiac-screening/${id}`
    return this.http.get<CardiacFormHeading>(url);
  }

  // get Pre op form data for clinic
  getCardiacDataForClinic(id: string): Observable<CardiacScreenFormData> {
      var url = `${this.formApiUrl}/forms/internal/adhoc/cardiac-screening/get/${id}`
      return this.http.get<CardiacScreenFormData>(url);
      }

  // Save Pre op form data for clinic
  SaveCardiacDataForClinic(id: string, formData: CardiacScreenFormData): Observable<CardiacScreenFormData> {
      var url = `${this.formApiUrl}/forms/internal/adhoc/cardiac-screening/post/${id}`
      return this.http.post<CardiacScreenFormData>(url, formData);
    }
}
