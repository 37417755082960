<div class="wrap-box" *ngIf="!isClinicView">
  <h2 class="blue-header">Referrals</h2>
</div>
<div class="" *ngIf="!isClinicView">
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="referralsCheck1" name="boneDensity" [(ngModel)]="step10.boneDensity" >
      <label for="referralsCheck1">Referral for bone density exam</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="referralsCheck2" name="bilatedEye" [(ngModel)]="step10.bilatedEye" >
      <label for="referralsCheck2">Referral for bilated eye exam</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="referralsCheck3" name="colorectalCancer" [(ngModel)]="step10.colorectalCancer" >
      <label for="referralsCheck3">Referral for colorectal cancer screening</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="referralsCheck4" name="rheumatologist" [(ngModel)]="step10.rheumatologist" >
      <label for="referralsCheck4">Referral for rheumatologist</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="referralsCheck5" name="podiatry" [(ngModel)]="step10.podiatry" >
      <label for="referralsCheck5">Referral for podiatry</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="referralsCheck6" name="nephrology" [(ngModel)]="step10.nephrology" >
      <label for="referralsCheck6">Referral for nephrology</label>
    </div>
  </div>
</div>
<div class="" *ngIf="!isClinicView">
  <div class="wrap-box">
    <label class="label-font-14">Blood tests</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck1" name="bnp" [(ngModel)]="step10.bnp" >
      <label for="BloodTestCheck1">BNP</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck2" name="cbc" [(ngModel)]="step10.cbc" >
      <label for="BloodTestCheck2">CBC</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck3" name="cbcWdiff" [(ngModel)]="step10.cbcWdiff" >
      <label for="BloodTestCheck3">CBC w/Diff</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck4" name="cmp" [(ngModel)]="step10.cmp" >
      <label for="BloodTestCheck4">CMP</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck5" name="esr" [(ngModel)]="step10.esr" >
      <label for="BloodTestCheck5">ESR</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck6" name="fastingLipid" [(ngModel)]="step10.fastingLipid" >
      <label for="BloodTestCheck6">Fasting lipid panel</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck7" name="freeT4" [(ngModel)]="step10.freeT4" >
      <label for="BloodTestCheck7">Free T-4</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck8" name="hemoglobinA1c" [(ngModel)]="step10.hemoglobinA1c" >
      <label for="BloodTestCheck8">Hemoglobin A1C</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck9" name="homocvsteine" [(ngModel)]="step10.homocvsteine" >
      <label for="BloodTestCheck9">Homocvsteine</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck10" name="iron" [(ngModel)]="step10.iron" >
      <label for="BloodTestCheck10">Iron</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck11" name="ucidAcid" [(ngModel)]="step10.ucidAcid" >
      <label for="BloodTestCheck11">Ucid acid</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck12" name="magnesium" [(ngModel)]="step10.magnesium" >
      <label for="BloodTestCheck12">Magnesium</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck13" name="vitd" [(ngModel)]="step10.vitd" >
      <label for="BloodTestCheck13">Vit D</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck14" name="phos" [(ngModel)]="step10.phos" >
      <label for="BloodTestCheck14">PHOS</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck15" name="ish" [(ngModel)]="step10.ish" >
      <label for="BloodTestCheck15">TSH</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck16" name="thyroxine" [(ngModel)]="step10.thyroxine" >
      <label for="BloodTestCheck16">Thyroxine</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="BloodTestCheck17" name="ptinr" [(ngModel)]="step10.ptinr" >
      <label for="BloodTestCheck17">PTINR</label>
    </div>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Other blood test</label>
    </div>

    <textarea class="custom-input" name="otherBloodTest" [(ngModel)]="step10.otherBloodTest"></textarea>
  </div>
</div>

<div class="" *ngIf="!isClinicView">
  <div class="wrap-box">
    <label class="label-font-14">Urine tests</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="urineTestCheck1" name="urineDrug" [(ngModel)]="step10.urineDrug" >
      <label for="urineTestCheck1">Urine drug test/conf.</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="urineTestCheck2" name="urinalysis" [(ngModel)]="step10.urinalysis" >
      <label for="urineTestCheck2">Urinalysis with reflex/culture</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="urineTestCheck3" name="occultDiood" [(ngModel)]="step10.occultDiood" >
      <label for="urineTestCheck3">Stool, occult Diood</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="urineTestCheck4" name="urineMicroalbumin" [(ngModel)]="step10.urineMicroalbumin" >
      <label for="urineTestCheck4">Urine microalbumin</label>
    </div>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Other urine test</label>
    </div>

    <textarea class="custom-input" name="otherUrineTest" [(ngModel)]="step10.otherUrineTest"></textarea>
  </div>
</div>

<div class="" *ngIf="!isClinicView">
  <div class="wrap-box">
    <label class="label-font-14">Radiology/imaging</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="radiologyCheck1" name="cxr" [(ngModel)]="step10.cxr" >
      <label for="radiologyCheck1">CXR PA/lateral views</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="radiologyCheck2" name="ultrasound" [(ngModel)]="step10.ultrasound" >
      <label for="radiologyCheck2">Ultrasound</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="radiologyCheck3" name="venousDoppler" [(ngModel)]="step10.venousDoppler" >
      <label for="radiologyCheck3">Venous doppler</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="radiologyCheck4" name="arterialDoppler" [(ngModel)]="step10.arterialDoppler">
      <label for="radiologyCheck4">Arterial doppler</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="radiologyCheck5" name="xray" [(ngModel)]="step10.xray">
      <label for="radiologyCheck5">X-ray</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="radiologyCheck6" name="mriWContrast" [(ngModel)]="step10.mriWContrast" >
      <label for="radiologyCheck6">MRI w/Contrast</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="radiologyCheck7" name="mriWoContrast" [(ngModel)]="step10.mriWoContrast" >
      <label for="radiologyCheck7">MRI w/o Contrast</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="radiologyCheck8" name="ctWContrast" [(ngModel)]="step10.ctWContrast">
      <label for="radiologyCheck8">CT w/Contrast</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="radiologyCheck9" name="ctWoContrast" [(ngModel)]="step10.ctWoContrast">
      <label for="radiologyCheck9">CT w/o Contrast</label>
    </div>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Other radiology/imaging</label>
    </div>

    <textarea class="custom-input" name="otherRadiology" [(ngModel)]="step10.otherRadiology"></textarea>
  </div>
</div>

<div class="" *ngIf="!isClinicView">
  <div class="wrap-box">
    <label class="label-font-14">Other tests</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="otherTestCheck1" name="overnightOximetry" [(ngModel)]="step10.overnightOximetry" >
      <label for="otherTestCheck1">Overnight oximetry (virtuox)</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="otherTestCheck2" name="homeSleep" [(ngModel)]="step10.homeSleep" >
      <label for="otherTestCheck2">Home sleep oxygen test (virtuox)</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="otherTestCheck3" name="mobileCardiac" [(ngModel)]="step10.mobileCardiac" >
      <label for="otherTestCheck3">Mobile cardiac telemetry (virtuox)</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="otherTestCheck4" name="overnightEeg" [(ngModel)]="step10.overnightEeg" >
      <label for="otherTestCheck4">Overnight EEG (virtuox)</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="checkbox" id="otherTestCheck5" name="leadEkg" [(ngModel)]="step10.leadEkg" >
      <label for="otherTestCheck5">12 Lead EKG (virtoux)</label>
    </div>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Other tests</label>
    </div>

    <textarea class="custom-input" name="otherTests" [(ngModel)]="step10.otherTests"></textarea>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Provider name</label>
    </div>
    <input type="text" class="custom-input" name="providerName" [(ngModel)]="step10.providerName">
  </div>
</div>
<div class="">
  <div class="wrap-box">
    <h2 class="blue-header">Additional notes</h2>
  </div>
</div>
<div class="">
  <div class="wrap-box">
    <textarea class="custom-input" name="additionalNotes" [(ngModel)]="step10.additionalNotes"></textarea>
  </div>
</div>
<div class="wrap-box" *ngIf="isClinicView">
  <button class="btn-save" (click)="save()">Submit</button>
</div>
