import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { CardiacScreeningVerifyDobComponent } from './cardiac-screening-verify-dob/cardiac-screening-verify-dob.component';
import { CardiacScreeningFormComponent } from './cardiac-screening-form/cardiac-screening-form.component';
import { CardiacScreeningComponent } from './cardiac-screening.component';
@NgModule({

  declarations: [
    CardiacScreeningVerifyDobComponent,
    CardiacScreeningFormComponent,
    CardiacScreeningComponent
  ],
  exports: [
    CardiacScreeningVerifyDobComponent,
    CardiacScreeningFormComponent,
    CardiacScreeningComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class cardiacscreeningmodule { }
