import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "@app/shared/shared.module";
import { CcmSelfReportingVitalsFormComponent } from "./ccm-self-reporting-vitals-form/ccm-self-reporting-vitals-form.component";
import { CcmVerifyDobComponent } from "./ccm-verify-dob/ccm-verify-dob.component";
import { CcmSelfReportingVitalsComponent } from "./ccm-self-reporting-vitals.component";

@NgModule({

  declarations: [
    CcmSelfReportingVitalsComponent,
    CcmSelfReportingVitalsFormComponent,
    CcmVerifyDobComponent
  ],
  exports: [
    CcmSelfReportingVitalsComponent,
    CcmSelfReportingVitalsFormComponent,
    CcmVerifyDobComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class CcmSelfReportingVitalsModule { }
