import { Component, Input, OnInit } from '@angular/core';
import { AnnualWellnessVisitFormComponent } from '../annual-wellness-visit-form.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Step2, fullForm } from '../annualwellness.model';
import { AnnualWellnessService } from '../annualwellness.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-medication-adherence',
  templateUrl: './medication-adherence.component.html',
  styleUrls: ['./medication-adherence.component.scss']
})
export class MedicationAdherenceComponent implements OnInit {
  form!: UntypedFormGroup;
  fullForm: fullForm;
  id: string = '';
  @Input() isClinicView: boolean = true;

  step2: Step2 = {
    medicationTakeTime: '',
    medicationTimeComment: '',
    forgetTakeMedication: '',
    confusedAboutMedication: '',
    hardTime: '',
    knowYourMedication: '',
    sideEffects: '',
    medicationExpensive: '',
    pharmacyRunout: '',
    medicationComment: '',
    adlSitting: '',
    adlWalkoutside: '',
    adlEatmeals: '',
    bathUnassisted: '',
    organizeDay: '',
    walkinHouse: '',
    dressedUnassisted: '',
    toiletUnassisted: '',
    answeredNo: '',
    leakingUrine: '',
    functionalComment: '',
    shopGroceries: '',
    publicTransport: '',
    phonecallsUnassisted: '',
    ableToCook: '',
    doHousework: '',
    takeMedication: '',
    handleFinances: '',
    instrumentalComment: ''
  };

  constructor(
    private layout: AnnualWellnessVisitFormComponent,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private AnnualWellnessService: AnnualWellnessService,
    private auth: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    if(this.isClinicView){
      this.GetPatientdetails();
    }
    else{
      this.GetDataForClinic();
    }
  }

  GetPatientdetails() {
    let dateOfBirth = this.auth.getItem("dateOfBirth");
    this.AnnualWellnessService.getAWVDataForPatient(this.id, dateOfBirth).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step2 = {
        medicationTakeTime: results.step2.medicationTakeTime || '',
        medicationTimeComment: results.step2.medicationTimeComment || '',
        forgetTakeMedication: results.step2.forgetTakeMedication || '',
        confusedAboutMedication: results.step2.confusedAboutMedication || '',
        hardTime: results.step2.hardTime || '',
        knowYourMedication: results.step2.knowYourMedication || '',
        sideEffects: results.step2.sideEffects || '',
        medicationExpensive: results.step2.medicationExpensive || '',
        pharmacyRunout: results.step2.pharmacyRunout || '',
        medicationComment: results.step2.medicationComment || '',
        adlSitting: results.step2.adlSitting || '',
        adlWalkoutside: results.step2.adlWalkoutside || '',
        adlEatmeals: results.step2.adlEatmeals || '',
        bathUnassisted: results.step2.bathUnassisted || '',
        organizeDay: results.step2.organizeDay || '',
        walkinHouse: results.step2.walkinHouse || '',
        dressedUnassisted: results.step2.dressedUnassisted || '',
        toiletUnassisted: results.step2.toiletUnassisted || '',
        answeredNo: results.step2.answeredNo || '',
        leakingUrine: results.step2.leakingUrine || '',
        functionalComment: results.step2.functionalComment || '',
        shopGroceries: results.step2.shopGroceries || '',
        publicTransport: results.step2.publicTransport || '',
        phonecallsUnassisted: results.step2.phonecallsUnassisted || '',
        ableToCook: results.step2.ableToCook || '',
        doHousework: results.step2.doHousework || '',
        takeMedication: results.step2.takeMedication || '',
        handleFinances: results.step2.handleFinances || '',
        instrumentalComment: results.step2.instrumentalComment || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/questionnaires/medical-annual-wellness-visit/'+ this.id]);
      }
    );
  }

  GetDataForClinic() {
    this.AnnualWellnessService.getAWVDataForClinic(this.id).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step2 = {
        medicationTakeTime: results.step2.medicationTakeTime || '',
        medicationTimeComment: results.step2.medicationTimeComment || '',
        forgetTakeMedication: results.step2.forgetTakeMedication || '',
        confusedAboutMedication: results.step2.confusedAboutMedication || '',
        hardTime: results.step2.hardTime || '',
        knowYourMedication: results.step2.knowYourMedication || '',
        sideEffects: results.step2.sideEffects || '',
        medicationExpensive: results.step2.medicationExpensive || '',
        pharmacyRunout: results.step2.pharmacyRunout || '',
        medicationComment: results.step2.medicationComment || '',
        adlSitting: results.step2.adlSitting || '',
        adlWalkoutside: results.step2.adlWalkoutside || '',
        adlEatmeals: results.step2.adlEatmeals || '',
        bathUnassisted: results.step2.bathUnassisted || '',
        organizeDay: results.step2.organizeDay || '',
        walkinHouse: results.step2.walkinHouse || '',
        dressedUnassisted: results.step2.dressedUnassisted || '',
        toiletUnassisted: results.step2.toiletUnassisted || '',
        answeredNo: results.step2.answeredNo || '',
        leakingUrine: results.step2.leakingUrine || '',
        functionalComment: results.step2.functionalComment || '',
        shopGroceries: results.step2.shopGroceries || '',
        publicTransport: results.step2.publicTransport || '',
        phonecallsUnassisted: results.step2.phonecallsUnassisted || '',
        ableToCook: results.step2.ableToCook || '',
        doHousework: results.step2.doHousework || '',
        takeMedication: results.step2.takeMedication || '',
        handleFinances: results.step2.handleFinances || '',
        instrumentalComment: results.step2.instrumentalComment || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/forms']);
      }
    );
  }

  save() {
    this.fullForm.step2 = this.step2;
    this.AnnualWellnessService.submitAWVDataForPatient(
      this.id,
      this.fullForm
    ).subscribe(
      (results) => {
        this.layout.onNextComponentClick();
      },
      (error) => { }
    );
  }
  getData() {
    return this.step2;
  }
}
