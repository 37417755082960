<div class="wrapper wrapper-full-page">
    <div class="">
        <!-- Navbar -->
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
            <div class="container">
                <div class="navbar-wrapper">
                    <div class="logo-img"><img src="./assets/img/logo2.png" /></div>
                    <a class="navbar-brand" href="#">LARA HEALTH</a>

                    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                        aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-bar burger-lines"></span>
                        <span class="navbar-toggler-bar burger-lines"></span>
                        <span class="navbar-toggler-bar burger-lines"></span>
                    </button>
                </div>

            </div>
        </nav>
        <!-- End Navbar -->
        <div class="full-page  section-image" data-color="black" data-image="../../assets/img/background.jpg">
            <!--   you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " -->
            <div class="content">
                <div class="container">
                    <div class="col-md-4 col-sm-6 ml-auto mr-auto form">



                        <ng-container *ngIf="!verifySubmitted">

                            <form [formGroup]="verificationForm" (ngSubmit)="onVerificationSubmit()">
                                <div class="card card-login">
                                    <div class="card-header ">
                                        <h3 class="header text-center">Reset Password</h3>
                                    </div>
                                    <div class="card-body ">

                                        <div class="form-group">
                                            <label for="email">Email</label>
                                            <input type="text" formControlName="email" class="form-control"
                                                [ngClass]="{ 'is-invalid': verifySubmit && v.email.errors }" />
                                            <div *ngIf=" verifySubmit && v.email.errors" class="invalid-feedback">
                                                <div *ngIf="v.email.errors.required">Email is required</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="card-footer ml-auto mr-auto">
                                        <button [disabled]="loading" class="btn btn-warning btn-wd">
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                            Send
                                        </button>
                                    </div>
                                    <div class="card-footer ml-auto mr-auto">
                                        <div *ngIf="msg" class="alert alert-danger mt-3 mb-0">{{msg}}</div>
                                        <a routerLink="/login" class="forgot-password">Cancel</a>
                                    </div>

                                </div>
                            </form>
                        </ng-container>


                        <ng-container *ngIf="verifySubmitted">

                            <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
                                <div class="card card-login">
                                    <div class="card-header ">
                                        <h3 class="header text-center">Reset Password</h3>
                                    </div>
                                    <div class="card-body ">

                                        <div class="form-group">
                                            <label for="code">Verification Code</label>
                                            <input type="text" formControlName="code" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.code.errors }" />
                                            <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
                                                <div *ngIf="f.code.errors.required">Code is required</div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="password">Password</label>
                                            <input type="password" formControlName="password" class="form-control" (change)="onPasswordChange()"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="passwordConfirm">Password</label>
                                            <input type="password" formControlName="passwordConfirm" class="form-control" (change)="onPasswordChange()"
                                                [ngClass]="{ 'is-invalid': submitted && f.passwordConfirm.errors }" />
                                            <div *ngIf="submitted && f.passwordConfirm.errors" class="invalid-feedback">
                                                <div *ngIf="f.passwordConfirm.errors.required">Password confirm is required</div>
                                                <span *ngIf="confirm_password.hasError('mismatch')">Password do not match.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer ml-auto mr-auto">
                                        <button [disabled]="loading" class="btn btn-warning btn-wd">
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                            Reset
                                        </button>
                                    </div>
                                    <div class="card-footer ml-auto mr-auto">
                                        <div *ngIf="msg" class="alert alert-danger mt-3 mb-0">{{msg}}</div>

                                    </div>
                                    <div class="card-footer ml-auto mr-auto">
                                        <a [routerLink]="['/login']" class="forgot-password">Cancel</a>

                                    </div>
                                </div>
                            </form>
                        </ng-container>




                    </div>
                </div>
            </div>


            <app-footer></app-footer>

        </div>

    </div>
</div>
