<footer class="footer">
    <div class="container-fluid">
      <nav class="float-left">
        <ul>
          <li>
            <a href="https://lara.health/terms">  Service Terms </a>
          </li>
          <li>
            <a href="https://lara.health/privacy">  Privacy Policy </a>
          </li>
          <!-- <li>
            <a href="#">
              About Us
            </a>
          </li>
          <li>
            <a href="#">
              Contact Us
            </a>
          </li> -->
        </ul>
      </nav>
      <div class="copyright float-right">
        &copy;
        {{currentDate | date: 'yyyy'}}
         Lara Health  
      </div>
    </div>
  </footer>