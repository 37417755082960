var apiBaseUrl = "https://api.dev.larahealth.cloud/patientservice";
var formUrl =  'https://api.dev.larahealth.cloud';

export const environment = {
  production: false,
  userApiUrl: apiBaseUrl,
  patientUrl: apiBaseUrl,
  billingApiUrl: 'https://api.dev.larahealth.cloud/billingservice',
  patientVideoUrl: 'https://d2sfltwl0mmtvh.cloudfront.net',
  formApiUrl: formUrl,

  //notificationUrl: "https://localhost:51439",
  //notificationUrl: "https://pcomm-alb-dev-501745244.us-east-1.elb.amazonaws.com",
  notificationUrl: 'https://notification.lara.health',
  userFlowToken: 'ct_37mh4d3ssffkzj6nl4kxfhkrxy',
  ecgUrl: 'https://ecg-dev.s3.amazonaws.com/',
};
