<div id="fallprevention">
  <h2 class="blue-header">Fall prevention</h2>
  <div class="mt-3">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for=""> 1. In the last 6 months, have you fallen?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="fallprevRadio11" name="haveYouFallen" [(ngModel)]="step3.haveYouFallen" value="YES">
        <label for="fallprevRadio11">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="fallprevRadio12" name="haveYouFallen" [(ngModel)]="step3.haveYouFallen" value="NO">
        <label for="fallprevRadio12">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for=""> 2. Did you sustain any injuries?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="fallprevRadio21" name="sustainInjuries" [(ngModel)]="step3.sustainInjuries" value="YES">
        <label for="fallprevRadio21">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="fallprevRadio22" name="sustainInjuries" [(ngModel)]="step3.sustainInjuries" value="NO">
        <label for="fallprevRadio22">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for=""> 3. Did you feel unsteady when you walk?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="fallprevRadio31" name="feelUnsteady" [(ngModel)]="step3.feelUnsteady" value="YES">
        <label for="fallprevRadio31">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="fallprevRadio32" name="feelUnsteady" [(ngModel)]="step3.feelUnsteady" value="NO">
        <label for="fallprevRadio32">No</label>
      </div>
    </div>
    <label class="text-20-bold-600 label-font-14 label-margin">Which assisted device do you use?</label>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="checkbox" id="wheelchair" name="wheelchair" [(ngModel)]="step3.wheelchair">
        <label for="wheelchair"> wheelchair</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="checkbox" id="cane" name="cane" [(ngModel)]="step3.cane">
        <label for="cane"> cane</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="checkbox" id="walkers" name="walkers" [(ngModel)]="step3.walkers" >
        <label for="walkers"> walkers</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="checkbox" id="prosthetic" name="prosthetic" [(ngModel)]="step3.prosthetic" >
        <label for="prosthetic"> prosthetic</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="checkbox" id="orthotic" name="orthotic" [(ngModel)]="step3.orthotic">
        <label for="orthotic"> orthotic</label>
      </div>
    </div>
  </div>
  <h2 class="blue-header" *ngIf="!isClinicView">Fall risk assessment</h2>
  <div class="mt-3" *ngIf="!isClinicView">
    <label class="text-20-bold-600 label-font-14">Gait & balance<br>
      Have the patient stand on both feet without any assistance and then have them walk: forward, through a doorway and
      make a turn.</label>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="fallriskRadio111" name="gaitBalance" [(ngModel)]="step3.gaitBalance" value="NORMAL/SAFE">
        <label for="fallriskRadio111"> Normal/safe gait and balance</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="fallriskRadio11" name="gaitBalance" [(ngModel)]="step3.gaitBalance" value="CANE_OR_WALKER">
        <label for="fallriskRadio11"> Need a cane or walker</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="fallriskRadio12" name="gaitBalance" [(ngModel)]="step3.gaitBalance" value="IS_SUFFERING">
        <label for="fallriskRadio12"> Is suffering</label>
      </div>
    </div>
    <label class="text-20-bold-600 label-font-14 label-margin">Level of consciousness</label>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="fallriskRadio21" name="consciousness" [(ngModel)]="step3.consciousness" value="ALERTED">
        <label for="fallriskRadio21"> Alerted</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="fallriskRadio22" name="consciousness" [(ngModel)]="step3.consciousness" value="DISORIENTED">
        <label for="fallriskRadio22"> Disoriented</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="fallriskRadio23" name="consciousness" [(ngModel)]="step3.consciousness" value="CONFUSED">
        <label for="fallriskRadio23"> Confused</label>
      </div>
    </div>
    <label class="text-20-bold-600 label-font-14 label-margin">Mobility</label>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="fallriskRadio31" name="mobility" [(ngModel)]="step3.mobility" value="INDEPENDENT">
        <label for="fallriskRadio31"> Independent</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="fallriskRadio32" name="mobility" [(ngModel)]="step3.mobility" value="LIMITED">
        <label for="fallriskRadio32"> Limited</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="fallriskRadio33" name="mobility" [(ngModel)]="step3.mobility" value="IMMOBILE_OR_AMPUTATION">
        <label for="fallriskRadio33"> Immobile or amputation</label>
      </div>
    </div>
  </div>
  <div class="wrap-box" *ngIf="isClinicView">
    <button class="btn-save" (click)="save()">Save and continue</button>
  </div>
</div>
