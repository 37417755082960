<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link
    href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap"
    rel="stylesheet">
<div class="main-container">
    <div class="header-fixed">
        <div class="text-center p-38">
            <img src="../../assets/img/formlogo.png"
                style="height: 48px; width: 171px;">
        </div>
    <progress class="progress-bar-div" [value]="progressvalue" max="100" *ngIf="showProgressBar"></progress>
</div>

     <app-verify-dob *ngIf="currentIndex == -1" (errorOccurred)="handleError($event)"></app-verify-dob>
     <app-patient-details  *ngIf="currentIndex == 0"></app-patient-details>
     <app-medication-adherence *ngIf="currentIndex == 1"></app-medication-adherence>
     <app-fall-prevention *ngIf="currentIndex == 2"></app-fall-prevention>
     <app-complications *ngIf="currentIndex == 3"></app-complications>
     <app-hearing-impairment *ngIf="currentIndex == 4"></app-hearing-impairment>
     <app-depression *ngIf="currentIndex == 5"></app-depression>
     <app-nutrition *ngIf="currentIndex == 6"></app-nutrition>
     <app-home-safety *ngIf="currentIndex == 7"></app-home-safety>
     <app-chronic-conditions *ngIf="currentIndex == 8"></app-chronic-conditions>
     <app-referrals *ngIf="currentIndex == 9"></app-referrals>

    <!-- <app-verify-dob ></app-verify-dob>
  <app-patient-details ></app-patient-details>
  <app-medication-adherence ></app-medication-adherence>
  <app-fall-prevention></app-fall-prevention>
  <app-complications></app-complications>
  <app-hearing-impairment></app-hearing-impairment>
  <app-depression></app-depression>
  <app-nutrition></app-nutrition>
  <app-home-safety></app-home-safety>
  <app-chronic-conditions></app-chronic-conditions>
  <app-referrals></app-referrals>  -->
</div>
