<div class="dialog card card-gray">

    <div class="card-body card-body-narrow form">
        <div class="row ">

            <div class="col-lg-12">
                <label class=" col-form-label">{{displayText}}</label>
            </div>
        </div>

        <mat-dialog-actions align="center" class="">
            <button type="button" class="btn btn-secondary mr-2 " [mat-dialog-close]="false">No</button>
        
            <button type="button" class="btn btn-primary " [mat-dialog-close]="true">Yes</button>
        </mat-dialog-actions>
    </div>
</div>

