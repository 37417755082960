<div id="depression">
  <h2 class="blue-header">Depression</h2>
  <div class="mt-3">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 1.</label>
       <label for=""> Little interest or pleasure in doing things</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio11" name="litteleInterest" [(ngModel)]="step6.litteleInterest" value="EVERYDAY">
       <label for="depressionRadio11">Everyday</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio12" name="litteleInterest" [(ngModel)]="step6.litteleInterest" value="OFTEN">
       <label for="depressionRadio12">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio13" name="litteleInterest" [(ngModel)]="step6.litteleInterest" value="NEVER">
       <label for="depressionRadio13">Never</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 2.</label>
       <label for=""> Feeling down, depressed or hopeless</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio21" name="feelingDown" [(ngModel)]="step6.feelingDown" value="EVERYDAY">
       <label for="depressionRadio21">Everyday</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio22" name="feelingDown" [(ngModel)]="step6.feelingDown" value="OFTEN">
       <label for="depressionRadio22">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio23" name="feelingDown" [(ngModel)]="step6.feelingDown" value="NEVER">
       <label for="depressionRadio23">Never</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 3.</label>
       <label for=""> Trouble falling or staying asleep or sleeping too much</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio31" name="troubleFalling" [(ngModel)]="step6.troubleFalling" value="EVERYDAY">
       <label for="depressionRadio31">Everyday</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio32" name="troubleFalling" [(ngModel)]="step6.troubleFalling" value="OFTEN">
       <label for="depressionRadio32">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio33" name="troubleFalling" [(ngModel)]="step6.troubleFalling" value="NEVER">
       <label for="depressionRadio33">Never</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 4.</label>
       <label for=""> Feeling tired or having little energy</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio41" name="feelingTired" [(ngModel)]="step6.feelingTired" value="EVERYDAY">
       <label for="depressionRadio41">Everyday</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio42" name="feelingTired" [(ngModel)]="step6.feelingTired" value="OFTEN">
       <label for="depressionRadio42">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio43" name="feelingTired" [(ngModel)]="step6.feelingTired" value="NEVER">
       <label for="depressionRadio43">Never</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 5.</label>
       <label for=""> Poor appetite or eating too much</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio51" name="poorAppetite" [(ngModel)]="step6.poorAppetite" value="EVERYDAY">
       <label for="depressionRadio51">Everyday</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio52" name="poorAppetite" [(ngModel)]="step6.poorAppetite" value="OFTEN">
       <label for="depressionRadio52">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio53" name="poorAppetite" [(ngModel)]="step6.poorAppetite" value="NEVER">
       <label for="depressionRadio53">Never</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 6.</label>
       <label for=""> Trouble concentrating on things such as reading the
          newspaper or watching TV</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio61" name="troubleConcentrating" [(ngModel)]="step6.troubleConcentrating" value="EVERYDAY">
       <label for="depressionRadio61">Everyday</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio62" name="troubleConcentrating" [(ngModel)]="step6.troubleConcentrating" value="OFTEN">
       <label for="depressionRadio62">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio63" name="troubleConcentrating" [(ngModel)]="step6.troubleConcentrating" value="NEVER">
       <label for="depressionRadio63">Never</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 7.</label>
       <label for=""> Moving or speaking so slowly that other people have
          noticed or the opposite - being so fidgety and restless
          that vou have been moving around a lot more than
          usual</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio71" name="movingSpeaking" [(ngModel)]="step6.movingSpeaking" value="EVERYDAY">
       <label for="depressionRadio71">Everyday</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio72" name="movingSpeaking" [(ngModel)]="step6.movingSpeaking" value="OFTEN">
       <label for="depressionRadio72">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio73" name="movingSpeaking" [(ngModel)]="step6.movingSpeaking" value="NEVER">
       <label for="depressionRadio73">Never</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 8.</label>
       <label for=""> Thoughts that you would be better off dead, or hurting
          yourself or others</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio81" name="hurtingYourself" [(ngModel)]="step6.hurtingYourself" value="EVERYDAY">
       <label for="depressionRadio81">Everyday</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio82" name="hurtingYourself" [(ngModel)]="step6.hurtingYourself" value="OFTEN">
       <label for="depressionRadio82">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio83" name="hurtingYourself" [(ngModel)]="step6.hurtingYourself" value="NEVER">
       <label for="depressionRadio83">Never</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 9.</label>
       <label for=""> Do you drink alcohol?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio91" name="drinkAlcohol" [(ngModel)]="step6.drinkAlcohol" value="EVERYDAY">
       <label for="depressionRadio91">Everyday</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio92" name="drinkAlcohol" [(ngModel)]="step6.drinkAlcohol" value="OFTEN">
       <label for="depressionRadio92">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio93" name="drinkAlcohol" [(ngModel)]="step6.drinkAlcohol" value="NEVER">
       <label for="depressionRadio93">Never</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 10.</label>
       <label for=""> Do you take recreational drugs?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio101" name="recreationalDrugs" [(ngModel)]="step6.recreationalDrugs" value="EVERYDAY">
       <label for="depressionRadio101">Everyday</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio102" name="recreationalDrugs" [(ngModel)]="step6.recreationalDrugs" value="OFTEN">
       <label for="depressionRadio102">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="depressionRadio103" name="recreationalDrugs" [(ngModel)]="step6.recreationalDrugs" value="NEVER">
       <label for="depressionRadio103">Never</label>
      </div>
    </div>
    <div class="wrap-box">
      <label class="label-font-14">When did you have these thoughts and do you have plans to take your life?</label>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" name="takeYourlife" [(ngModel)]="step6.takeYourlife">
    </div>
    <div class="wrap-box">
      <label class="label-font-14">Comments</label>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" name="depressionComment" [(ngModel)]="step6.depressionComment">
    </div>
  </div>

  <h2 class="blue-header">Anxiety</h2>
  <div class="mt-3">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 1.</label>
       <label for=""> In the past 2 weeks, how often have you felt nervous,
          anxious, or on edge?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="AnxietyRadio11" name="feltNervous" [(ngModel)]="step6.feltNervous" value="FREQUENTLY">
       <label for="AnxietyRadio11">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="AnxietyRadio12" name="feltNervous" [(ngModel)]="step6.feltNervous" value="OFTEN">
       <label for="AnxietyRadio12">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="AnxietyRadio13" name="feltNervous" [(ngModel)]="step6.feltNervous" value="RARELY">
       <label for="AnxietyRadio13">Rarely</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 2.</label>
       <label for=""> In the past 2 weeks how often were you not able to stop
          worrying or control your worrying?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="AnxietyRadio21" name="stopWorrying" [(ngModel)]="step6.stopWorrying" value="FREQUENTLY">
       <label for="AnxietyRadio21">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="AnxietyRadio22" name="stopWorrying" [(ngModel)]="step6.stopWorrying" value="OFTEN">
       <label for="AnxietyRadio22">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="AnxietyRadio23" name="stopWorrying" [(ngModel)]="step6.stopWorrying" value="RARELY">
       <label for="AnxietyRadio23">Rarely</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 3.</label>
       <label for=""> How often does stress prevent you from handling tasks
          such as your health, finances, family, relationships,
          work?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="AnxietyRadio31" name="handlingTasks" [(ngModel)]="step6.handlingTasks" value="FREQUENTLY">
       <label for="AnxietyRadio31">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="AnxietyRadio32" name="handlingTasks" [(ngModel)]="step6.handlingTasks" value="OFTEN">
       <label for="AnxietyRadio32">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="AnxietyRadio33" name="handlingTasks" [(ngModel)]="step6.handlingTasks" value="RARELY">
       <label for="AnxietyRadio33">Rarely</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 4.</label>
       <label for=""> How often do you get the social and emotional support
          you need?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="AnxietyRadio41" name="emotionalSupport" [(ngModel)]="step6.emotionalSupport" value="FREQUENTLY">
       <label for="AnxietyRadio41">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="AnxietyRadio42" name="emotionalSupport" [(ngModel)]="step6.emotionalSupport" value="OFTEN">
       <label for="AnxietyRadio42">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="AnxietyRadio43" name="emotionalSupport" [(ngModel)]="step6.emotionalSupport" value="RARELY">
       <label for="AnxietyRadio43">Rarely</label>
      </div>
    </div>
    <div class="wrap-box">
      <label class="label-font-14">Comments</label>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" name="anxietyComment" [(ngModel)]="step6.anxietyComment">
    </div>
    <div class="wrap-box" *ngIf="isClinicView">
      <button class="btn-save" (click)="save()">Save and continue</button>
    </div>
  </div>
</div>
