import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UserLayoutComponent } from '@app/layouts/user-layout/user-layout.component';
import { SharedModule } from '@app/shared/shared.module';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    // FooterComponent,
    NavbarComponent,
    SidebarComponent,
    UserLayoutComponent
  ],
  exports: [
    // FooterComponent,
    NavbarComponent,
    SidebarComponent
  ]
})
export class ComponentsModule { }
