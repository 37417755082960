import { Component, Input, OnInit } from '@angular/core';
import { AnnualWellnessVisitFormComponent } from '../annual-wellness-visit-form.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Step5, fullForm } from '../annualwellness.model';
import { ActivatedRoute } from '@angular/router';
import { AnnualWellnessService } from '../annualwellness.service';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hearing-impairment',
  templateUrl: './hearing-impairment.component.html',
  styleUrls: ['./hearing-impairment.component.scss']
})
export class HearingImpairmentComponent implements OnInit {
  @Input() isClinicView: boolean = true;
  form!: UntypedFormGroup;
  fullForm: fullForm;
  id: string = '';
  step5: Step5 = {
    wearHearing: '',
    feelHearing: '',
    feelEmbarassed: '',
    feelFrustrated: '',
    hardtimeHearing: '',
    visitingFriends: '',
    argumentsWithFamily: '',
    listeningTv: '',
    hearingComment: ''
  };

  constructor(
    private layout: AnnualWellnessVisitFormComponent,
    private fb: UntypedFormBuilder,
    private AnnualWellnessService: AnnualWellnessService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    if(this.isClinicView){
      this.GetPatientdetails();
    }
    else{
      this.GetDataForClinic();
    }
  }

  GetPatientdetails() {
    let dateOfBirth = this.auth.getItem("dateOfBirth");
    this.AnnualWellnessService.getAWVDataForPatient(this.id, dateOfBirth).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step5 = {
          wearHearing: results.step5. wearHearing || '',
          feelHearing: results.step5.feelHearing || '',
          feelEmbarassed: results.step5.feelEmbarassed || '',
          feelFrustrated: results.step5.feelFrustrated || '',
          hardtimeHearing: results.step5.hardtimeHearing || '',
          visitingFriends: results.step5.visitingFriends || '',
          argumentsWithFamily: results.step5.argumentsWithFamily || '',
          listeningTv: results.step5.listeningTv || '',
          hearingComment: results.step5.hearingComment || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/questionnaires/medical-annual-wellness-visit/'+ this.id]);
      }
    );
  }

  GetDataForClinic() {
    this.AnnualWellnessService.getAWVDataForClinic(this.id).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step5 = {
          wearHearing: results.step5. wearHearing || '',
          feelHearing: results.step5.feelHearing || '',
          feelEmbarassed: results.step5.feelEmbarassed || '',
          feelFrustrated: results.step5.feelFrustrated || '',
          hardtimeHearing: results.step5.hardtimeHearing || '',
          visitingFriends: results.step5.visitingFriends || '',
          argumentsWithFamily: results.step5.argumentsWithFamily || '',
          listeningTv: results.step5.listeningTv || '',
          hearingComment: results.step5.hearingComment || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/forms']);
      }
    );
  }

  save() {
    this.fullForm.step5 = this.step5;
    this.AnnualWellnessService.submitAWVDataForPatient(
      this.id,
      this.fullForm
    ).subscribe(
      (results) => {
        this.layout.onNextComponentClick();
      },
      (error) => { }
    );
  }
  getData() {
    return this.step5;
  }
}
