import { Component, Input, OnInit } from '@angular/core';
import { AnnualWellnessVisitFormComponent } from '../annual-wellness-visit-form.component';
import { AnnualWellnessService } from '../annualwellness.service';
import { ActivatedRoute } from '@angular/router';
import { Step10, fullForm } from '../annualwellness.model';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.scss']
})
export class ReferralsComponent implements OnInit {
  @Input() isClinicView: boolean = true;

  constructor(
    private layout: AnnualWellnessVisitFormComponent,
    private AnnualWellnessService: AnnualWellnessService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router,
    private toastr:ToastrService,
  ) { }

  fullForm: fullForm;
  id: string = '';
  step10: Step10 = {
    boneDensity: '',
    bilatedEye: '',
    colorectalCancer: '',
    rheumatologist: '',
    podiatry: '',
    nephrology: '',
    bnp: '',
    cbc: '',
    cbcWdiff: '',
    cmp: '',
    esr: '',
    fastingLipid: '',
    freeT4: '',
    hemoglobinA1c: '',
    homocvsteine: '',
    iron: '',
    ucidAcid: '',
    magnesium: '',
    vitd: '',
    phos: '',
    ish: '',
    thyroxine: '',
    ptinr: '',
    otherBloodTest: '',
    urineDrug: '',
    urinalysis: '',
    occultDiood: '',
    urineMicroalbumin: '',
    otherUrineTest: '',
    cxr: '',
    ultrasound: '',
    venousDoppler: '',
    arterialDoppler: '',
    xray: '',
    mriWContrast: '',
    mriWoContrast: '',
    ctWContrast: '',
    ctWoContrast: '',
    otherRadiology: '',
    overnightOximetry: '',
    homeSleep: '',
    mobileCardiac: '',
    overnightEeg: '',
    leadEkg: '',
    otherTests: '',
    providerName: '',
    additionalNotes: ''
  };

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    if(this.isClinicView){
      this.GetPatientdetails();
    }
    else{
      this.GetDataForClinic();
    }
  }

  GetPatientdetails() {
    let dateOfBirth = this.auth.getItem("dateOfBirth");
    this.AnnualWellnessService.getAWVDataForPatient(this.id, dateOfBirth).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step10 = {
        boneDensity: results.step10.boneDensity || '',
        bilatedEye: results.step10.bilatedEye || '',
        colorectalCancer: results.step10.colorectalCancer || '',
        rheumatologist: results.step10.rheumatologist || '',
        podiatry: results.step10.podiatry || '',
        nephrology: results.step10.nephrology || '',
        bnp: results.step10.bnp || '',
        cbc: results.step10.cbc || '',
        cbcWdiff: results.step10.cbcWdiff || '',
        cmp: results.step10.cmp || '',
        esr: results.step10.esr || '',
        fastingLipid: results.step10.fastingLipid || '',
        freeT4: results.step10.freeT4 || '',
        hemoglobinA1c: results.step10.hemoglobinA1c || '',
        homocvsteine: results.step10.homocvsteine || '',
        iron: results.step10.iron || '',
        ucidAcid: results.step10.ucidAcid || '',
        magnesium: results.step10.magnesium || '',
        vitd: results.step10.vitd || '',
        phos: results.step10.phos || '',
        ish: results.step10.ish || '',
        thyroxine: results.step10.thyroxine || '',
        ptinr: results.step10.ptinr || '',
        otherBloodTest: results.step10.otherBloodTest || '',
        urineDrug: results.step10.urineDrug || '',
        urinalysis: results.step10.urinalysis || '',
        occultDiood: results.step10.occultDiood || '',
        urineMicroalbumin: results.step10.urineMicroalbumin || '',
        otherUrineTest: results.step10.otherUrineTest || '',
        cxr: results.step10.cxr || '',
        ultrasound: results.step10.ultrasound || '',
        venousDoppler: results.step10.venousDoppler || '',
        arterialDoppler: results.step10.arterialDoppler || '',
        xray: results.step10.xray || '',
        mriWContrast: results.step10.mriWContrast || '',
        mriWoContrast: results.step10.mriWoContrast || '',
        ctWContrast: results.step10.ctWContrast || '',
        ctWoContrast: results.step10.ctWoContrast || '',
        otherRadiology: results.step10.otherRadiology || '',
        overnightOximetry: results.step10.overnightOximetry || '',
        homeSleep: results.step10.homeSleep || '',
        mobileCardiac: results.step10.mobileCardiac || '',
        overnightEeg: results.step10.overnightEeg || '',
        leadEkg: results.step10.leadEkg || '',
        otherTests: results.step10.otherTests || '',
        providerName: results.step10.providerName || '',
        additionalNotes: results.step10.additionalNotes || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/questionnaires/medical-annual-wellness-visit/'+ this.id]);
      }
    );
  }

  GetDataForClinic() {
    this.AnnualWellnessService.getAWVDataForClinic(this.id).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step10 = {
        boneDensity: results.step10.boneDensity || '',
        bilatedEye: results.step10.bilatedEye || '',
        colorectalCancer: results.step10.colorectalCancer || '',
        rheumatologist: results.step10.rheumatologist || '',
        podiatry: results.step10.podiatry || '',
        nephrology: results.step10.nephrology || '',
        bnp: results.step10.bnp || '',
        cbc: results.step10.cbc || '',
        cbcWdiff: results.step10.cbcWdiff || '',
        cmp: results.step10.cmp || '',
        esr: results.step10.esr || '',
        fastingLipid: results.step10.fastingLipid || '',
        freeT4: results.step10.freeT4 || '',
        hemoglobinA1c: results.step10.hemoglobinA1c || '',
        homocvsteine: results.step10.homocvsteine || '',
        iron: results.step10.iron || '',
        ucidAcid: results.step10.ucidAcid || '',
        magnesium: results.step10.magnesium || '',
        vitd: results.step10.vitd || '',
        phos: results.step10.phos || '',
        ish: results.step10.ish || '',
        thyroxine: results.step10.thyroxine || '',
        ptinr: results.step10.ptinr || '',
        otherBloodTest: results.step10.otherBloodTest || '',
        urineDrug: results.step10.urineDrug || '',
        urinalysis: results.step10.urinalysis || '',
        occultDiood: results.step10.occultDiood || '',
        urineMicroalbumin: results.step10.urineMicroalbumin || '',
        otherUrineTest: results.step10.otherUrineTest || '',
        cxr: results.step10.cxr || '',
        ultrasound: results.step10.ultrasound || '',
        venousDoppler: results.step10.venousDoppler || '',
        arterialDoppler: results.step10.arterialDoppler || '',
        xray: results.step10.xray || '',
        mriWContrast: results.step10.mriWContrast || '',
        mriWoContrast: results.step10.mriWoContrast || '',
        ctWContrast: results.step10.ctWContrast || '',
        ctWoContrast: results.step10.ctWoContrast || '',
        otherRadiology: results.step10.otherRadiology || '',
        overnightOximetry: results.step10.overnightOximetry || '',
        homeSleep: results.step10.homeSleep || '',
        mobileCardiac: results.step10.mobileCardiac || '',
        overnightEeg: results.step10.overnightEeg || '',
        leadEkg: results.step10.leadEkg || '',
        otherTests: results.step10.otherTests || '',
        providerName: results.step10.providerName || '',
        additionalNotes: results.step10.additionalNotes || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/forms']);
      }
    );
  }

  save() {
    this.fullForm.step10 = this.step10;
    this.fullForm.status = 'COMPLEATED';
    this.AnnualWellnessService.submitAWVDataForPatient(
      this.id,
      this.fullForm
    ).subscribe(
      (results) => {
        this.router.navigate(['/questionnaires/medical-annual-wellness-visit/' + this.id + '/thank-you']);
      },
      (error) => {
      }
    );
  }

  getData() {
    return this.step10;
  }

}
