import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '@app/users/user.service';
import { RtmPainFormData, RtmPainFormHeading } from './rtm-pain.model';



@Injectable({
  providedIn: 'root'
})
export class RtmPainFormService {
  formApiUrl: string;

  constructor(private http: HttpClient, private userService: UserService) {
    this.formApiUrl = environment.formApiUrl;
  }

  // Get RTM Pain for Patient
  GetRtmPainPatient(id: string, date: string, dateofbirth: string): Observable<RtmPainFormData> {
    var url = `${this.formApiUrl}/forms/response/rtm-pain/get/${id}/${date}`
    let patient = {
      "patient": {
        "dateOfBirth": dateofbirth
        }
      }
    return this.http.post<RtmPainFormData>(url, patient);
  }

  GetRtmPainHeadingData(id: string): Observable<RtmPainFormHeading> {
    var url = `${this.formApiUrl}/forms/head/rtm-pain/${id}`
    return this.http.get<RtmPainFormHeading>(url);
  }

   // Save RTM Pain for Patient
  SaveRtmPainPatient(id: string, date: string, formData: RtmPainFormData): Observable<RtmPainFormData> {
    var url = `${this.formApiUrl}/forms/response/rtm-pain/post/${id}/${date}`
    return this.http.post<RtmPainFormData>(url, formData);
  }

}
