import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { first, finalize, tap } from 'rxjs/operators';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: UntypedFormGroup;
  verificationForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  verifySubmit=false;
  verifySubmitted = false;
  returnUrl: string;
  error = '';
  email ="";
  verificationSent = false;
  msg: string;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      code: ['', Validators.required],
      password: ['', Validators.required],
      passwordConfirm: ['', Validators.required,  ]
    });

    this.verificationForm = this.formBuilder.group({
     
      email: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  onPasswordChange() {
    if (this.confirm_password.value == this.password.value) {
      this.confirm_password.setErrors(null);
    } else {
      this.confirm_password.setErrors({ mismatch: true });
    }
  }
  
  // getting the form control elements
  get password(): AbstractControl {
    return this.resetForm.controls['password'];
  }
  
  get confirm_password(): AbstractControl {
    return this.resetForm.controls['passwordConfirm'];
  }
  get f() { return this.resetForm.controls; }
  get v() { return this.verificationForm.controls; }
  onSubmit() {
    this.submitted = true;

    if (this.resetForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.confirmForgotPassword(this.v.email.value, this.f.code.value, this.f.password.value)
      .pipe(
        tap(() => (this.msg = "")),
        finalize(() => {
          this.loading = false;
        }))
      .subscribe(
        data => {
          if (data.StatusCode && data.StatusCode == 500) {
            this.msg = data.Message;
          }
          else {
            this.router.navigate(["/login"]);
          }

        },
        error => {

          this.msg = error.error.Message;
          this.loading = false;
        });
  }

  onVerificationSubmit()
  {

    this.verifySubmit=true;
  if (this.verificationForm.invalid) {
    return;
  }

  this.loading = true;
  
  this.authenticationService.forgotPassword(this.v.email.value)
    .pipe(
      tap(() => (this.msg = "")),
      finalize(() => {
        this.loading = false;
      }))
    .subscribe(
      data => {
        if (data.StatusCode && data.StatusCode == 500) {
          this.msg = data.Message;
          
        }
        else {
          this.verifySubmitted =true;
        }

      },
      error => {

        this.msg = error.error.Message;
        this.loading = false;
      });
}
}