import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-op-assessment',
  templateUrl: './pre-op-assessment.component.html',
  styleUrls: ['./pre-op-assessment.component.scss']
})
export class PreOpAssessmentComponent implements OnInit {
  currentIndex = -1;
  id:string='';
  stepNumber:string ='';

  components = [
    'app-ccm-self-reporting-vitals-form'
  ];

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('window:popstate', ['$event'])

  showComponent(index: number) {
    this.currentIndex = index;
  }

  onNextComponentClick() {
    window.scroll(0, 0);
    if (this.currentIndex < this.components.length - 1) {
        this.showComponent(this.currentIndex + 1);
      }
  }
}
