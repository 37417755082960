import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { PreopFormData, PreopFormHeading } from './pre-op-assessment.model';



@Injectable({
  providedIn: 'root'
})
export class PreopAssessmentService {
  formApiUrl: string;

  constructor(private http: HttpClient) {
    this.formApiUrl = environment.formApiUrl;
  }

  // get Pre op form data for patient
  GetPreopPatient(id: string, dateofbirth: string): Observable<PreopFormData> {
    var url = `${this.formApiUrl}/forms/response/pre-op-assessment/get/${id}`
    let patient = {
      "patient": {
        "dateOfBirth": dateofbirth
        }
      }
    return this.http.post<PreopFormData>(url, patient);
  }

  GetPreopHeading(id: string): Observable<PreopFormHeading> {
    var url = `${this.formApiUrl}/forms/head/pre-op-assessment/${id}`
    return this.http.get<PreopFormHeading>(url);
  }

  // get Pre op form data for patient
  SavePreopPatient(id: string, formData: PreopFormData): Observable<PreopFormData> {
    var url = `${this.formApiUrl}/forms/response/pre-op-assessment/post/${id}`
    return this.http.post<PreopFormData>(url, formData);
  }

  // get Pre op form data for clinic
  getPreopDataForClinic(id: string): Observable<PreopFormData> {
    var url = `${this.formApiUrl}/forms/internal/adhoc/pre-op-assessment/get/${id}`
    return this.http.get<PreopFormData>(url);
    }

  // Save Pre op form data for clinic
  SavePreopDataForClinic(id: string, formData: PreopFormData): Observable<PreopFormData> {
    var url = `${this.formApiUrl}/forms/internal/adhoc/pre-op-assessment/post/${id}`
    return this.http.post<PreopFormData>(url, formData);
  }
}
