<div id="nutrition">
  <h2 class="blue-header">Nutrition</h2>
  <div class="mt-3">
    <label class="text-20-bold-600 label-font-14">Over past 7 days:</label>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 1.</label>
        <label for=""> How often do you eat fast food or snacks or pizza?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="NutritionRadio11" name="eatFastfood" [(ngModel)]="step7.eatFastfood" value="FREQUENTLY">
        <label for="NutritionRadio11">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="NutritionRadio12" name="eatFastfood" [(ngModel)]="step7.eatFastfood" value="OFTEN">
        <label for="NutritionRadio12">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="NutritionRadio13" name="eatFastfood" [(ngModel)]="step7.eatFastfood" value="RARELY">
        <label for="NutritionRadio13">Rarely</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 2.</label>
        <label for=""> How often do you drink sodas or eat sweets?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="NutritionRadio21" name="drinkSodas" [(ngModel)]="step7.drinkSodas" value="FREQUENTLY">
        <label for="NutritionRadio21">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="NutritionRadio22" name="drinkSodas" [(ngModel)]="step7.drinkSodas" value="OFTEN">
        <label for="NutritionRadio22">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="NutritionRadio23" name="drinkSodas" [(ngModel)]="step7.drinkSodas" value="RARELY">
        <label for="NutritionRadio23">Rarely</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 3.</label>
        <label for=""> How often do you eat fruits and vegetables?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="NutritionRadio31" name="eatFruits" [(ngModel)]="step7.eatFruits" value="FREQUENTLY">
        <label for="NutritionRadio31">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="NutritionRadio32" name="eatFruits" [(ngModel)]="step7.eatFruits" value="OFTEN">
        <label for="NutritionRadio32">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="NutritionRadio33" name="eatFruits" [(ngModel)]="step7.eatFruits" value="RARELY">
        <label for="NutritionRadio33">Rarely</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 4.</label>
        <label for=""> How often do vou eat less than 2 meals a day?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="NutritionRadio41" name="lessTwoMeals" [(ngModel)]="step7.lessTwoMeals" value="FREQUENTLY">
        <label for="NutritionRadio41">Frequently</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="NutritionRadio42" name="lessTwoMeals" [(ngModel)]="step7.lessTwoMeals" value="OFTEN">
        <label for="NutritionRadio42">Often</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="NutritionRadio43" name="lessTwoMeals" [(ngModel)]="step7.lessTwoMeals" value="RARELY">
        <label for="NutritionRadio43">Rarely</label>
      </div>
    </div>
    <div class="wrap-box">
      <label class="text-20-bold-600 label-font-14"> Do you always have enough money to buy the food you need?</label>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="NutritionRadio51" name="enoughMoney" [(ngModel)]="step7.enoughMoney" value="YES">
        <label for="NutritionRadio51">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="NutritionRadio52" name="enoughMoney" [(ngModel)]="step7.enoughMoney" value="NO">
        <label for="NutritionRadio52">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <label class="label-font-14">Comments</label>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" name="nutritionComment" [(ngModel)]="step7.nutritionComment">
    </div>
  </div>

  <h2 class="blue-header">Physical activity</h2>
  <div class="mt-3">
    <label class="text-20-bold-600 label-font-14"> Do you exercise?</label>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="PhysicalRadio11" name="exercise" [(ngModel)]="step7.exercise" value="YES">
        <label for="PhysicalRadio11">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="PhysicalRadio12" name="exercise" [(ngModel)]="step7.exercise" value="NO">
        <label for="PhysicalRadio12">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <label class="label-font-14">How many days in a week do you exercise?</label>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" name="weekExercise" [(ngModel)]="step7.weekExercise">
    </div>
    <div class="wrap-box">
      <label class="label-font-14">On days when you exercise, for how long do you exercise (in minutes)?</label>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" name="longExercise" [(ngModel)]="step7.longExercise">
    </div>
    <div class="wrap-box">
      <label class="label-font-14"> How intense is your typical exercise?</label>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="PhysicalRadio21" name="typicalExercise" [(ngModel)]="step7.typicalExercise" value="LIGHT">
        <label for="PhysicalRadio21">Light (stretching or slow walking etc.)</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="PhysicalRadio22" name="typicalExercise" [(ngModel)]="step7.typicalExercise" value="MODERATE">
        <label for="PhysicalRadio22">Moderate (brisk walking etc.)</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="PhysicalRadio23" name="typicalExercise" [(ngModel)]="step7.typicalExercise" value="INTENSE">
        <label for="PhysicalRadio23">Intense (jogging, swimming etc.)</label>
      </div>
    </div>
    <div class="wrap-box">
      <label class="label-font-14">Comments</label>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" name="physicalComment" [(ngModel)]="step7.physicalComment">
    </div>
  </div>
  <div class="wrap-box" *ngIf="isClinicView">
    <button class="btn-save" (click)="save()">Save and continue</button>
  </div>
</div>
