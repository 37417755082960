import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { PatientsService } from '@app/patients/patients.service';
import { SharedService } from '@app/shared/shared.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements AfterViewInit {
    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;
    loading: boolean;
    summary: any;
    isButton:boolean = true;

    constructor(
      location: Location,
      private element: ElementRef,
      private router: Router,
      private authService: AuthenticationService,
      private sharedService: SharedService,
      private route: ActivatedRoute
      ) {
      this.location = location;
      this.sidebarVisible = false;

    }
    ngAfterViewInit(): void {
        this.getDashboardSummary();
    }

    ngOnInit() {

      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.updateButtonVisibility(event.url);
      });
      this.updateButtonVisibility(this.router.url);

        this.listTitles = ROUTES.filter(listTitle => listTitle);
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.router.events.subscribe((event) => {
            this.sidebarClose('nav-open');
            var $layer: any = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
                this.mobile_menu_visible = 0;
            }
        });
    }


    getDashboardSummary() {
        this.loading = true;
        this.sharedService.getDashboardSummary().subscribe(re => {

            this.summary = re;
            //save it
            this.sharedService.getDashboardSummaryFromCache(re);
            this.loading = false;
        },
            error => {
                this.loading = false;
            }
        );
    }

    logout() {
        this.authService.logout();
    }
    sidebarOpen(className) {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);

        body.classList.add(className);

        this.sidebarVisible = true;
    };
    sidebarClose(className) {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove(className);
    };
    sidebarToggle(className) {
        //  console.log("toggle");
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen(className);
        } else {
            this.sidebarClose(className);
        }
        const body = document.getElementsByTagName('body')[0];

        if (this.mobile_menu_visible == 1) {
            // $('html').removeClass(className);
            body.classList.remove(className);
            if ($layer) {
                $layer.remove();
            }
            setTimeout(function () {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function () {
                $toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');


            if (body.querySelectorAll('.main-panel')) {
                document.getElementsByClassName('main-panel')[0].appendChild($layer);
            } else if (body.classList.contains('off-canvas-sidebar')) {
                document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function () {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function () { //asign a function
                body.classList.remove(className);
                this.mobile_menu_visible = 0;
                $layer.classList.remove('visible');
                setTimeout(function () {
                    $layer.remove();
                    $toggle.classList.remove('toggled');
                }, 400);
            }.bind(this);

            body.classList.add(className);
            this.mobile_menu_visible = 1;

        }
    };

    getTitle() {
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if (titlee.charAt(0) === '#') {
          titlee = titlee.slice(1);
      }
      for (var item = 0; item < this.listTitles.length; item++) {
          const route = this.listTitles[item].path;
          if (route === titlee || route.startsWith('/forms') && titlee.startsWith('/forms')) {
              return this.listTitles[item].title;
          }
      }
      return 'Dashboard';
  }


  updateButtonVisibility(url: string): void {
    if (url == '/InvitePatients') {
      this.isButton = false;
    } else {
      this.isButton = true;
    }
  }

}
