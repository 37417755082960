<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link
    href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap"
    rel="stylesheet">
<div class="main-container">
    <div class="header-fixed">
        <div class="text-center p-38">
            <img src="../../assets/img/formlogo.png"
                style="height: 48px; width: 171px;">
        </div>
</div>
<app-cardiac-screening-verify-dob *ngIf="currentIndex == -1"></app-cardiac-screening-verify-dob>
<app-cardiac-screening-form *ngIf="currentIndex == 0"></app-cardiac-screening-form>
</div>
