import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '@app/users/user.service';
import { DailyAsthmaFormHeading, DailyAsthmaFormData } from './daily-asthma-reporting.model';



@Injectable({
  providedIn: 'root'
})
export class DailyAsthmaReportingService {
  formApiUrl: string;

  constructor(private http: HttpClient, private userService: UserService) {
    this.formApiUrl = environment.formApiUrl;
  }

  GetDailyAsthmaPatient(id: string, date: string, dateofbirth: string): Observable<DailyAsthmaFormData> {
    var url = `${this.formApiUrl}/forms/response/asthma-daily/get/${id}/${date}`
    let patient = {
      "patient": {
        "dateOfBirth": dateofbirth
        }
      }
    return this.http.post<DailyAsthmaFormData>(url, patient);
  }

  GetHeadingData(id: string): Observable<DailyAsthmaFormHeading> {
    var url = `${this.formApiUrl}/forms/head/asthma-daily/${id}`
    return this.http.get<DailyAsthmaFormHeading>(url);
  }

  SaveDailyAsthmaPatient(id: string, date: string, formData: DailyAsthmaFormData): Observable<DailyAsthmaFormData> {
    var url = `${this.formApiUrl}/forms/response/asthma-daily/post/${id}/${date}`
    return this.http.post<DailyAsthmaFormData>(url, formData);
  }

}
