<div class="wrapper wrapper-full-page">
    <div class="">
        <!-- Navbar -->
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
            <div class="container">
                <div class="navbar-wrapper">
                    <div class="logo-img"><img src="./assets/img/logo2.png" /></div>
                    <a class="navbar-brand" href="#">LARA HEALTH</a>

                    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                        aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-bar burger-lines"></span>
                        <span class="navbar-toggler-bar burger-lines"></span>
                        <span class="navbar-toggler-bar burger-lines"></span>
                    </button>
                </div>

            </div>
        </nav>
        <!-- End Navbar -->
        <div class="full-page  section-image" data-color="black" data-image="../../assets/img/background.jpg">
            <!--   you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " -->
            <div class="content">
                <div class="container">
                    <div class="col-md-4 col-sm-6 ml-auto mr-auto form">
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="!isSmsMfa">

                            <div class="card card-login">
                                <div class="card-header ">
                                    <h3 class="header text-center">Login</h3>
                                </div>
                                <div class="card-body ">

                                    <div class="form-group">
                                        <label for="username">Email</label>
                                        <input type="text" formControlName="username" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                            <div *ngIf="f.username.errors.required">Email is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="password">Password</label>
                                        <input type="password" formControlName="password" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">Password is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer ml-auto mr-auto">
                                    <button [disabled]="loading" class="btn btn-warning btn-wd">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Login
                                    </button>
                                </div>
                                <div class="card-footer ml-auto mr-auto">
                                    <div *ngIf="msg" class="alert alert-danger mt-3 mb-0">{{msg}}</div>

                                </div>
                                <div class="card-footer ml-auto mr-auto">
                                    <a  [routerLink]="['/reset']"  class="forgot-password">Forgot my password</a>

                                </div>

                            </div>
                        </form>

                        <form [formGroup]="mfaForm" (ngSubmit)="onSubmitMfa()" *ngIf="isSmsMfa">

                            <div class="card card-login">
                                <div class="card-header ">
                                    <h3 class="header text-center">Login</h3>
                                </div>
                                <div class="card-body ">

                                    <div class="form-group">
                                        <label for="smsMfaCode">SMS Authentication Code</label>
                                        <input type="text" formControlName="smsMfaCode" class="form-control"
                                            [ngClass]="{ 'is-invalid': mfaSubmitted && mfa.smsMfaCode.errors }" />
                                        <div *ngIf="mfaSubmitted && mfa.smsMfaCode.errors" class="invalid-feedback">
                                            <div *ngIf="mfa.smsMfaCode.errors.required">Code is required</div>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-footer ml-auto mr-auto">
                                    <button [disabled]="loading" class="btn btn-warning btn-wd">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Login
                                    </button>
                                </div>
                                <div class="card-footer ml-auto mr-auto">
                                    <div *ngIf="msg" class="alert alert-danger mt-3 mb-0">{{msg}}</div>

                                </div>
                                <div class="card-footer ml-auto mr-auto">
                                    <a  [routerLink]="['/reset']"  class="forgot-password">Forgot my password</a>

                                </div>

                                <div class="card-footer ml-auto mr-auto">

                                    <a (click)="reset()" class="forgot-password">Cancel</a>
                                </div>

                            </div>
                        </form>

                    </div>
                </div>
            </div>


            <app-footer></app-footer>

        </div>

    </div>
</div>
