<!-- <app-loader *ngIf="loading"></app-loader> -->
<div class="row">
  <section class="col-lg-12">
    <ng-container>
      <div class="row ">
        <div class="col-12 ">
          <div class="card card-primary card-outline card-outline-tabs">
            <div class="card-header p-0 border-bottom-0">
              <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link " [class.active]="currentTab=='invitePatient'" data-toggle="pill"
                    href="#tab-patients" role="tab" aria-selected="true" (click)="changeTab('invitePatient')">
                    Invite patients</a>
                </li>
                <li class="nav-item ml-auto">
                  <button class="btn btn-fab close-button" (click)="closeButton()">
                    <i class="material-icons text_align-center">close</i>
                  </button>
                </li>
              </ul>
            </div>
            <div class="card-body">
              <form [formGroup]="form" (ngSubmit)="submitForm()" class="form">
                <div  class="row" formArrayName="patients">
                  <div class="row col-xl-12 col-lg-12 p-0 justify-content-around" *ngFor="let patientGroup of patients.controls; let i = index" [formGroupName]="i" style="margin-top: 15px !important;">
                        <div class="col-xl-2 col-lg-6 col-sm-12 patient-data row">
                          <label class="col-sm-4 col-form-label">First name</label>
                          <div class="col-sm-8">
                            <input type="text" class="form-control  col-form-label" formControlName="firstName">
                            <div *ngIf="isFieldInvalid(i, 'firstName')" class="text-danger">First name is required</div>
                          </div>
                        </div>
                        <div class="col-xl-2 col-lg-6 col-sm-12 patient-data row">
                          <label class="col-sm-4 col-form-label">Last name</label>
                          <div class="col-sm-8">
                            <input type="text" class="form-control  col-form-label" formControlName="lastName">
                            <div *ngIf="isFieldInvalid(i, 'lastName')" class="text-danger">Last name is required</div>
                          </div>
                        </div>
                        <div class="col-xl-2 col-lg-6 col-sm-12 patient-data row">
                          <label class="col-sm-4 col-form-label"> Cell #</label>
                          <div class="col-sm-8">
                            <input type="tel" class="form-control   col-form-label" formControlName="phoneNumber">
                            <div *ngIf="isFieldInvalid(i, 'phoneNumber')" class="text-danger">
                              <div *ngIf="patients.controls[i].get('phoneNumber').hasError('required')">
                                Phone number is required
                              </div>
                              <div
                                *ngIf="patients.controls[i].get('phoneNumber').hasError('minlength')">
                                Phone number must be 10 digits long
                              </div>
                              <div
                                *ngIf="patients.controls[i].get('phoneNumber').hasError('maxlength')">
                                Phone number maximum limit exist
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-2 col-lg-6 col-sm-12 patient-data row">
                          <label class="col-sm-4 col-form-label">DOB</label>
                          <div class="col-sm-8">
                            <mat-form-field floatLabel="never" class="mat-datepicker" (click)="picker.open()">
                              <!-- <mat-label>MM/DD/YYYY</mat-label> -->
                              <input matInput [matDatepicker]="picker" autocomplete="off" formControlName="dateOfBirth" (dateChange)="onDateInput($event, i)" placeholder="MM/DD/YYYY">
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>
                            <!-- <input type="date" class="form-control  col-form-label" formControlName="dateOfBirth"> -->
                            <div *ngIf="isFieldInvalid(i, 'dateOfBirth')" class="text-danger">Date of birth is required
                            </div>
                          </div>
                        </div>

                        <div class="col-xl-3 col-lg-6 col-sm-12 patient-data row">
                          <label class="col-sm-3 col-form-label">Form</label>
                          <div class="col-sm-8">
                            <select class="form-control col-form-label" formControlName="form" (change)="updateSelectedForm($event.target.value)">
                              <option value="">-- Select form type --</option>
                              <option value="annual-wellness-visit">Annual Wellness Visit</option>
                              <option value="pre-op-assessment">Pre Op Assessment</option>
                              <option value="cardiac-screening">Cardiac screening</option>
                            </select>
                          </div>
                        </div>
                      </div>

                </div>
                <div class="row">
                  <div class="col-12" style="margin-top: 15px !important;">
                    <div class=" addpatient">
                      <button class="btn addrow m-0" type="button" (click)="addRow()"> <i class="material-icons">add</i>
                        Add row</button>
                    </div>
                  </div>
                  <div class="col-12 invite-patient-btn">
                    <button type="submit" class="btn btn-primary ml-5"> <i class="material-icons">group_add</i>
                      Invite</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </section>
</div>
