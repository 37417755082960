<nav class="navbar navbar-expand-lg  navbar-absolute fixed-top">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-yellow btn-just-icon  btn-fab btn-round"
          (click)="sidebarToggle('sidebar-mini')">
          <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
          <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
        </button>
      </div>
      <a class="navbar-brand" href="javascript:;">{{getTitle()}}</a>
    </div>
    <button class="navbar-toggler" type="button" (click)="sidebarToggle('nav-open')" data-toggle="collapse"
      aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>

    <div *ngIf="isButton">
    <a *ngIf="getTitle() == 'Forms'" [routerLink]="['/InvitePatients']" class="btn btn-primary  ml-5" > <i class="material-icons">group_add</i> Send Form
    </a>
    <a *ngIf="getTitle() != 'Forms'" [routerLink]="['/enrollment']" class="btn btn-primary  ml-5"> <i class="material-icons">group_add</i> Enrollment
    </a>
    </div>

    <div class="collapse navbar-collapse justify-content-end">
      <!--     <form class="navbar-form">
            <div class="input-group no-border">
              <input
                type="text"
                value=""
                class="form-control"
                placeholder="Search..."
              />
              <button
                type="submit"
                class="btn btn-white btn-round btn-just-icon"
              >
                <i class="material-icons">search</i>
                <div class="ripple-container"></div>
              </button>
            </div>
          </form> -->
      <ul class="navbar-nav">

        <li class="nav-item " *ngIf="summary">
          <a class="nav-link" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <i class="material-icons">notifications</i>
            <span class="notification">{{summary.noOfPatientsWithAlerts}}</span>
            <p class="d-lg-none d-md-block">
              Some Actions
            </p>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">

            <a [routerLink]="['/patients']" [queryParams]="{searchTerm: 'noOfPatientsWithAlerts'}" class="dropdown-item"
              href="#">{{summary.noOfPatientsWithAlerts}} patients with notifications</a>

          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" (click)="logout()" id="navbarDropdownProfile" aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">exit_to_app</i>
            Log out
          </a>

        </li>
      </ul>
    </div>
  </div>
</nav>
