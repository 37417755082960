<div id="homeSafety">
  <h2 class="blue-header">Home safety and living conditions</h2>
  <div class="mt-3">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 1.</label>
        <label for=""> Does your home have smoke and carbon monoxide
          detectors?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="Homeradio11" name="carbonMonoxide" [(ngModel)]="step8.carbonMonoxide" value="YES">
        <label for="Homeradio11">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="Homeradio12" name="carbonMonoxide" [(ngModel)]="step8.carbonMonoxide" value="NO">
        <label for="Homeradio12">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 2.</label>
        <label for=""> Do you always fasten your seat belt when you are in a
          car?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="Homeradio21" name="seatBealt" [(ngModel)]="step8.seatBealt" value="YES">
        <label for="Homeradio21">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="Homeradio22" name="seatBealt" [(ngModel)]="step8.seatBealt" value="NO">
        <label for="Homeradio22">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 3.</label>
        <label for=""> Do you live alone?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="Homeradio31" name="liveAlone" [(ngModel)]="step8.liveAlone" value="YES">
        <label for="Homeradio31">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="Homeradio32" name="liveAlone" [(ngModel)]="step8.liveAlone" value="NO">
        <label for="Homeradio32">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <label class="label-font-14">Comments</label>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" name="homeComment" [(ngModel)]="step8.homeComment">
    </div>
  </div>
  <div class="mt-3">
    <div class="wrap-box">
      <label class="label-font-14"> Who do you live with?</label>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="Homeradio41" name="liveWith" [(ngModel)]="step8.liveWith" value="ALONE">
        <label for="Homeradio41">Alone</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="Homeradio42" name="liveWith" [(ngModel)]="step8.liveWith" value="WITH_FAMILY">
        <label for="Homeradio42">With my family</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="Homeradio43" name="liveWith" [(ngModel)]="step8.liveWith" value="WITH_FRIEND">
        <label for="Homeradio43">With a friend</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="Homeradio44" name="liveWith" [(ngModel)]="step8.liveWith" value="WITH_CAREGIVER">
        <label for="Homeradio44">With a caregiver</label>
      </div>
    </div>

    <div class="wrap-box">
      <label class="label-font-14"> Do you live in:</label>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="Homeradio51" name="liveIn" [(ngModel)]="step8.liveIn" value="HOME">
        <label for="Homeradio51">Home</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="Homeradio52" name="liveIn" [(ngModel)]="step8.liveIn" value="ASSISTED_LIVING_FACILITY">
        <label for="Homeradio52">Assisted living facility</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="Homeradio53" name="liveIn" [(ngModel)]="step8.liveIn" value="NURSING_HOME">
        <label for="Homeradio53">Nursing home</label>
      </div>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" name="liveInComment" [(ngModel)]="step8.liveInComment">
    </div>
  </div>

  <h2 class="blue-header">Advanced care planning</h2>
  <div class="mt-3">
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 1.</label>
        <label for=""> Do you have a medical power of attorney? (Someone to
          make medical decisions for you in the event that you are
          unable to?)</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="carePlanRadio11" name="medicalPower" [(ngModel)]="step8.medicalPower" value="YES">
        <label for="carePlanRadio11">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="carePlanRadio12" name="medicalPower" [(ngModel)]="step8.medicalPower" value="NO">
        <label for="carePlanRadio12">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="carePlanRadio13" name="medicalPower" [(ngModel)]="step8.medicalPower" value="DONT_KNOW">
        <label for="carePlanRadio13">Don't know</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 2.</label>
        <label for=""> Do you have a living will/advanced directive?
          (Documents that make your healthcare wishes known,
          such as a DNR?)</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="carePlanRadio21" name="advancedDirective" [(ngModel)]="step8.advancedDirective" value="YES">
        <label for="carePlanRadio21">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="carePlanRadio22" name="advancedDirective" [(ngModel)]="step8.advancedDirective" value="NO">
        <label for="carePlanRadio22">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="carePlanRadio23" name="advancedDirective" [(ngModel)]="step8.advancedDirective" value="DONT_KNOW">
        <label for="carePlanRadio23">Don't know</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label class="listnumber"> 3.</label>
        <label for=""> Is a copy of your advanced care directive on file at your
          doctor's office? (Documents that make your healthcare
          wishes known, such as a DNR?)</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="carePlanRadio31" name="careDirective" [(ngModel)]="step8.careDirective" value="YES">
        <label for="carePlanRadio31">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="carePlanRadio32" name="careDirective" [(ngModel)]="step8.careDirective" value="NO">
        <label for="carePlanRadio32">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="carePlanRadio33" name="careDirective" [(ngModel)]="step8.careDirective" value="DONT_KNOW">
        <label for="carePlanRadio33">Don't know</label>
      </div>
    </div>
    <div class="wrap-box">
      <label class="label-font-14">Comments</label>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" name="careplanningComment" [(ngModel)]="step8.careplanningComment">
    </div>
  </div>
  <div class="wrap-box" *ngIf="isClinicView">
    <button class="btn-save" (click)="save()">Save and continue</button>
  </div>
</div>
