import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-asthma-self-reporting-daily',
  templateUrl: './asthma-self-reporting-daily.component.html',
  styleUrls: ['./asthma-self-reporting-daily.component.scss']
})
export class AsthmaSelfReportingDailyComponent implements OnInit {
  components = [
    'app-asthma-self-reporting-daily-form'
  ];
  currentIndex = -1;
  id:string='';
  stepNumber:string ='';

  ngOnInit() {
  }
  @HostListener('window:popstate', ['$event'])

  showComponent(index: number) {
    this.currentIndex = index;
  }

  onNextComponentClick() {
    window.scroll(0, 0);
    if (this.currentIndex < this.components.length - 1) {
        this.showComponent(this.currentIndex + 1);
      }
  }
}
