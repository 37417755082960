import { Component, Input, OnInit } from '@angular/core';
import { AnnualWellnessVisitFormComponent } from '../annual-wellness-visit-form.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Step4, fullForm } from '../annualwellness.model';
import { AnnualWellnessService } from '../annualwellness.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-complications',
  templateUrl: './complications.component.html',
  styleUrls: ['./complications.component.scss']
})
export class ComplicationsComponent implements OnInit {
  @Input() isClinicView: boolean = true;
  form!: UntypedFormGroup;

  fullForm: fullForm;
  id: string = '';
  step4: Step4 = {
    medicationType: '',
    ambulation: '',
    visual: '',
    environmental: '',
    predisposing: '',
    historyFalls: '',
    rugsHallways: '',
    grabBars: '',
    handRails: '',
    properLighting: '',
    complicationComment: ''
  };

  constructor(
    private layout: AnnualWellnessVisitFormComponent,
    private fb: UntypedFormBuilder,
    private AnnualWellnessService: AnnualWellnessService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // if(this.isClinicView){
    //   this.layout.onNextComponentClick();
    // }
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    if(this.isClinicView){
      this.GetPatientdetails();
    }
    else{
      this.GetDataForClinic();
    }
  }

  GetPatientdetails() {
    let dateOfBirth = this.auth.getItem("dateOfBirth");
    this.AnnualWellnessService.getAWVDataForPatient(this.id, dateOfBirth).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step4 = {
        medicationType: results.step4.medicationType || '',
        ambulation: results.step4.ambulation || '',
        visual: results.step4.visual || '',
        environmental: results.step4.environmental || '',
        predisposing: results.step4.predisposing || '',
        historyFalls: results.step4.historyFalls || '',
        rugsHallways: results.step4.rugsHallways || '',
        grabBars: results.step4.grabBars || '',
        handRails: results.step4.handRails || '',
        properLighting: results.step4.properLighting || '',
        complicationComment: results.step4.complicationComment || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/questionnaires/medical-annual-wellness-visit/'+ this.id]);
      }
    );
  }

  GetDataForClinic() {
    this.AnnualWellnessService.getAWVDataForClinic(this.id).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step4 = {
        medicationType: results.step4.medicationType || '',
        ambulation: results.step4.ambulation || '',
        visual: results.step4.visual || '',
        environmental: results.step4.environmental || '',
        predisposing: results.step4.predisposing || '',
        historyFalls: results.step4.historyFalls || '',
        rugsHallways: results.step4.rugsHallways || '',
        grabBars: results.step4.grabBars || '',
        handRails: results.step4.handRails || '',
        properLighting: results.step4.properLighting || '',
        complicationComment: results.step4.complicationComment || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/forms']);
      }
    );
  }

  save() {
    this.fullForm.step4 = this.step4;
    this.AnnualWellnessService.submitAWVDataForPatient(
      this.id,
      this.fullForm
    ).subscribe(
      (results) => {
        this.layout.onNextComponentClick();
      },
      (error) => { }
    );
  }
  getData() {
    return this.step4;
  }
}
