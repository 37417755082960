import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { AsthmaSelfReportingDailyComponent } from './asthma-self-reporting-daily.component';
import { AsthmaSelfReportingDailyFormComponent } from './asthma-self-reporting-daily-form/asthma-self-reporting-daily-form.component';
import { DailyAsthmaVerifyDobComponent } from './daily-asthma-verify-dob/daily-asthma-verify-dob.component';
@NgModule({

  declarations: [
    AsthmaSelfReportingDailyComponent,
    AsthmaSelfReportingDailyFormComponent,
    DailyAsthmaVerifyDobComponent
  ],
  exports: [
    AsthmaSelfReportingDailyComponent,
    AsthmaSelfReportingDailyFormComponent,
    DailyAsthmaVerifyDobComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class DailyAsthmaReportingModule { }
