import { Component, OnInit, Output, EventEmitter, Input, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute , Router , Params} from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-annual-wellness-visit-form',
  templateUrl: './annual-wellness-visit-form.component.html',
  styleUrls: ['./annual-wellness-visit-form.component.scss']
})
export class AnnualWellnessVisitFormComponent {
  constructor(private route: ActivatedRoute , private router: Router ,private location: Location){
  }

  components = [
    'app-patient-details',
    'app-medication-adherence',
    'app-fall-prevention',
    'app-complications',
    'app-hearing-impairment',
    'app-depression',
    'app-nutrition',
    'app-home-safety',
    'app-chronic-conditions',
    'app-referrals'
  ];
  progressvalue: number = 0
  currentIndex = -1;
  id:string='';
  stepNumber:string ='';
  showProgressBar: boolean = true;

  ngOnInit() {
    // Display the
    this.route.params.subscribe((params: Params) => {
      this.id = params['invitation-id'];
      this.stepNumber = params['StepNumber'];
      if(this.stepNumber != null && this.stepNumber != ''){
        this.currentIndex = parseInt(this.stepNumber.match(/\d+/)[0], 10);
        this.currentIndex = (this.currentIndex -1);
        this.progressvalue = ((this.currentIndex + 1) * 10);
        }
        this.showComponent(this.currentIndex, this.progressvalue);
    });
  }
  @HostListener('window:popstate', ['$event'])

  showComponent(index: number, porvalue: number) {
    this.currentIndex = index;
    this.progressvalue = porvalue
  }

  onNextComponentClick() {
    window.scroll(0, 0);
    // Update index to show the next component

    if (this.currentIndex < this.components.length - 1) {
      if(this.currentIndex == 7){
        this.router.navigate(['/questionnaires/medical-annual-wellness-visit/'+ this.id+'/step'+(this.currentIndex + 3)]);
        this.showComponent(this.currentIndex + 2, this.progressvalue + 10);
      }else{
        this.router.navigate(['/questionnaires/medical-annual-wellness-visit/'+ this.id+'/step'+(this.currentIndex + 2)]);
        this.showComponent(this.currentIndex + 1, this.progressvalue + 10);
      }
  }else{
    this.router.navigate(['/questionnaires/medical-annual-wellness-visit/' + this.id + '/thank-you']);
  }

}

handleError(errorOccurred: boolean) {
  if (errorOccurred) {
    this.showProgressBar = false;
  }
}

}
