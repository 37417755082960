<form  [formGroup]="ccmForm" (ngSubmit)="save()">

  <div class="">
    <div class="">
      <div><span class="text-20-bold-700 line-height-48">{{formHeading.formName}}</span></div>
      <div class="line-height-48">
        <span class="text-20-bold-700">Medical practice: </span>
        <span class="text-20-bold-400">{{formHeading.clinicName}}</span>
      </div>
      <div class="line-height-48">
        <span class="text-20-bold-700">Document created: </span>
        <span class="text-20-bold-400" *ngIf="formHeading.createdAt == null">{{todayDate | date: 'MMMM dd yyyy'}}</span>
        <span class="text-20-bold-400" *ngIf="formHeading.createdAt !== null">{{formHeading.createdAt | date: 'MMMM dd yyyy'}}</span>
      </div>
    </div>


  <div class="wrap-box" >
    <h2 class="blue-header">Blood pressure (BP) values</h2>
  </div>
  <div class="mt-2">
    <div class="wrap-box">
      <div>
        <label class="label-font-14">For what date are you reporting?</label>
      </div>
      <input type="date" class="custom-input" formControlName="reportingDate" [min]="minDate" (change)="onDateChange($event)"/>
    </div>
  </div>
  <div class="mt-2">
  <div class="wrap-box">
    <div>
      <label class="label-font-14">BP SYS
        <!-- <span class="text-danger">*</span> -->
      </label>
    </div>
   <input type="number" class="custom-input" formControlName="bpSys" />
   <div *ngIf="ccmForm.get('bpSys').invalid && (ccmForm.get('bpSys').dirty || ccmForm.get('bpSys').touched)" class="text-danger">
    <span *ngIf="ccmForm.get('bpSys').hasError('required')">
      BP SYS cannot be empty.
    </span>
    <span *ngIf="ccmForm.get('bpSys').hasError('min')">
      Minimum value allowed is 0.
    </span>
    <span *ngIf="ccmForm.get('bpSys').hasError('max')">
      Maximum value allowed is up to 999.
    </span>
  </div>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">BP DIA
        <!-- <span class="text-danger">*</span> -->
      </label>
    </div>
   <input type="number" class="custom-input" formControlName="bpDia"/>
   <div *ngIf="ccmForm.get('bpDia').invalid && (ccmForm.get('bpDia').dirty || ccmForm.get('bpDia').touched)" class="text-danger">
    <span *ngIf="ccmForm.get('bpDia').hasError('required')">
      BP DIA cannot be empty.
    </span>
    <span *ngIf="ccmForm.get('bpDia').hasError('min')">
      Minimum value allowed is 0.
    </span>
    <span *ngIf="ccmForm.get('bpDia').hasError('max')">
      Maximum value allowed is up to 999.
    </span>
  </div>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Heart rate
        <!-- <span class="text-danger">*</span> -->
      </label>
    </div>
   <input type="number" class="custom-input" formControlName="heartRate"/>
   <div *ngIf="ccmForm.get('heartRate').invalid && (ccmForm.get('heartRate').dirty || ccmForm.get('heartRate').touched)" class="text-danger">
    <span *ngIf="ccmForm.get('heartRate').hasError('required')">
      Heart rate cannot be empty.
    </span>
    <span *ngIf="ccmForm.get('heartRate').hasError('min')">
      Minimum value allowed is 0.
    </span>
    <span *ngIf="ccmForm.get('heartRate').hasError('max')">
      Maximum value allowed is up to 999.
    </span>
  </div>
  </div>
  <div class="wrap-box" >
    <div class="wrap-radio">
      <label for="">Irregular heartbeat detected?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="heartbeat11" [value]="true" formControlName="irregularHeartbeat">
      <label for="heartbeat11">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="heartbeat12" [value]="false" formControlName="irregularHeartbeat">
      <label for="heartbeat12">No</label>
    </div>
  </div>
  <div class="text-danger" *ngIf="formSubmitted && (ccmForm.get('irregularHeartbeat').value == undefined || ccmForm.get('irregularHeartbeat').value == null)">
    <span>Please select one option</span>
  </div>
</div>


<div class="wrap-box" >
  <h2 class="blue-header">Blood sugar (glucose) values</h2>
</div>

<div class="mt-2">
<div class="wrap-box">
  <div>
    <label class="label-font-14">Glucose Value
      <!-- <span class="text-danger">*</span> -->
    </label>
  </div>
 <input type="number" class="custom-input" formControlName="glucose" />
 <div *ngIf="ccmForm.get('glucose').invalid && (ccmForm.get('glucose').dirty || ccmForm.get('glucose').touched)" class="text-danger">
  <span *ngIf="ccmForm.get('glucose').hasError('required')">
    Glucose Value cannot be empty.
  </span>
  <span *ngIf="ccmForm.get('glucose').hasError('min')">
    Minimum value allowed is 0.
  </span>
  <span *ngIf="ccmForm.get('glucose').hasError('max')">
    Maximum value allowed is up to 999.
  </span>
</div>
</div>
<div class="wrap-box">
  <div class="wrap-radio">
    <label for="">Measured</label>
  </div>
</div>
<div class="wrap-box">
  <div class="wrap-radio">
    <input type="radio" id="measured11" value="PRE_MEAL" formControlName="glucoseMeasured">
    <label for="measured11">pre-meal</label>
  </div>
</div>
<div class="wrap-box">
  <div class="wrap-radio">
    <input type="radio" id="measured12" value="POST_MEAL" formControlName="glucoseMeasured">
    <label for="measured12">post-meal</label>
  </div>
</div>
<div class="text-danger" *ngIf="formSubmitted && (ccmForm.get('glucoseMeasured').value === null || ccmForm.get('glucoseMeasured').value === '')">
  <span>Please select one option</span>
</div>
</div>

<div class="wrap-box" >
  <h2 class="blue-header">Weight values</h2>
</div>

<div class="mt-2">
<div class="wrap-box">
  <div>
    <label class="label-font-14">Weight
      <!-- <span class="text-danger">*</span> -->
    </label>
  </div>
  <div class="row">
 <input type="number" class="custom-input custom-input-suffix ml-2" formControlName="weight"/>
 <span class="custom-suffix">lbs</span>
</div>
<div *ngIf="ccmForm.get('weight').invalid && (ccmForm.get('weight').dirty || ccmForm.get('weight').touched)" class="text-danger">
  <span *ngIf="ccmForm.get('weight').hasError('required')">
    weight cannot be empty.
  </span>
  <span *ngIf="ccmForm.get('weight').hasError('min')">
    Minimum value allowed is 0.
  </span>
  <span *ngIf="ccmForm.get('weight').hasError('max')">
    Maximum value allowed is up to 999.
  </span>
</div>
</div>
</div>

<div class="wrap-box" >
  <h2 class="blue-header">Blood oxygen values</h2>
</div>

<div class="mt-2">
<div class="wrap-box">
  <div>
    <label class="label-font-14">O2</label>
  </div>
  <div class="row">
 <input type="number" class="custom-input custom-input-suffix ml-2" formControlName="oxygenO2"/>
 <span class="custom-suffix">%</span>
</div>
<div *ngIf="ccmForm.get('oxygenO2').invalid && (ccmForm.get('oxygenO2').dirty || ccmForm.get('oxygenO2').touched)" class="text-danger">
  <span *ngIf="ccmForm.get('oxygenO2').hasError('required')">
    O2 cannot be empty.
  </span>
  <span *ngIf="ccmForm.get('oxygenO2').hasError('min')">
    Minimum value allowed is 0.
  </span>
  <span *ngIf="ccmForm.get('oxygenO2').hasError('max')">
    Maximum value allowed is up to 100.
  </span>
</div>
</div>
<div class="wrap-box">
  <div>
    <label class="label-font-14">HR</label>
  </div>
 <input type="number" class="custom-input" min="0" max="999" formControlName="oxygenHR"/>
 <div *ngIf="ccmForm.get('oxygenHR').invalid && (ccmForm.get('oxygenHR').dirty || ccmForm.get('oxygenHR').touched)" class="text-danger">
  <span *ngIf="ccmForm.get('oxygenHR').hasError('required')">
    HR cannot be empty.
  </span>
  <span *ngIf="ccmForm.get('oxygenHR').hasError('min')">
    Minimum value allowed is 0.
  </span>
  <span *ngIf="ccmForm.get('oxygenHR').hasError('max')">
    Maximum value allowed is up to 999.
  </span>
</div>
</div>
</div>

<div class="wrap-box" >
  <h2 class="blue-header">Message to the care team</h2>
</div>

<div class="mt-2">
<div class="wrap-box">
  <div>
    <label class="label-font-14">Message</label>
  </div>
 <input type="text" class="custom-input" formControlName="message"/>
</div>
</div>

<div class="wrap-box">
  <button class="btn-save">Submit</button>
</div>

  </div>
</form>
