import { Component, OnInit, Output, EventEmitter, Input, HostListener, ViewChild } from '@angular/core';

@Component({
  selector: 'app-ccm-self-reporting-vitals',
  templateUrl: './ccm-self-reporting-vitals.component.html',
  styleUrls: ['./ccm-self-reporting-vitals.component.scss']
})
export class CcmSelfReportingVitalsComponent implements OnInit {
  constructor(){
  }

  components = [
    'app-ccm-self-reporting-vitals-form'
  ];
  currentIndex = -1;
  id:string='';
  stepNumber:string ='';

  ngOnInit() {
  }
  @HostListener('window:popstate', ['$event'])

  showComponent(index: number) {
    this.currentIndex = index;
  }

  onNextComponentClick() {
    window.scroll(0, 0);
    if (this.currentIndex < this.components.length - 1) {
        this.showComponent(this.currentIndex + 1);
      }
  }
}
