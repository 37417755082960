import { Component, Input, OnInit } from '@angular/core';
import { AnnualWellnessVisitFormComponent } from '../annual-wellness-visit-form.component';
import { Step6, fullForm } from '../annualwellness.model';
import { AnnualWellnessService } from '../annualwellness.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-depression',
  templateUrl: './depression.component.html',
  styleUrls: ['./depression.component.scss']
})
export class DepressionComponent implements OnInit {
  @Input() isClinicView: boolean = true;

  fullForm: fullForm;
  id: string = '';
  step6: Step6 = {
    litteleInterest: '',
    feelingDown: '',
    troubleFalling: '',
    feelingTired: '',
    poorAppetite: '',
    troubleConcentrating: '',
    movingSpeaking: '',
    hurtingYourself: '',
    drinkAlcohol: '',
    recreationalDrugs: '',
    takeYourlife: '',
    depressionComment: '',
    feltNervous: '',
    stopWorrying: '',
    handlingTasks: '',
    emotionalSupport: '',
    anxietyComment: ''
  };

  constructor(
    private layout: AnnualWellnessVisitFormComponent,
    private AnnualWellnessService: AnnualWellnessService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    if(this.isClinicView){
      this.GetPatientdetails();
    }
    else{
      this.GetDataForClinic();
    }
  }

  GetPatientdetails() {
    let dateOfBirth = this.auth.getItem("dateOfBirth");
    this.AnnualWellnessService.getAWVDataForPatient(this.id, dateOfBirth).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step6 = {
          litteleInterest: results.step6.litteleInterest || '',
        feelingDown: results.step6.feelingDown || '',
        troubleFalling: results.step6.troubleFalling || '',
        feelingTired: results.step6.feelingTired || '',
        poorAppetite: results.step6.poorAppetite || '',
        troubleConcentrating: results.step6.troubleConcentrating || '',
        movingSpeaking: results.step6.movingSpeaking || '',
        hurtingYourself: results.step6.hurtingYourself || '',
        drinkAlcohol: results.step6.drinkAlcohol || '',
        recreationalDrugs: results.step6.recreationalDrugs || '',
        takeYourlife: results.step6.takeYourlife || '',
        depressionComment: results.step6.depressionComment || '',
        feltNervous: results.step6.feltNervous || '',
        stopWorrying: results.step6.stopWorrying || '',
        handlingTasks: results.step6.handlingTasks || '',
        emotionalSupport: results.step6.emotionalSupport || '',
        anxietyComment: results.step6.anxietyComment || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/questionnaires/medical-annual-wellness-visit/'+ this.id]);
      }
    );
  }

  GetDataForClinic() {
    this.AnnualWellnessService.getAWVDataForClinic(this.id).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step6 = {
          litteleInterest: results.step6.litteleInterest || '',
        feelingDown: results.step6.feelingDown || '',
        troubleFalling: results.step6.troubleFalling || '',
        feelingTired: results.step6.feelingTired || '',
        poorAppetite: results.step6.poorAppetite || '',
        troubleConcentrating: results.step6.troubleConcentrating || '',
        movingSpeaking: results.step6.movingSpeaking || '',
        hurtingYourself: results.step6.hurtingYourself || '',
        drinkAlcohol: results.step6.drinkAlcohol || '',
        recreationalDrugs: results.step6.recreationalDrugs || '',
        takeYourlife: results.step6.takeYourlife || '',
        depressionComment: results.step6.depressionComment || '',
        feltNervous: results.step6.feltNervous || '',
        stopWorrying: results.step6.stopWorrying || '',
        handlingTasks: results.step6.handlingTasks || '',
        emotionalSupport: results.step6.emotionalSupport || '',
        anxietyComment: results.step6.anxietyComment || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/forms']);
      }
    );
  }

  save() {
    this.fullForm.step6 = this.step6;
    this.AnnualWellnessService.submitAWVDataForPatient(
      this.id,
      this.fullForm
    ).subscribe(
      (results) => {
        this.layout.onNextComponentClick();
      },
      (error) => { }
    );
  }
  getData() {
    return this.step6;
  }
}

