import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-monthly-asthma-control-test',
  templateUrl: './monthly-asthma-control-test.component.html',
  styleUrls: ['./monthly-asthma-control-test.component.scss']
})
export class MonthlyAsthmaControlTestComponent implements OnInit {

  components = [
    'app-monthly-asthma-control-test-form'
  ];
  currentIndex = -1;
  id:string='';
  stepNumber:string ='';

  ngOnInit() {
  }
  @HostListener('window:popstate', ['$event'])

  showComponent(index: number) {
    this.currentIndex = index;
  }

  onNextComponentClick() {
    window.scroll(0, 0);
    if (this.currentIndex < this.components.length - 1) {
        this.showComponent(this.currentIndex + 1);
      }
  }
}
