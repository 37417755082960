
<form  [formGroup]="cardiacForm" (ngSubmit)="save()">

  <div class="">
    <div><span class="text-20-bold-700 line-height-48">{{CardiacFormHeading.formName}}</span></div>
    <div class="line-height-48">
      <span class="text-20-bold-700">Medical practice: </span>
      <span class="text-20-bold-400">{{CardiacFormHeading.clinicName}}</span>
    </div>
    <div class="line-height-48">
      <span class="text-20-bold-700">Document created: </span>
      <span class="text-20-bold-400" *ngIf="CardiacFormHeading.createdAt == null">{{todayDate | date: 'MMMM dd yyyy'}}</span>
      <span class="text-20-bold-400" *ngIf="CardiacFormHeading.createdAt !== null">{{CardiacFormHeading.createdAt | date: 'MMMM dd yyyy'}}</span>
    </div>
  </div>
  <div class="wrap-box" >
    <h2 class="blue-header">RPM result assessment</h2>
  </div>

  <div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 1.</label>
      <label>Are you enrolled in blood pressure monitoring program?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="monitoring1" name="bloodPressureProgram" formControlName="bloodPressureProgram" [value]="true">
      <label for="monitoring1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="monitoring2" name="bloodPressureProgram" formControlName="bloodPressureProgram" [value]="false">
      <label for="monitoring2" >No</label>
    </div>
  </div>
</div>
  <div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 2.</label>
      <label>Does your blood pressure monitor reports irregular heart rate often/periodically?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="periodically1" name="bloodPressureIrregularHeartRate" formControlName="bloodPressureIrregularHeartRate" [value]="true">
      <label for="periodically1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="periodically2"  name="bloodPressureIrregularHeartRate" formControlName="bloodPressureIrregularHeartRate" [value]="false">
      <label for="periodically2" >No</label>
    </div>
  </div>
</div>
<div class="one-question" *ngIf="!isClinicView">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 3.</label>
      <label>Has the patient been marked as a “Frequent IHR patient” in Lara Health?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="IHRpatient1"  name="FrequentIHRpatient" formControlName="FrequentIHRpatient" [value]="true">
      <label for="IHRpatient1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="IHRpatient2"  name="FrequentIHRpatient" formControlName="FrequentIHRpatient" [value]="false">
      <label for="IHRpatient2" >No</label>
    </div>
  </div>
</div>
<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 4.</label>
      <label>Do you use Apple Watch, Fitbit or another smart wearable that has reported IHR occurrences such as Afib?
        </label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="occurrences1"  name="wearableIrregularHeartRate" formControlName="wearableIrregularHeartRate" [value]="true">
      <label for="occurrences1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="occurrences2"  name="wearableIrregularHeartRate" formControlName="wearableIrregularHeartRate" [value]="false">
      <label for="occurrences2" >No</label>
    </div>
  </div>
</div>
<div class="one-question d-none">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label>Patients reporting IHR or marked as frequent IHR patients in Lara Health should be considered for further investigation.</label>
    </div>
  </div>
</div>
<div class="wrap-box" >
  <h2 class="blue-header">General symptoms assessment</h2>
</div>
<div class="wrap-box">
  <label class="label-font-14">In the past 12 months, have you experienced any of the following:</label>
</div>
<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 1.</label>
      <label>A fluttering in the chest</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="fluttering1"  name="flutterinInChest" formControlName="flutterinInChest" [value]="true">
      <label for="fluttering1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="fluttering2"  name="flutterinInChest" formControlName="flutterinInChest" [value]="false">
      <label for="fluttering2" >No</label>
    </div>
  </div>
</div>
<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 2.</label>
      <label> A racing heartbeat (tachycardia)</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="tachycardia1" name="tachycardia" formControlName="tachycardia" [value]="true">
      <label for="tachycardia1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="tachycardia2" name="tachycardia" formControlName="tachycardia" [value]="false">
      <label for="tachycardia2" >No</label>
    </div>
  </div>
</div>
<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 3.</label>
      <label>A slow heartbeat (bradycardia)</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="bradycardia1" name="bradycardia" formControlName="bradycardia" [value]="true">
      <label for="bradycardia1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="bradycardia2" name="bradycardia" formControlName="bradycardia" [value]="false">
      <label for="bradycardia2" >No</label>
    </div>
  </div>
</div>
<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 4.</label>
      <label>Strong pumping/heart beating in the chest, beating too strong or noticeably</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="noticeably1" name="strongHeardBeating" formControlName="strongHeardBeating" [value]="true">
      <label for="noticeably1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="noticeably2" name="strongHeardBeating" formControlName="strongHeardBeating" [value]="false">
      <label for="noticeably2" >No</label>
    </div>
  </div>
</div>
<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 5.</label>
      <label> Chest pain</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="pain1" name="chestPain" formControlName="chestPain" [value]="true">
      <label for="pain1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="pain2" name="chestPain" formControlName="chestPain" [value]="false">
      <label for="pain2" >No</label>
    </div>
  </div>
</div>
<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 6.</label>
      <label>Shortness of breath</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="breath1" name="breathShortness" formControlName="breathShortness" [value]="true">
      <label for="breath1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="breath2" name="breathShortness" formControlName="breathShortness" [value]="false">
      <label for="breath2" >No</label>
    </div>
  </div>
</div>
<div class="one-question d-none">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label>Patients reporting one or more general symptom should be considered for further investigation.</label>
    </div>
  </div>
</div>
<div class="wrap-box" >
  <h2 class="blue-header">Other symptoms assessment</h2>
</div>
<div class="wrap-box">
  <label class="label-font-14">In the past 12 months, have you experienced any of the following:</label>
</div>
<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 1.</label>
      <label>Anxiety</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="Anxiety1" name="anxiety" formControlName="anxiety" [value]="true">
      <label for="Anxiety1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="Anxiety2" name="anxiety" formControlName="anxiety" [value]="false">
      <label for="Anxiety2" >No</label>
    </div>
  </div>
</div>
<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 2.</label>
      <label> Fatigue</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="Fatigue1" name="fatigue" formControlName="fatigue" [value]="true">
      <label for="Fatigue1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="Fatigue2" name="fatigue" formControlName="fatigue" [value]="false">
      <label for="Fatigue2" >No</label>
    </div>
  </div>
</div>
<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 3.</label>
      <label>Lightheadedness or dizziness</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="dizziness1" name="dizzinesss" formControlName="dizzinesss" [value]="true">
      <label for="dizziness1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="dizziness2" name="dizzinesss" formControlName="dizzinesss" [value]="false">
      <label for="dizziness2" >No</label>
    </div>
  </div>
</div>
<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 4.</label>
      <label>Sweating</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="Sweating1" name="sweating" formControlName="sweating" [value]="true">
      <label for="Sweating1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="Sweating2" name="sweating" formControlName="sweating" [value]="false">
      <label for="Sweating2" >No</label>
    </div>
  </div>
</div>
<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 5.</label>
      <label> Fainting (syncope) or near fainting</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="fainting1" name="fainting" formControlName="fainting" [value]="true">
      <label for="fainting1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="fainting2" name="fainting" formControlName="fainting" [value]="false">
      <label for="fainting2" >No</label>
    </div>
  </div>
</div>
<div class="one-question d-none">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label>Patients reporting other symptoms, especially if general symptoms present, frequently should be considered for further investigation.</label>
    </div>
  </div>
</div>
<div class="wrap-box" >
  <h2 class="blue-header">COVID screening</h2>
</div>
<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 1.</label>
      <label>Have you had COVID in the past?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="COVID1" name="covid" formControlName="covid" [value]="true">
      <label for="COVID1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="COVID2" name="covid" formControlName="covid" [value]="false">
      <label for="COVID2" >No</label>
    </div>
  </div>
</div>
<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 2.</label>
      <label> Have you had COVID more than once?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="COVIDcal1" name="multipleCovid" formControlName="multipleCovid" [value]="true">
      <label for="COVIDcal1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="COVIDcal2" name="multipleCovid" formControlName="multipleCovid" [value]="false">
      <label for="COVIDcal2" >No</label>
    </div>
  </div>
</div>
<div class="one-question">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label class="listnumber"> 3.</label>
      <label>Are you experiencing health problems post COVID (long COVID symptoms)?</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="COVIDsymptoms1" name="postCovidHealthIssues" formControlName="postCovidHealthIssues" [value]="true">
      <label for="COVIDsymptoms1">Yes</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <input type="radio" id="COVIDsymptoms2" name="postCovidHealthIssues" formControlName="postCovidHealthIssues" [value]="false">
      <label for="COVIDsymptoms2" >No</label>
    </div>
  </div>
</div>
<div class="one-question d-none">
  <div class="wrap-box">
    <div class="wrap-radio">
      <label>Patients who contracted COVID in the past and are reporting long COVID symptoms should be considered for further investigation.</label>
    </div>
  </div>
</div>

<div class="wrap-box" *ngIf="!isClinicView">
  <label class="label-font-14">Would you like to recommend the patient for further cardiac testing?</label>
</div>
<div class="wrap-box" *ngIf="!isClinicView">
  <div class="wrap-radio">
    <input type="radio" id="testing1" name="overallHealth"  value="Yes">
    <label for="testing1">Yes</label>
  </div>
</div>
<div class="wrap-box" *ngIf="!isClinicView">
  <div class="wrap-radio">
    <input type="radio" id="testing2" name="overallHealth"  value="No">
    <label for="testing2" >No</label>
  </div>
</div>
  <div class="wrap-box">
    <button class="btn-save">Submit</button>
  </div>
  </form>
