import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { MonthlyAsthmaControlTestComponent } from './monthly-asthma-control-test.component';
import { MonthlyAsthmaControlTestFormComponent } from './monthly-asthma-control-test-form/monthly-asthma-control-test-form.component';
import { MonthlyAsthmaVerifyDobComponent } from './monthly-asthma-verify-dob/monthly-asthma-verify-dob.component';
@NgModule({

  declarations: [
    MonthlyAsthmaControlTestComponent,
    MonthlyAsthmaControlTestFormComponent,
    MonthlyAsthmaVerifyDobComponent
  ],
  exports: [
    MonthlyAsthmaControlTestComponent,
    MonthlyAsthmaControlTestFormComponent,
    MonthlyAsthmaVerifyDobComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class MonthlyAsthmaControlTestModule { }
