import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-form-submitted-screen',
  templateUrl: './form-submitted-screen.component.html',
  styleUrls: ['./form-submitted-screen.component.scss']
})
export class FormSubmittedScreenComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

}
