export class AnnualFormStatus {
  id:string;
  dataKey: string;
  resource: string;
  createdAt:string;
  status: string;
  updatedAt: string;
  patient: Patient;
  step1: Step1;
  step2: Step2;
  step3: Step3;
  step4: Step4;
  step5: Step5;
  step6: Step6;
  step7: Step7;
  step8: Step8;
  step9: Step9;
  step10: Step10;
}
export class fullForm {
  id:string;
  dataKey: string;
  resource: string;
  createdAt:string;
  status: string;
  updatedAt: string;
  patient: Patient;
  step1: Step1;
  step2: Step2;
  step3: Step3;
  step4: Step4;
  step5: Step5;
  step6: Step6;
  step7: Step7;
  step8: Step8;
  step9: Step9;
  step10: Step10;
}

export class Patient{
  firstName: string;
  lastName: string;
  dateOfBirth: string;
}

export class Step1 {
  typeOfVisit: string;
  visitComment: string;
  gender: string;
  maritialStatus: string;
  personAnswering: string;
  comments: string;
  bodyMass: string;
  colorectal: string;
  dateFoLastColorectal: string;
  breastCancer: string;
  dateOfLastBreastCancer: string;
  bloodPressure: string;
  diabeticEyeExam: string;
  nephropathy: string;
  boneDensityTest: string;
  overallHealth: string;
  lifeSatisfaction: string;
  mouthteethCondition: string;
  mentalHealth: string;
  mentalHealthComment: string;
  snore: string;
  feltSleepy: string;
  experiencedPain: string;
  painDuringActivity: string;
  painLevel: string;
  personalLoss: string;
  pesonalLossComment: string;
  sleepHours: string;
}

export class Step2 {
  medicationTakeTime: string;
  medicationTimeComment: string;
  forgetTakeMedication: string;
  confusedAboutMedication: string;
  hardTime: string;
  knowYourMedication: string;
  sideEffects: string;
  medicationExpensive: string;
  pharmacyRunout: string;
  medicationComment: string;
  adlSitting: string;
  adlWalkoutside: string;
  adlEatmeals: string;
  bathUnassisted: string;
  organizeDay: string;
  walkinHouse: string;
  dressedUnassisted: string;
  toiletUnassisted: string;
  answeredNo: string;
  leakingUrine: string;
  functionalComment: string;
  shopGroceries: string;
  publicTransport: string;
  phonecallsUnassisted: string;
  ableToCook: string;
  doHousework: string;
  takeMedication: string;
  handleFinances: string;
  instrumentalComment: string;
}

export class Step3 {
  haveYouFallen: string;
  sustainInjuries: string;
  feelUnsteady: string;
  wheelchair: string;
  cane: string;
  walkers: string;
  prosthetic: string;
  orthotic: string;
  gaitBalance: string;
  consciousness: string;
  mobility: string;
}

export class Step4 {
  medicationType: string;
  ambulation: string;
  visual: string;
  environmental: string;
  predisposing: string;
  historyFalls: string;
  rugsHallways: string;
  grabBars: string;
  handRails: string;
  properLighting: string;
  complicationComment: string;
}

export class Step5 {
  wearHearing: string;
  feelHearing: string;
  feelEmbarassed: string;
  feelFrustrated: string;
  hardtimeHearing: string;
  visitingFriends: string;
  argumentsWithFamily: string;
  listeningTv: string;
  hearingComment: string;
}

export class Step6 {
  litteleInterest: string;
  feelingDown: string;
  troubleFalling: string;
  feelingTired: string;
  poorAppetite: string;
  troubleConcentrating: string;
  movingSpeaking: string;
  hurtingYourself: string;
  drinkAlcohol: string;
  recreationalDrugs: string;
  takeYourlife: string;
  depressionComment: string;
  feltNervous: string;
  stopWorrying: string;
  handlingTasks: string;
  emotionalSupport: string;
  anxietyComment: string;
}

export class Step7 {
  eatFastfood: string;
  drinkSodas: string;
  eatFruits: string;
  lessTwoMeals: string;
  enoughMoney: string;
  nutritionComment: string;
  exercise: string;
  weekExercise: string;
  longExercise: string;
  typicalExercise: string;
  physicalComment: string;
}

export class Step8 {
  carbonMonoxide: string;
  seatBealt: string;
  liveAlone: string;
  homeComment: string;
  liveWith: string;
  liveIn: string;
  liveInComment: string;
  medicalPower: string;
  advancedDirective: string;
  careDirective: string;
  careplanningComment: string;
}

export class Step9 {
  paraplegia: string;
  quadriplegia: string;
  rheumatoid: string;
  tremor: string;
  parkinsonsDisease: string;
  convulsions: string;
  elpilepsy: string;
  anginaPectoris: string;
  hivInfection: string;
  viralHepatitis: string;
  purpura: string;
  atheroscleroticHeart: string;
  atrialFibrillation: string;
  cardiacArrhyrhmia: string;
  heartTransplant: string;
  lungTransplant: string;
  liverTransplant: string;
  tracheostomy: string;
  gastrostomy: string;
  colostomy: string;
  absenceToe: string;
  absenceLog: string;
  assessment: string;
  plan: string
}

export class Step10 {
  boneDensity: string;
  bilatedEye: string;
  colorectalCancer: string;
  rheumatologist: string;
  podiatry: string;
  nephrology: string;
  bnp: string;
  cbc: string;
  cbcWdiff: string;
  cmp: string;
  esr: string;
  fastingLipid: string;
  freeT4: string;
  hemoglobinA1c: string;
  homocvsteine: string;
  iron: string;
  ucidAcid: string;
  magnesium: string;
  vitd: string;
  phos: string;
  ish: string;
  thyroxine: string;
  ptinr: string;
  otherBloodTest: string;
  urineDrug: string;
  urinalysis: string;
  occultDiood: string;
  urineMicroalbumin: string;
  otherUrineTest: string;
  cxr: string;
  ultrasound: string;
  venousDoppler: string;
  arterialDoppler: string;
  xray: string;
  mriWContrast: string;
  mriWoContrast: string;
  ctWContrast: string;
  ctWoContrast: string;
  otherRadiology: string;
  overnightOximetry: string;
  homeSleep: string;
  mobileCardiac: string;
  overnightEeg: string;
  leadEkg: string;
  otherTests: string;
  providerName: string;
  additionalNotes: string;
}

export class ResponseInvits {
    id: string;
    url: string;

}
export class InvitePatient {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dateOfBirth: string;
  form:string;
}
export class FormHeading{
  clinicName:string;
  formName:string;
  createdAt:string;
}
