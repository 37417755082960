import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserLayoutComponent } from './layouts/user-layout/user-layout.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './authentication/login/login.component';
import { AuthGuard } from './authentication/auth.guard';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { CanDeactivateGuard } from './patients/can-deactivate.guard';
import { AnnualWellnessVisitFormComponent } from './annual-wellness-visit-form/annual-wellness-visit-form.component';
import { AnnualWellnessComponent } from './annual-wellness/annual-wellness.component';
import { InvitePatientsComponent } from './invite-patients/invite-patients.component';
import { CcmSelfReportingVitalsComponent } from './ccm-self-reporting-vitals/ccm-self-reporting-vitals.component';
import { MonthlyAsthmaControlTestComponent } from './monthly-asthma-control-test/monthly-asthma-control-test.component';
import { AsthmaSelfReportingDailyComponent } from './asthma-self-reporting-daily/asthma-self-reporting-daily.component';
import { FormSubmittedScreenComponent } from './form-submitted-screen/form-submitted-screen.component';
import { CardiacScreeningComponent } from './cardiac-screening/cardiac-screening.component';
import { PreOpAssessmentComponent } from './pre-op-assessment/pre-op-assessment.component';
import { RtmPainComponent } from './rtm-pain/rtm-pain.component';

const routes: Routes = [
  {
    path: '', component: UserLayoutComponent,
    children: [
      { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'patients', loadChildren: () => import('./patients/patients.module').then(m => m.PatientsModule), canActivate: [AuthGuard] },
      { path: 'enrollment', loadChildren: () => import('./enrollment/enrollment.module').then(m => m.EnrollmentModule), canActivate: [AuthGuard] },
      { path: 'billing', loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule), canActivate: [AuthGuard] },
      { path: 'users', loadChildren: () => import('./users/user.module').then(m => m.UsersModule), canActivate: [AuthGuard] },
      { path: 'specialitycare', loadChildren: () => import('./speciality-care/speciality-care.module').then(m => m.SpecialityCareModule), canActivate: [AuthGuard] },
      { path: 'forms', loadChildren: () => import('./annual-wellness/annual-wellness.module').then(m => m.AnnaulwellnessModule) },
      {path : 'InvitePatients' ,component:InvitePatientsComponent}

    ],

  },
  {
    path: "login", component: LoginComponent
  },
  {
    path: "reset", component: ResetPasswordComponent
  },
  { path: 'report', loadChildren: () => import('./report/report.module').then(m => m.ReportModule), canActivate: [AuthGuard] },
  { path: 'questionnaires/:form-type/:invitation-id/thank-you', component: FormSubmittedScreenComponent },
  { path: 'questionnaires/medical-annual-wellness-visit/:invitation-id', component: AnnualWellnessVisitFormComponent },
  { path: 'questionnaires/medical-annual-wellness-visit/:invitation-id/:StepNumber', component: AnnualWellnessVisitFormComponent },
  { path: 'questionnaires/ccm-self-reporting-vitals/:invitation-id', component: CcmSelfReportingVitalsComponent },
  { path: 'questionnaires/monthly-asthma-control-test/:invitation-id', component: MonthlyAsthmaControlTestComponent },
  { path: 'questionnaires/asthma-self-reporting-daily/:invitation-id', component: AsthmaSelfReportingDailyComponent },
  { path: 'questionnaires/cardiac-screening/:invitation-id', component: CardiacScreeningComponent },
  { path: 'questionnaires/pre-op-assessment/:invitation-id', component: PreOpAssessmentComponent },
  { path: 'questionnaires/rtm-pain/:invitation-id', component: RtmPainComponent },

];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
})
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule { }
