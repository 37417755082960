import { Pipe, PipeTransform } from "@angular/core";
import * as moment from 'moment';

@Pipe({ name: 'hideDefaultDate' })
export class hideDefaultDate implements PipeTransform {
    moment: any = moment;

    transform(input: string): string {

        if (input) {
            if (input == "Jan 01 0001" || input == "Jan 01 0001 00:00") {
                return "";
            }
            else {
                return input;

            }
        }
        else {
            return input;
        }
    }

}