<div id="fallprevention">
  <h2 class="blue-header">Complications</h2>
  <div class="mt-3"><div *ngIf="!isClinicView">
    <label class="text-20-bold-600 label-font-14">
      Based upon the following type of medications: anesthetics,
      antihistamines, cathartics, diuretics, antihypertensives, antiseizure, benzodiazepines, hypoglycemics,
      psychotropics, sedative/hypnothics.</label>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="anesthetics1" name="medicationType" [(ngModel)]="step4.medicationType" value="NONE_TAKEN">
        <label for="anesthetics1"> None of these medications taken currently or w/in
          past 7 days</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="anesthetics2" name="medicationType" [(ngModel)]="step4.medicationType" value="TAKES_1_TO_2">
        <label for="anesthetics2"> Takes 1-2 of these medications currently or w/in
          past 7 days</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="anesthetics3" name="medicationType" [(ngModel)]="step4.medicationType" value="TAKES_3_TO_4">
        <label for="anesthetics3"> Takes 3-4 of these medications currently or w/in
          past 7 days or less</label>
      </div>
    </div>
    <label class="text-20-bold-600 label-font-14 label-margin">
      Ambulation/elimination status</label>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ambulation1" name="ambulation" [(ngModel)]="step4.ambulation" value="AMBULATION_ADEQUATE_1">
        <label for="ambulation1"> Adequate (w/ or w/o glasses)</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ambulation2" name="ambulation" [(ngModel)]="step4.ambulation" value="AMBULATION_ADEQUATE_2">
        <label for="ambulation2"> Adequate (w/ or w/o glasses)</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="ambulation3" name="ambulation" [(ngModel)]="step4.ambulation" value="AMBULATION_LEGALLY_BLIND">
        <label for="ambulation3"> Legally blind</label>
      </div>
    </div>
    <label class="text-20-bold-600 label-font-14 label-margin">
      Visual impairment</label>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="visual1" name="visual" [(ngModel)]="step4.visual" value="VISUAL_ADEQUATE_1">
        <label for="visual1"> Adequate (w/ or w/o glasses)</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="visual2" name="visual" [(ngModel)]="step4.visual" value="VISUAL_ADEQUATE_2">
        <label for="visual2"> Adequate (w/ or w/o glasses)</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="visual3" name="visual" [(ngModel)]="step4.visual" value="VISUAL_LEGALLY_BLIND">
        <label for="visual3"> Legally blind</label>
      </div>
    </div>
    <label class="text-20-bold-600 label-font-14 label-margin">
      Environmental hazards</label>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="environmental1" name="environmental" [(ngModel)]="step4.environmental" value="NONE">
        <label for="environmental1"> None</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="environmental2" name="environmental" [(ngModel)]="step4.environmental" value="RUGS_OXYGEN">
        <label for="environmental2"> Rugs, oxygen tubing</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="environmental3" name="environmental" [(ngModel)]="step4.environmental" value="CLUTTERED_ROOM">
        <label for="environmental3"> Cluttered room</label>
      </div>
    </div>
    <label class="text-20-bold-600 label-font-14 label-margin">
      Predisposing diseases</label>
    <label class="text-20-bold-600 label-font-14 label-margin">
      Based upon the following conditions: hypertension,
      vertigo, CVA, Parkingson's disease, loss of limb(s), seizures, arthritis, osteoporosis, fractures.
    </label>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="predisposing1" name="predisposing" [(ngModel)]="step4.predisposing" value="NONE_PRESENT">
        <label for="predisposing1"> None present</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="predisposing2" name="predisposing" [(ngModel)]="step4.predisposing" value="1-2_PRESENT">
        <label for="predisposing2"> 1-2 present</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="predisposing3" name="predisposing" [(ngModel)]="step4.predisposing" value="3_MORE_PRESENT">
        <label for="predisposing3"> 3 or more present</label>
      </div>
    </div></div>
    <label class="text-20-bold-600 label-font-14 label-margin">
      History of falls in last 90 days</label>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="historyFall1" name="historyFalls" [(ngModel)]="step4.historyFalls" value="NO_FALLS">
        <label for="historyFall1"> No falls</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="historyFall2" name="historyFalls" [(ngModel)]="step4.historyFalls" value="1-2_FALLS">
        <label for="historyFall2"> 1-2 falls</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="historyFall3" name="historyFalls" [(ngModel)]="step4.historyFalls" value="3_MORE_FALLS">
        <label for="historyFall3"> 3 or more falls</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for=""> 1. Rugs in the hallways?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="complicationRadio11" name="rugsHallways" [(ngModel)]="step4.rugsHallways" value="YES">
        <label for="complicationRadio11">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="complicationRadio12" name="rugsHallways" [(ngModel)]="step4.rugsHallways" value="NO">
        <label for="complicationRadio12">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for=""> 2. Grab bars in the bathroom?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="complicationRadio21" name="grabBars" [(ngModel)]="step4.grabBars" value="YES">
        <label for="complicationRadio21">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="complicationRadio22" name="grabBars" [(ngModel)]="step4.grabBars" value="NO">
        <label for="complicationRadio22">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for=""> 3. Hand rails on the stairs?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="complicationRadio31" name="handRails" [(ngModel)]="step4.handRails" value="YES">
        <label for="complicationRadio31">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="complicationRadio32" name="handRails" [(ngModel)]="step4.handRails" value="NO">
        <label for="complicationRadio32">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <label for=""> 4. Proper lighting?</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="complicationRadio41" name="properLighting" [(ngModel)]="step4.properLighting" value="YES">
        <label for="complicationRadio41">Yes</label>
      </div>
    </div>
    <div class="wrap-box">
      <div class="wrap-radio">
        <input type="radio" id="complicationRadio42" name="properLighting" [(ngModel)]="step4.properLighting" value="NO">
        <label for="complicationRadio42">No</label>
      </div>
    </div>
    <div class="wrap-box">
      <label class="label-font-14">Comments
      </label>
    </div>
    <div class="wrap-box">
      <input type="text" class="custom-input" name="complicationComment" [(ngModel)]="step4.complicationComment">
    </div>
  </div>
  <div class="wrap-box" *ngIf="isClinicView">
    <button class="btn-save" (click)="save()">Save and continue</button>
  </div>
</div>
