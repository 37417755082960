import { NgModule } from '@angular/core';
import { InvitePatientsComponent } from './invite-patients.component';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
@NgModule({

  declarations: [
    InvitePatientsComponent
  ],
  exports: [
    InvitePatientsComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class InvitePatientsModule { }
