import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { RtmPainComponent } from './rtm-pain.component';
import { RtmPainFormComponent } from './rtm-pain-form/rtm-pain-form.component';
import { RtmPainVerifyDobComponent } from './rtm-pain-verify-dob/rtm-pain-verify-dob.component';



@NgModule({
  declarations: [
    RtmPainComponent,
    RtmPainFormComponent,
    RtmPainVerifyDobComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    RtmPainComponent,
    RtmPainFormComponent,
    RtmPainVerifyDobComponent
  ]
})
export class RtmPainModule { }
