import { Component, Input, OnInit } from '@angular/core';
import { AnnualWellnessService } from '../annualwellness.service';
import { AnnualWellnessVisitFormComponent } from '../annual-wellness-visit-form.component';
import { ActivatedRoute } from '@angular/router';
import { Step9, fullForm } from '../annualwellness.model';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-chronic-conditions',
  templateUrl: './chronic-conditions.component.html',
  styleUrls: ['./chronic-conditions.component.scss']
})
export class ChronicConditionsComponent implements OnInit {
  @Input() isClinicView: boolean = true;

  constructor(
    private layout: AnnualWellnessVisitFormComponent,
    private AnnualWellnessService: AnnualWellnessService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router
  ) { }

  fullForm: fullForm;
  id: string = '';
  step9: Step9 = {
    paraplegia: '',
  quadriplegia: '',
  rheumatoid: '',
  tremor: '',
  parkinsonsDisease: '',
  convulsions: '',
  elpilepsy: '',
  anginaPectoris: '',
  hivInfection: '',
  viralHepatitis: '',
  purpura: '',
  atheroscleroticHeart: '',
  atrialFibrillation: '',
  cardiacArrhyrhmia: '',
  heartTransplant: '',
  lungTransplant: '',
  liverTransplant: '',
  tracheostomy: '',
  gastrostomy: '',
  colostomy: '',
  absenceToe: '',
  absenceLog: '',
  assessment: '',
  plan: ''
  };
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');

    if(this.isClinicView){   //move to next component
      this.layout.onNextComponentClick();
    }
    if(this.isClinicView){
      this.GetPatientdetails();
    }
    else{
      this.GetDataForClinic();
    }
  }


  GetPatientdetails() {
    let dateOfBirth = this.auth.getItem("dateOfBirth");
    this.AnnualWellnessService.getAWVDataForPatient(this.id, dateOfBirth).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step9 = {
          paraplegia: results.step9.paraplegia || '',
        quadriplegia: results.step9.quadriplegia || '',
        rheumatoid: results.step9.rheumatoid || '',
        tremor: results.step9.tremor || '',
        parkinsonsDisease: results.step9.parkinsonsDisease || '',
        convulsions: results.step9.convulsions || '',
        elpilepsy: results.step9.elpilepsy || '',
        anginaPectoris: results.step9.anginaPectoris || '',
        hivInfection: results.step9.hivInfection || '',
        viralHepatitis: results.step9.viralHepatitis || '',
        purpura: results.step9.purpura || '',
        atheroscleroticHeart: results.step9.atheroscleroticHeart || '',
        atrialFibrillation: results.step9.atrialFibrillation || '',
        cardiacArrhyrhmia: results.step9.cardiacArrhyrhmia || '',
        heartTransplant: results.step9.heartTransplant || '',
        lungTransplant: results.step9.lungTransplant || '',
        liverTransplant: results.step9.liverTransplant || '',
        tracheostomy: results.step9.tracheostomy || '',
        gastrostomy: results.step9.gastrostomy || '',
        colostomy: results.step9.colostomy || '',
        absenceToe: results.step9.absenceToe || '',
        absenceLog: results.step9.absenceLog || '',
        assessment: results.step9.assessment || '',
        plan: results.step9.plan || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/questionnaires/medical-annual-wellness-visit/'+ this.id]);
      }
    );
  }

  GetDataForClinic() {
    this.AnnualWellnessService.getAWVDataForClinic(this.id).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step9 = {
          paraplegia: results.step9.paraplegia || '',
        quadriplegia: results.step9.quadriplegia || '',
        rheumatoid: results.step9.rheumatoid || '',
        tremor: results.step9.tremor || '',
        parkinsonsDisease: results.step9.parkinsonsDisease || '',
        convulsions: results.step9.convulsions || '',
        elpilepsy: results.step9.elpilepsy || '',
        anginaPectoris: results.step9.anginaPectoris || '',
        hivInfection: results.step9.hivInfection || '',
        viralHepatitis: results.step9.viralHepatitis || '',
        purpura: results.step9.purpura || '',
        atheroscleroticHeart: results.step9.atheroscleroticHeart || '',
        atrialFibrillation: results.step9.atrialFibrillation || '',
        cardiacArrhyrhmia: results.step9.cardiacArrhyrhmia || '',
        heartTransplant: results.step9.heartTransplant || '',
        lungTransplant: results.step9.lungTransplant || '',
        liverTransplant: results.step9.liverTransplant || '',
        tracheostomy: results.step9.tracheostomy || '',
        gastrostomy: results.step9.gastrostomy || '',
        colostomy: results.step9.colostomy || '',
        absenceToe: results.step9.absenceToe || '',
        absenceLog: results.step9.absenceLog || '',
        assessment: results.step9.assessment || '',
        plan: results.step9.plan || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/forms']);
      }
    );
  }

  save() {
    this.fullForm.step9 = this.step9;
    this.AnnualWellnessService.submitAWVDataForPatient(
      this.id,
      this.fullForm
    ).subscribe(
      (results) => {
        this.layout.onNextComponentClick();
      },
      (error) => { }
    );
  }
  getData() {
    return this.step9;
  }
}
