import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { PreOpAssessmentComponent } from './pre-op-assessment.component';
import { PreOpAssessmentFormComponent } from './pre-op-assessment-form/pre-op-assessment-form.component';
import { PreOpVerifyDobComponent } from './pre-op-verify-dob/pre-op-verify-dob.component';
@NgModule({

  declarations: [
    PreOpAssessmentComponent,
    PreOpAssessmentFormComponent,
    PreOpVerifyDobComponent
  ],
  exports: [
    PreOpAssessmentComponent,
    PreOpAssessmentFormComponent,
    PreOpVerifyDobComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class PreOpAssessmentmodule { }
