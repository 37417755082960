import { Component, Input, OnInit } from '@angular/core';
import { CardiacScreenFormData ,CardiacFormHeading, Patient} from '../cardiac-screening.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CardiacScreeningService } from '../cardiac-screening.service';
import { AuthenticationService } from '@app/authentication/authentication.service';
import * as moment from 'moment';
@Component({
  selector: 'app-cardiac-screening-form',
  templateUrl: './cardiac-screening-form.component.html',
  styleUrls: ['./cardiac-screening-form.component.scss']
})
export class CardiacScreeningFormComponent implements OnInit {
  @Input() isClinicView: boolean = true;
  todayDate = moment(new Date()).format('MM DD yyyy');

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private auth: AuthenticationService,
    private CardiacScreeningService:CardiacScreeningService,
    private formBuilder: FormBuilder
  ) { }

  cardiacForm: FormGroup;

  patient: Patient = {
    dateOfBirth: '',
    firstName: '',
    lastName: '',
    phoneNumber: ''
  }

  CardiacScreenFormData :CardiacScreenFormData ={
    bloodPressureProgram: null,
    bloodPressureIrregularHeartRate: null,
    frequentIHRPatient: null,
    wearableIrregularHeartRate: null,
    flutterinInChest: null,
    tachycardia: null,
    bradycardia: null,
    strongHeardBeating: null,
    chestPain: null,
    breathShortness: null,
    anxiety: null,
    fatigue: null,
    dizzinesss: null,
    sweating: null,
    fainting: null,
    covid: null,
    multipleCovid: null,
    postCovidHealthIssues: null,
    furtherCardiacTesting: null,
    id: "",
    dataKey: "",
    resource: "",
    patient: {
      dateOfBirth: "",
      firstName: "",
      lastName: "",
      phoneNumber: ""
    },
    createdAt: "",
    status: "",
  }

  CardiacFormHeading: CardiacFormHeading = {
    clinicName: '',
    formName: '',
    createdAt: ''
  }

  id: string = '';

  ngOnInit(): void {

    this.id = this.route.snapshot.paramMap.get('invitation-id');
    this.GetCardiacScreendetails();
    this.GetCardiacScreenHeading();

    this.cardiacForm = this.formBuilder.group({
      bloodPressureProgram: [null],
      bloodPressureIrregularHeartRate: [null],
      frequentIHRPatient: [null],
      wearableIrregularHeartRate: [null],
      flutterinInChest: [null],
      tachycardia: [null],
      bradycardia: [null],
      strongHeardBeating: [null],
      chestPain: [null],
      breathShortness: [null],
      anxiety: [null],
      fatigue: [null],
      dizzinesss: [null],
      sweating: [null],
      fainting: [null],
      covid: [null],
      multipleCovid: [null],
      postCovidHealthIssues: [null],
      furtherCardiacTesting: [null]
    });

  }

  save() {
    this.cardiacForm.value["patient"] = this.patient;
     this.cardiacForm.value["status"] = 'COMPLEATED';
      this.CardiacScreeningService.SaveCardiacform(
        this.id,
        this.cardiacForm.value
      ).subscribe(
        (results: any) => {
          this.router.navigate(['questionnaires/cardiac-screening/'+ this.id +'/thank-you']);
        },
        (error) => {
        }
      );
  }

  GetCardiacScreendetails() {
    let dateOfBirth = this.auth.getItem("dateOfBirth");
    this.CardiacScreeningService.GetCardiacform(this.id, dateOfBirth).subscribe(
      (results) => {
        this.CardiacScreenFormData = results;
        this.patient = results.patient;
        this.cardiacForm.patchValue(this.CardiacScreenFormData);
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['questionnaires/cardiac-screening/' + this.id]);
      }
    );
  }

  GetCardiacScreenHeading() {
    this.CardiacScreeningService.GetHeadingData(this.id).subscribe(
      (results: CardiacFormHeading) => {
        this.CardiacFormHeading = results;
      },
      (error) => {
      }
    );
  }
}
