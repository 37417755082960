import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'displayNull'})
export class displayNullPipe implements PipeTransform{
 
    transform(input :any) : string {
       
        if(input){
            return input;
        }
        else{
            return "-"
        }
    }

}