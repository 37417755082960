import { Component, OnInit } from '@angular/core';
import { CcmSelfReportingService } from '../ccm-self-reporting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { CCMFormData, FormHeading, Patient } from '../ccm-self-reporting.model';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-ccm-self-reporting-vitals-form',
  templateUrl: './ccm-self-reporting-vitals-form.component.html',
  styleUrls: ['./ccm-self-reporting-vitals-form.component.scss']
})
export class CcmSelfReportingVitalsFormComponent implements OnInit {
  minDate: string;
  todayDate = moment(new Date()).format('MMMM DD yyyy');
  reportingDate: string = new Date().toISOString().split('T')[0];
  id: string = '';
  ccmForm: UntypedFormGroup;
  formSubmitted: boolean = false;

  constructor(
    private CcmFormService: CcmSelfReportingService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private toastr: ToastrService,
    private router: Router,
    private fb: UntypedFormBuilder
  ) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 6);
    this.minDate = yesterday.toISOString().split('T')[0];
  }

  formHeading: FormHeading = {
    clinicName: '',
    formName: '',
    createdAt: ''
  }

  patient: Patient = {
    dateOfBirth: '',
    firstName: '',
    lastName: ''
  }

  CcmFormData: CCMFormData = {
    bpSys: 0,
    bpDia: 0,
    heartRate: 0,
    irregularHeartbeat: null,
    glucose: 0,
    glucoseMeasured: '',
    weight: 0,
    oxygenO2: 0,
    oxygenHR: 0,
    message: '',
    patient: {
      dateOfBirth: '',
      firstName: '',
      lastName: ''
    },
    id: '',
    dataKey: '',
    resource: '',
    status: '',
    createdAt: ''
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');

    this.ccmForm = this.fb.group({
      reportingDate: [new Date().toISOString().split('T')[0], Validators.required],
      bpSys: [0, [Validators.required, Validators.min(0), Validators.max(999)]],
      bpDia: [0, [Validators.required, Validators.min(0), Validators.max(999)]],
      heartRate: [0, [Validators.required, Validators.min(0), Validators.max(999)]],
      irregularHeartbeat: [null, Validators.required],
      glucose: [0, [Validators.required, Validators.min(0), Validators.max(999)]],
      glucoseMeasured: ['', Validators.required],
      weight: [0, [Validators.required, Validators.min(0), Validators.max(999)]],
      oxygenO2: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      oxygenHR: [0, [Validators.required, Validators.min(0), Validators.max(999)]],
      message: ['']
    });

    this.GetCCMPatientData();
    this.GetCCMHeading();
  }

  GetCCMPatientData() {
    let dateOfBirth = this.auth.getItem("dateOfBirth");
    this.CcmFormService.GetCCMForPatient(this.id, this.reportingDate, dateOfBirth).subscribe(
      (results: any) => {
        this.CcmFormData = results;
        this.patient = results.patient;
        this.ccmForm.patchValue(this.CcmFormData);
      },
      (error) => {
        console.error('Error fetching details:', error);
        this.router.navigate(['/questionnaires/ccm-self-reporting-vitals/' + this.id]);
      }
    );
  }

  save() {
    this.formSubmitted = true;
    if (this.ccmForm.valid) {
      this.ccmForm.value["patient"] = this.patient;
      this.ccmForm.value["status"] = 'COMPLEATED';
      this.CcmFormService.SubmitCCMForPatient(
        this.id,
        this.ccmForm.value.reportingDate,
        this.ccmForm.value
      ).subscribe(
        (results: any) => {
          this.router.navigate(['/questionnaires/ccm-self-reporting-vitals/' + this.id + '/thank-you']);
        },
        (error) => {
        }
      );
    } else {
      this.toastr.error(`Please Fill the Form Correctly.`, 'Error');
    }
  }

  GetCCMHeading() {
    this.CcmFormService.GetCCMHeadingData(this.id).subscribe(
      (results: FormHeading) => {
        this.formHeading = results;
      },
      (error) => {
      }
    );
  }

  getData() {
    return this.CcmFormData;
  }

  onDateChange(event: any) {
    const selectedDate = new Date(event.target.value);
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);

    if (selectedDate < sevenDaysAgo) {
      this.ccmForm.get('reportingDate').setValue(sevenDaysAgo.toISOString().substring(0, 10));
    }
  }

}
