import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { MonthlyAsthmaControlService } from '../monthly-asthma-service';
import { MonthlyAsthmaControlTestComponent } from '../monthly-asthma-control-test.component';
import * as moment from 'moment';
import { MonthlyFormHeading } from '../monthly-asthma-model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-monthly-asthma-verify-dob',
  templateUrl: './monthly-asthma-verify-dob.component.html',
  styleUrls: ['./monthly-asthma-verify-dob.component.scss']
})
export class MonthlyAsthmaVerifyDobComponent implements OnInit {

  constructor(
    private MonthlyAsthmaControlTestComponent: MonthlyAsthmaControlTestComponent,
    private route: ActivatedRoute,
    private MonthlyAsthmaControlService: MonthlyAsthmaControlService,
    private toastr: ToastrService,
    private auth: AuthenticationService,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  todayDate = moment(new Date()).format('MMMM DD yyyy');
  id: string = '';
  month: string;
  ISInvalid: boolean = false;
  Isreview: boolean = false;
  dateofbirth: string = new Date().toISOString().split('T')[0];

  formHeading: MonthlyFormHeading = {
    clinicName: '',
    formName: '',
    createdAt: ''
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    const currentDate = new Date();
    this.month = moment(new Date()).format('yyyy-MM');
    this.GetHeading();
  }

  onDateInput(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;
    this.dateofbirth = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
  }

  next() {
    this.MonthlyAsthmaControlService.GetMonthlyAsthmaPatient(this.id, this.month, this.dateofbirth).subscribe(
      (results) => {
        if (results != null || results.id != '') {
          this.auth.setItem('dateOfBirth',this.dateofbirth);
          this.MonthlyAsthmaControlTestComponent.onNextComponentClick();
        } else {
          this.ISInvalid = true;
        }
      },
      (error) => {
        if (error.status == 403) {
          this.router.navigate(['/questionnaires/monthly-asthma-control-test/'+ this.id +'/thank-you']);
          // this.Isreview = true;
        } else {
          this.ISInvalid = true;
        }
      }
    );
  }

  GetHeading() {
    this.MonthlyAsthmaControlService.GetMonthlyHeading(this.id).subscribe(
      (results: MonthlyFormHeading) => {
        this.formHeading = results;
      },
      (error) => {
      }
    );
  }
}
