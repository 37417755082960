import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'displayZeroToDoubleDash' })
export class displayZeroToDoubleDashPipe implements PipeTransform {

    transform(input: any): any {

        if (input && input > 0) {
            return input;
        }
        else {
            return "--"
        }
    }

}