import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AnnualWellnessVisitFormComponent } from '../annual-wellness-visit-form.component';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AnnualWellnessService } from '../annualwellness.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { FormHeading } from '../annualwellness.model';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-verify-dob',
  templateUrl: './verify-dob.component.html',
  styleUrls: ['./verify-dob.component.scss'],
})
export class VerifyDOBComponent implements OnInit {

  constructor(
    private AnnualWellnessVisitFormComponent: AnnualWellnessVisitFormComponent,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private AnnualWellnessService: AnnualWellnessService,
    private toastr: ToastrService,
    private auth: AuthenticationService,
    private router: Router,
    private datePipe: DatePipe
  ) {

  }
  @Output() errorOccurred = new EventEmitter<boolean>();
  isprogress:any;
  todayDate = moment(new Date()).format('MMMM DD yyyy');
  formHeading:FormHeading = new FormHeading();
  id: string = '';
  ISInvalid: boolean = false;
  Isreview: boolean = false;
  dateofbirth: string = moment(new Date()).format('YYYY-MM-DD');

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    this.GetAWVHeading();
  }

  onDateInput(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;
    this.dateofbirth = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
  }

  next() {
    this.AnnualWellnessService.getAWVDataForPatient(this.id, this.dateofbirth).subscribe(
      (results) => {
        if (results != null || results.id != '') {
          this.auth.setItem('dateOfBirth',this.dateofbirth);
          this.AnnualWellnessVisitFormComponent.onNextComponentClick();
        } else {
          this.ISInvalid = true;
        }
      },
      (error) => {
        if (error.status == 403) {
          this.router.navigate(['/questionnaires/medical-annual-wellness-visit/'+ this.id +'/thank-you']);
          // this.Isreview = true;
          // this.errorOccurred.emit(this.Isreview);
        } else {
          this.ISInvalid = true;
        }
      }
    );
  }

  GetAWVHeading() {
    this.AnnualWellnessService.GetAWVHeadingData(this.id).subscribe(
      (results: FormHeading) => {
        this.formHeading = results;
      },
      (error) => {
       }
    );
  }

}
