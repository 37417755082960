import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/authentication/authentication.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DailyAsthmaFormData, DailyAsthmaFormHeading, Patient } from '../daily-asthma-reporting.model';
import { DailyAsthmaReportingService } from '../daily-asthma-reporting.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-asthma-self-reporting-daily-form',
  templateUrl: './asthma-self-reporting-daily-form.component.html',
  styleUrls: ['./asthma-self-reporting-daily-form.component.scss']
})
export class AsthmaSelfReportingDailyFormComponent implements OnInit {
  minDate: string;
  todayDate = moment(new Date()).format('MMMM DD yyyy');
  reportingDate: string = new Date().toISOString().split('T')[0];
  id: string = '';
  dailyAsthmaForm: UntypedFormGroup;
  formSubmitted: boolean = false;
  isOtherFreeText: boolean = true;
  symptomsList = [
    { id: 'symptoms1', value: 'WHEEZING', label: 'Wheezing' },
    { id: 'symptoms2', value: 'TIGHT_CHEST', label: 'Tight chest' },
    { id: 'symptoms3', value: 'ASTHMA_ATTACK', label: 'Asthma Attack' },
    { id: 'symptoms4', value: 'COUGH', label: 'Cough' },
    { id: 'symptoms5', value: 'BREATHLESS', label: 'Breathless' },
    { id: 'symptoms6', value: 'MUCUS', label: 'Mucus' },
    { id: 'symptoms7', value: 'OTHER', label: 'Other' }
  ];

  constructor(
    private DailyAsthmaService: DailyAsthmaReportingService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private toastr: ToastrService,
    private router: Router,
    private fb: UntypedFormBuilder
  ) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 6);
    this.minDate = yesterday.toISOString().split('T')[0];
  }

  formHeading: DailyAsthmaFormHeading = {
    clinicName: '',
    formName: '',
    createdAt: ''
  }

  patient: Patient = {
    dateOfBirth: '',
    firstName: '',
    lastName: ''
  }

  dailyAsthmaFormData: DailyAsthmaFormData = {
    symptoms: [],
    symptomsOther: '',
    symptomsRate: '',
    takingDailyPreventativeMeds: null,
    preventativeMedsDozes: 0,
    preventativeMedsFrequency: 0,
    quickReliefInhalerUsed: null,
    activityLevels: '',
    sleepQuality: '',
    spirometerFEV1: 0,
    spirometerFVC: 0,
    spirometerRatio: 0,
    peakFlowTest: 0,
    pulseOxTest: 0,
    comments: '',
    id: '',
    dataKey: '',
    resource: '',
    patient: {
      dateOfBirth: '',
      firstName: '',
      lastName: ''
    },
    createdAt: '',
    status: '',
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');

    this.dailyAsthmaForm = this.fb.group({
      reportingDate: [new Date().toISOString().split('T')[0], Validators.required],
      symptoms: [[]],
      symptomsOther: [''],
      symptomsRate: [''],
      takingDailyPreventativeMeds: [null, Validators.required],
      preventativeMedsDozes: [0, [Validators.required, Validators.min(0)]],
      preventativeMedsFrequency: [''],
      quickReliefInhalerUsed: [null, Validators.required],
      activityLevels: [''],
      sleepQuality: ['', Validators.required],
      spirometerFEV1: [0, [Validators.required, Validators.min(0)]],
      spirometerFVC: [0, [Validators.required, Validators.min(0)]],
      spirometerRatio: [0],
      peakFlowTest: [0, [Validators.required, Validators.min(0), Validators.max(9000)]],
      pulseOxTest: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      comments: ['']
    });

    this.GetDailyAsthmadetails();
    this.GetDailyAsthmaHeading();

  }

  GetDailyAsthmadetails() {
    let dateOfBirth = this.auth.getItem("dateOfBirth");
    this.DailyAsthmaService.GetDailyAsthmaPatient(this.id, this.reportingDate, dateOfBirth).subscribe(
      (results) => {
        this.dailyAsthmaFormData = results;
        this.patient = results.patient;
        this.dailyAsthmaForm.patchValue(this.dailyAsthmaFormData);
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/questionnaires/asthma-self-reporting-daily/' + this.id]);
      }
    );
  }

  isOtherChecked() {
    const otherCheckbox = this.dailyAsthmaForm.get('symptoms');
    if (otherCheckbox.value.find((symptom: string) => symptom === 'OTHER')) {
      this.isOtherFreeText = false;
    } else {
      this.isOtherFreeText = true;
    }
  }

  addSymptomsControls(isChecked: boolean, i: number) {
    let symptomArray = this.dailyAsthmaForm.get('symptoms').value;
    const Checkvalue = this.symptomsList[i].value;

    if (isChecked) {
      symptomArray.push(Checkvalue);
    } else {
      symptomArray = symptomArray.filter((item: any) => item !== Checkvalue);
    }
    this.dailyAsthmaForm.get('symptoms').setValue(symptomArray);
  }

  save() {
    this.formSubmitted = true;
    if (this.dailyAsthmaForm.valid) {
      this.dailyAsthmaForm.value["patient"] = this.patient;
      this.dailyAsthmaForm.value["status"] = 'COMPLEATED';
      this.DailyAsthmaService.SaveDailyAsthmaPatient(
        this.id,
        this.dailyAsthmaForm.value.reportingDate,
        this.dailyAsthmaForm.value
      ).subscribe(
        (results: any) => {
          this.router.navigate(['/questionnaires/asthma-self-reporting-daily/'+ this.id +'/thank-you']);
        },
        (error) => {
        }
      );
    } else {
      this.toastr.error(`Please fill the form correctly.`, 'Error');
    }
  }

  GetDailyAsthmaHeading() {
    this.DailyAsthmaService.GetHeadingData(this.id).subscribe(
      (results: DailyAsthmaFormHeading) => {
        this.formHeading = results;
      },
      (error) => {
      }
    );
  }

  getData() {
    return this.dailyAsthmaFormData;
  }

  calculateRatio() {
    const fev1 = this.dailyAsthmaForm.get('spirometerFEV1').value;
    const fvc = this.dailyAsthmaForm.get('spirometerFVC').value;

    if (fev1 && fvc) {
      const ratio = (fev1 / fvc) * 100;
      this.dailyAsthmaForm.patchValue({
        spirometerRatio: parseFloat(ratio.toFixed(2))
      });
    } else {
      this.dailyAsthmaForm.patchValue({
        spirometerRatio: 0
      });
    }
  }

  onDateChange(event: any) {
    const selectedDate = new Date(event.target.value);
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);

    if (selectedDate < sevenDaysAgo) {
      this.dailyAsthmaForm.get('reportingDate').setValue(sevenDaysAgo.toISOString().substring(0, 10));
    }
  }

}
