<div class="wrapper">
  <app-sidebar></app-sidebar>
  <div class="main-panel">
    <!-- Navbar -->
    <app-navbar></app-navbar>
    <!-- End Navbar -->

    <div class="main-content">
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</div>