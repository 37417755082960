import { Component, OnInit } from '@angular/core';
import { Patient, RtmPainFormData, RtmPainFormHeading } from '../rtm-pain.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { RtmPainFormService } from '../rtm-pain.service';

@Component({
  selector: 'app-rtm-pain-form',
  templateUrl: './rtm-pain-form.component.html',
  styleUrls: ['./rtm-pain-form.component.scss']
})
export class RtmPainFormComponent implements OnInit {
  minDate: string;
  todayDate = moment(new Date()).format('MMMM DD yyyy');
  reportingDate: string = new Date().toISOString().split('T')[0];
  id: string = '';
  RTMForm: FormGroup;
  formSubmitted: boolean = false;
  symptomsOther: boolean = true;
  symptomsList = [
    { id: 'symptoms1', value: 'SEDATION', label: 'Sedation' },
    { id: 'symptoms2', value: 'CONSTIPATION', label: 'Constipation' },
    { id: 'symptoms3', value: 'NAUSEA', label: 'Nausea' },
    { id: 'symptoms4', value: 'VOMITING', label: 'Vomiting' },
    { id: 'symptoms5', value: 'DIZZINESS', label: 'Dizziness' },
    { id: 'symptoms6', value: 'SHALLOW_BREATHING', label: 'Shallow breathing' },
    { id: 'symptoms7', value: 'OTHER', label: 'Other' }
  ];

  constructor(
    private rtmPainService: RtmPainFormService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private toastr: ToastrService,
    private router: Router,
    private fb: FormBuilder
  ) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 6);
    this.minDate = yesterday.toISOString().split('T')[0];
  }

  formHeading: RtmPainFormHeading = {
    clinicName: '',
    formName: '',
    createdAt: ''
  }

  patient: Patient = {
    dateOfBirth: '',
    firstName: '',
    lastName: ''
  }

  rtmPainFormData: RtmPainFormData = {
    symptoms: [],
    symptomsOther: '',
    takingDailyMeds: null,
    medsDozes: null,
    medicationWork: null,
    painMedicine: '',
    activeOnMeds: null,
    canDoMoreWithMeds: '',
    craveOpioid: null,
    feelPain: '',
    recentProcedure: null,
    feelMeds: null,
    additionalComments: '',
    id: '',
    dataKey: '',
    resource: '',
    patient: {
      dateOfBirth: '',
      firstName: '',
      lastName: ''
    },
    createdAt: '',
    status: '',
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');

    this.RTMForm = this.fb.group({
      reportingDate: [new Date().toISOString().split('T')[0], [Validators.required]],
      symptoms: [[]],
      symptomsOther: [''],
      takingDailyMeds: [null],
      medsDozes: [null,[Validators.required, Validators.min(0), Validators.max(100)]],
      medicationWork: [null,[Validators.required, Validators.min(0), Validators.max(24)]],
      painMedicine: [''],
      activeOnMeds: [null],
      canDoMoreWithMeds: [''],
      craveOpioid: [null],
      feelPain: [''],
      recentProcedure: [null],
      feelMeds: [null],
      additionalComments: [''],
    });

    this.GetRtmFormdetails();
    this.GetRtmFormHeading();

  }

  GetRtmFormdetails() {
    let dateOfBirth = this.auth.getItem("dateOfBirth");
    this.rtmPainService.GetRtmPainPatient(this.id, this.reportingDate, dateOfBirth).subscribe(
      (results) => {
        this.rtmPainFormData = results;
        this.patient = results.patient;
        this.RTMForm.patchValue(this.rtmPainFormData);
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/questionnaires/rtm-pain/' + this.id]);
      }
    );
  }

  isOtherChecked() {
    const otherCheckbox = this.RTMForm.get('symptoms');
    if (otherCheckbox.value.find((symptom: string) => symptom === 'OTHER')) {
      this.symptomsOther = false;
    } else {
      this.symptomsOther = true;
      this.RTMForm.get('symptomsOther').setValue('');
    }
  }

  addSymptomsControls(isChecked: boolean, i: number) {
    let symptomArray = this.RTMForm.get('symptoms').value;
    const Checkvalue = this.symptomsList[i].value;

    if (isChecked) {
      symptomArray.push(Checkvalue);
    } else {
      symptomArray = symptomArray.filter((item: any) => item !== Checkvalue);
    }
    this.RTMForm.get('symptoms').setValue(symptomArray);
  }

  save() {
    this.formSubmitted = true;
    if (this.RTMForm.valid) {
      this.RTMForm.value["patient"] = this.patient;
      this.RTMForm.value["status"] = 'COMPLEATED';
      this.rtmPainService.SaveRtmPainPatient(
        this.id,
        this.RTMForm.value.reportingDate,
        this.RTMForm.value
      ).subscribe(
        (results: any) => {
          this.router.navigate(['/questionnaires/rtm-pain/'+ this.id +'/thank-you']);
        },
        (error) => {
        }
      );
    } else {
      this.toastr.error(`Please fill the form correctly.`, 'Error');
    }
  }

  GetRtmFormHeading() {
    this.rtmPainService.GetRtmPainHeadingData(this.id).subscribe(
      (results: RtmPainFormHeading) => {
        this.formHeading = results;
      },
      (error) => {
      }
    );
  }

  onDateChange(event: any) {
    const selectedDate = new Date(event.target.value);
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);

    if (selectedDate < sevenDaysAgo) {
      this.RTMForm.get('reportingDate').setValue(sevenDaysAgo.toISOString().substring(0, 10));
    }
  }
}
