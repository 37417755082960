import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { Router } from '@angular/router';
import { User, UserSearch } from '@app/shared/models/user.model';
import { UserService } from '@app/users/user.service';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: '' },
  { path: '/patients', title: 'Patients', icon: 'person', class: '' },
  { path: '/enrollment', title: 'Enrollment', icon: 'person', class: '' },
  { path: '/billing', title: 'Billing', icon: 'person', class: '' },
  { path: '/patients/alltasks', title: 'Tasks', icon: 'person', class: '' },
  { path: '/patients/allmessages', title: 'Messages', icon: 'person', class: '' },
  { path: '/users', title: 'Staff/Users', icon: 'person', class: '' },
  { path: '/users/clinic', title: 'Clinic', icon: 'person', class: '' },
  { path: '/users/profile', title: 'My Profile', icon: 'person', class: '' },
  { path: '/specialitycare', title: 'ECG', icon: 'person', class: '' },
  { path: '/forms', title: 'Forms', icon: 'person', class: '' },
  { path: '/InvitePatients', title: 'Forms', icon: 'person', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  currentUser: User;
  usersManagers: string[] = ["Account Owner", "Administrator", "SysAdmin"]
  canManageUser: boolean = false;

  constructor(private router: Router, private userService: UserService, private authenticationService: AuthenticationService) {

    this.currentUser = this.userService.getCurrentUser();
    if (this.usersManagers.filter(x => x.includes(this.currentUser.role)).length > 0)
      this.canManageUser = true;

  }

  ngOnInit() {
    if (this.canManageUser)
      this.menuItems = ROUTES.filter(menuItem => menuItem);
    else
      this.menuItems = ROUTES.filter(menuItem => menuItem.path != '');

  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
  logout() {

    this.authenticationService.logout();

  }
}
