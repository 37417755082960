import { Component, Input, OnInit } from '@angular/core';
import { AnnualWellnessVisitFormComponent } from '../annual-wellness-visit-form.component';
import { Step3, fullForm } from '../annualwellness.model';
import { ActivatedRoute } from '@angular/router';
import { AnnualWellnessService } from '../annualwellness.service';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-fall-prevention',
  templateUrl: './fall-prevention.component.html',
  styleUrls: ['./fall-prevention.component.scss']
})
export class FallPreventionComponent implements OnInit {
  @Input() isClinicView: boolean = true;
  fullForm: fullForm;
  id: string = '';
  step3: Step3 = {
    haveYouFallen: '',
    sustainInjuries: '',
    feelUnsteady: '',
    wheelchair: '',
    cane: '',
    walkers: '',
    prosthetic: '',
    orthotic: '',
    gaitBalance: '',
    consciousness: '',
    mobility: ''
  };

  constructor(
    private layout: AnnualWellnessVisitFormComponent,
    private route: ActivatedRoute,
    private AnnualWellnessService: AnnualWellnessService,
    private auth: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    if(this.isClinicView){
      this.GetPatientdetails();
    }
    else{
      this.GetDataForClinic();
    }
  }

  GetPatientdetails() {
    let dateOfBirth = this.auth.getItem("dateOfBirth");
    this.AnnualWellnessService.getAWVDataForPatient(this.id, dateOfBirth).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step3 = {
        haveYouFallen: results.step3.haveYouFallen || '',
        sustainInjuries: results.step3.sustainInjuries || '',
        feelUnsteady: results.step3.feelUnsteady || '',
        wheelchair: results.step3.wheelchair || '',
        cane: results.step3.cane || '',
        walkers: results.step3.walkers || '',
        prosthetic: results.step3.prosthetic || '',
        orthotic: results.step3.orthotic || '',
        gaitBalance: results.step3.gaitBalance || '',
        consciousness: results.step3.consciousness || '',
        mobility: results.step3.mobility || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/questionnaires/medical-annual-wellness-visit/'+ this.id]);
      }
    );
  }

  GetDataForClinic() {
    this.AnnualWellnessService.getAWVDataForClinic(this.id).subscribe(
      (results: any) => {
        this.fullForm = results;
        this.step3 = {
        haveYouFallen: results.step3.haveYouFallen || '',
        sustainInjuries: results.step3.sustainInjuries || '',
        feelUnsteady: results.step3.feelUnsteady || '',
        wheelchair: results.step3.wheelchair || '',
        cane: results.step3.cane || '',
        walkers: results.step3.walkers || '',
        prosthetic: results.step3.prosthetic || '',
        orthotic: results.step3.orthotic || '',
        gaitBalance: results.step3.gaitBalance || '',
        consciousness: results.step3.consciousness || '',
        mobility: results.step3.mobility || ''
        };
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/forms']);
      }
    );
  }

  save() {
    this.fullForm.step3 = this.step3;
    this.AnnualWellnessService.submitAWVDataForPatient(
      this.id,
      this.fullForm
    ).subscribe(
      (results) => {
        this.layout.onNextComponentClick();
      },
      (error) => { }
    );
  }
  getData() {
    return this.step3;
  }
}
