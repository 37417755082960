import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { PreopFormHeading } from '../pre-op-assessment.model';
import { PreopAssessmentService } from '../pre-op.service';
import { PreOpAssessmentComponent } from '../pre-op-assessment.component';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pre-op-verify-dob',
  templateUrl: './pre-op-verify-dob.component.html',
  styleUrls: ['./pre-op-verify-dob.component.scss']
})
export class PreOpVerifyDobComponent implements OnInit {
  id: string = '';
  Isreview: boolean = false;
  ISInvalid: boolean = false;
  todayDate = moment(new Date()).format('MMMM DD yyyy');
  dateofbirth: string = new Date().toISOString().split('T')[0];

  constructor(
    private PreOpComponent:PreOpAssessmentComponent,
    private PreopService: PreopAssessmentService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router,
    private datePipe: DatePipe,
  ) { }

 formHeading: PreopFormHeading = {
    clinicName: '',
    form: '',
    formName:'',
    scope: '',
    createdAt: ''
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    this.GetPreopHeadingData();
  }

  onDateInput(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;
    this.dateofbirth = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
  }

  next() {
    this.PreopService.GetPreopPatient(this.id, this.dateofbirth).subscribe(
      (results) => {
        if (results != null || results.id != '') {
          this.auth.setItem('dateOfBirth', this.dateofbirth);
          this.PreOpComponent.onNextComponentClick();
        } else {
          this.ISInvalid = true;
        }
      },
      (error) => {
        if (error.status == 403) {
          this.router.navigate(['/questionnaires/pre-op-assessment-form/'+ this.id +'/thank-you']);
          // this.Isreview = true;
        } else {
          this.ISInvalid = true;
        }
      }
    );
 }

 GetPreopHeadingData() {
  this.PreopService.GetPreopHeading(this.id).subscribe(
    (results: PreopFormHeading) => {
      this.formHeading = results;
    },
    (error) => {
     }
  );
}

}
