import {Component, OnInit} from '@angular/core';
import {MonthlyAsthmaForm, MonthlyFormHeading} from '../monthly-asthma-model';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@app/authentication/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {MonthlyAsthmaControlService} from '../monthly-asthma-service';
import * as moment from 'moment';

@Component({
  selector: 'app-monthly-asthma-control-test-form',
  templateUrl: './monthly-asthma-control-test-form.component.html',
  styleUrls: ['./monthly-asthma-control-test-form.component.scss']
})
export class MonthlyAsthmaControlTestFormComponent implements OnInit {

  todayDate = moment(new Date()).format('MMMM DD yyyy');
  id: string = '';
  month: string;

  constructor(
    private MonthlyAsthmaControlService: MonthlyAsthmaControlService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private toastr: ToastrService,
    private router: Router
  ) {
  }

  MonthlyFormHeading: MonthlyFormHeading = {
    clinicName: '',
    formName: '',
    createdAt: ''
  }

  monthlyAsthmaData: MonthlyAsthmaForm = {
    asthmaImpact: '',
    symptomsFrequency: '',
    symptomsRate: '',
    breathShortnessFrequency: '',
    rescueMedicationFrequency: '',
    patient: {
      dateOfBirth: '',
      firstName: '',
      lastName: ''
    },
    id: '',
    dataKey: '',
    resource: '',
    createdAt: '',
    status: '',
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('invitation-id');
    const currentDate = new Date();
    this.month = moment(new Date()).format('yyyy-MM');
    this.GetHeading();
    this.GetAsthmaPatientdetails();
  }

  GetAsthmaPatientdetails() {
    const dateOfBirth = this.auth.getItem("dateOfBirth");
    this.MonthlyAsthmaControlService.GetMonthlyAsthmaPatient(this.id, this.month, dateOfBirth).subscribe(
      (results: any) => {
        this.monthlyAsthmaData = results;
        this.monthlyAsthmaData.patient = results.patient;
      },
      (error) => {
        console.error('Error fetching patient details:', error);
        this.router.navigate(['/questionnaires/monthly-asthma-control-test/' + this.id]);
      }
    );
  }

  save() {
    this.monthlyAsthmaData.status = 'COMPLEATED';
    this.MonthlyAsthmaControlService.SubmitMonthlyAsthma(
      this.id,
      this.month,
      this.monthlyAsthmaData
    ).subscribe(
      (results: any) => {
        this.router.navigate(['/questionnaires/monthly-asthma-control-test/' + this.id + '/thank-you']);
      },
      (error) => {
      }
    );
  }

  GetHeading() {
    this.MonthlyAsthmaControlService.GetMonthlyHeading(this.id).subscribe(
      (results: MonthlyFormHeading) => {
        this.MonthlyFormHeading = results;
      },
      (error) => {
      }
    );
  }

  getData() {
    return this.monthlyAsthmaData;
  }
}
