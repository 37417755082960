import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '@app/users/user.service';
import { MonthlyAsthmaForm, MonthlyFormHeading } from './monthly-asthma-model';



@Injectable({
  providedIn: 'root'
})
export class MonthlyAsthmaControlService {
  formApiUrl: string;

  constructor(private http: HttpClient, private userService: UserService) {
    this.formApiUrl = environment.formApiUrl;
  }

  GetMonthlyAsthmaPatient(id: string, month: string, dateofbirth: string): Observable<MonthlyAsthmaForm> {
    var url = `${this.formApiUrl}/forms/response/asthma-monthly/get/${id}/${month}`
    let patient = {
      "patient": {
        "dateOfBirth": dateofbirth
        }
      }
    return this.http.post<MonthlyAsthmaForm>(url, patient);
  }

  GetMonthlyHeading(id: string): Observable<MonthlyFormHeading> {
    var url = `${this.formApiUrl}/forms/head/asthma-monthly/${id}`
    return this.http.get<MonthlyFormHeading>(url);
  }

  SubmitMonthlyAsthma(id: string, month: string, formData: MonthlyAsthmaForm): Observable<MonthlyAsthmaForm> {
    var url = `${this.formApiUrl}/forms/response/asthma-monthly/post/${id}/${month}`
    return this.http.post<MonthlyAsthmaForm>(url, formData);
  }

}
