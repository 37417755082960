import { Component,HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-cardiac-screening',
  templateUrl: './cardiac-screening.component.html',
  styleUrls: ['./cardiac-screening.component.scss']
})
export class CardiacScreeningComponent implements OnInit {
  currentIndex = -1;
  components = [
    'cardiac-screening-form'
  ];
  constructor() { }

  ngOnInit(): void {
  }
  @HostListener('window:popstate', ['$event'])
  showComponent(index: number) {
    this.currentIndex = index;
  }

  onNextComponentClick() {
    window.scroll(0, 0);
    if (this.currentIndex < this.components.length - 1) {
        this.showComponent(this.currentIndex + 1);
      }
  }
}
