import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { BaseChartDirective } from 'ng2-charts';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LoaderComponent } from './loader/loader.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { hideDefaultDate } from './pipes/hideDefaultDate.pipe';
import { CanDeactivateGuard } from '../patients/can-deactivate.guard';
import { ComponentCanDeactivate } from './can-deactivate/component-can-deactivate';
import { MinuteSecondsPipe } from './pipes/minuteSeconds.pipe';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { displayNullPipe } from './pipes/displayNull.pipe';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';
import { MatRadioModule } from '@angular/material/radio';
import { displayZeroToDoubleDashPipe } from './pipes/displayZeroToDoubleDash.pipe';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxPrintModule } from 'ngx-print';

import { BrowserModule } from '@angular/platform-browser';
import { FooterComponent } from '@app/components/footer/footer.component';
@NgModule({

  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    BaseChartDirective,
    NgSelectModule,
    MatDialogModule,
    MatCardModule,
    MatDividerModule,
    MatBadgeModule,
    MatRadioModule,
    NgxPrintModule,
    NgxZendeskWebwidgetModule,
    MatAutocompleteModule,
    MaterialFileInputModule,
    MatIconModule,
    DragDropModule

  ],
  declarations: [
    displayNullPipe,
    displayZeroToDoubleDashPipe,
    hideDefaultDate,
    MinuteSecondsPipe,
    PhoneMaskDirective,
    LoaderComponent,
    ConfirmDialogComponent,
    ConfirmationDialogComponent,
    FooterComponent

  ],
  exports: [
    hideDefaultDate,
    MinuteSecondsPipe,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    BaseChartDirective,
    NgSelectModule,
    MatDialogModule,
    PhoneMaskDirective,
    MatCardModule,
    MatDividerModule,
    MatBadgeModule,
    MatRadioModule,
    NgxPrintModule,
    MatIconModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    NgxZendeskWebwidgetModule,
    AutocompleteLibModule,
    MaterialFileInputModule,
    displayZeroToDoubleDashPipe,
    DragDropModule,
    LoaderComponent,
    FooterComponent


  ]
})
export class SharedModule { }
