import { NgModule } from '@angular/core';

import { AnnualWellnessVisitFormComponent } from './annual-wellness-visit-form.component';
import { PatientDetailsComponent } from './patient-details/patient-details.component';
import { MedicationAdherenceComponent } from './medication-adherence/medication-adherence.component';
import { FallPreventionComponent } from './fall-prevention/fall-prevention.component';
import { ComplicationsComponent } from './complications/complications.component';
import { HearingImpairmentComponent } from './hearing-impairment/hearing-impairment.component';
import { DepressionComponent } from './depression/depression.component';
import { NutritionComponent } from './nutrition/nutrition.component';
import { HomeSafetyComponent } from './home-safety/home-safety.component';
import { ChronicConditionsComponent } from './chronic-conditions/chronic-conditions.component';
import { ReferralsComponent } from './referrals/referrals.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { VerifyDOBComponent } from './verify-dob/verify-dob.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
@NgModule({

  declarations: [
    AnnualWellnessVisitFormComponent,
    PatientDetailsComponent,
    MedicationAdherenceComponent,
    FallPreventionComponent,
    ComplicationsComponent,
    HearingImpairmentComponent,
    DepressionComponent,
    NutritionComponent,
    HomeSafetyComponent,
    ChronicConditionsComponent,
    ReferralsComponent,
    VerifyDOBComponent
  ],
  exports: [
    AnnualWellnessVisitFormComponent,
    MedicationAdherenceComponent,
    PatientDetailsComponent,
    FallPreventionComponent,
    ComplicationsComponent,
    HearingImpairmentComponent,
    DepressionComponent,
    NutritionComponent,
    HomeSafetyComponent,
    ChronicConditionsComponent,
    ReferralsComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class AnnualWellnessVisitFormModule { }
