import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormHeading, CCMFormData } from './ccm-self-reporting.model';



@Injectable({
  providedIn: 'root'
})

export class CcmSelfReportingService {
  formApiUrl: string;

  constructor(private http: HttpClient) {
    this.formApiUrl = environment.formApiUrl;
  }

  GetCCMForPatient(id: string, date: string, dateofbirth: string): Observable<CCMFormData> {
    var url = `${this.formApiUrl}/forms/response/ccm-daily/get/${id}/${date}`
    let patient = {
      "patient": {
        "dateOfBirth": dateofbirth
        }
      }
    return this.http.post<CCMFormData>(url, patient);
  }

  GetCCMHeadingData(id: string): Observable<FormHeading> {
    var url = `${this.formApiUrl}/forms/head/ccm-daily/${id}`
    return this.http.get<FormHeading>(url);
  }

  SubmitCCMForPatient(id: string, date: string, formData: CCMFormData): Observable<CCMFormData> {
    var url = `${this.formApiUrl}/forms/response/ccm-daily/post/${id}/${date}`
    return this.http.post<CCMFormData>(url, formData);
  }

}
