<div class="wrap-box" *ngIf="!isClinicView">
  <h2 class="blue-header">Annual review of chronic conditions</h2>
</div>
<div class="" *ngIf="!isClinicView">
  <div class="wrap-box">
    <label class="label-font-14">Also comment in plan if applicable</label>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck1" name="paraplegia" [(ngModel)]="step9.paraplegia" ></div>
      <label for="AnnualCheck1">G82.2 - Paraplegia</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck2" name="quadriplegia" [(ngModel)]="step9.quadriplegia" ></div>
      <label for="AnnualCheck2">G82.5 - Quadriplegia</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck3" name="rheumatoid" [(ngModel)]="step9.rheumatoid"></div>
      <label for="AnnualCheck3">M05.9 - Rheumatoid arthritis</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck4" name="tremor" [(ngModel)]="step9.tremor"></div>
      <label for="AnnualCheck4">R25.1 - Tremor, unspecified</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck5" name="parkinsonsDisease" [(ngModel)]="step9.parkinsonsDisease" ></div>
      <label for="AnnualCheck5">G20 - Parkinson's disease</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck6" name="convulsions" [(ngModel)]="step9.convulsions"></div>
      <label for="AnnualCheck6">R56.9 - Unspecified convulsions</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck7" name="elpilepsy" [(ngModel)]="step9.elpilepsy"></div>
      <label for="AnnualCheck7">G40.9 - Epilepsy, unspecified</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck8" name="anginaPectoris" [(ngModel)]="step9.anginaPectoris" ></div>
      <label for="AnnualCheck8">l20 - Angina pectoris</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck9" name="hivInfection" [(ngModel)]="step9.hivInfection" ></div>
      <label for="AnnualCheck9">Z21- Asymptomatic human immunodeficiency virus
        [HIV] infection</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck10" name="viralHepatitis" [(ngModel)]="step9.viralHepatitis"></div>
      <label for="AnnualCheck10">B18 - Chronic viral hepatitis</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck11" name="purpura" [(ngModel)]="step9.purpura" ></div>
      <label for="AnnualCheck11">D69.2 - Other nonthrombocytopenic purpura</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck12" name="atheroscleroticHeart" [(ngModel)]="step9.atheroscleroticHeart" ></div>
      <label for="AnnualCheck12">125.119 - Atherosclerotic heart disease native coronary
        artery with unspecified angina pectoris</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck13" name="atrialFibrillation" [(ngModel)]="step9.atrialFibrillation" ></div>
      <label for="AnnualCheck13">148.91 - Unspecified atrial fibrillation</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck14" name="cardiacArrhyrhmia" [(ngModel)]="step9.cardiacArrhyrhmia"></div>
      <label for="AnnualCheck14">l49.9 - Cardiac arrhythmia, unspecified</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck15" name="heartTransplant" [(ngModel)]="step9.heartTransplant" ></div>
      <label for="AnnualCheck15">Z94.1 - Heart transplant</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck16" name="lungTransplant" [(ngModel)]="step9.lungTransplant" ></div>
      <label for="AnnualCheck16">Z94.2 - Lung transplant</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck17" name="liverTransplant" [(ngModel)]="step9.liverTransplant" ></div>
      <label for="AnnualCheck17">Z94.4 - Liver transplant</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck18" name="tracheostomy" [(ngModel)]="step9.tracheostomy"></div>
      <label for="AnnualCheck18">Z93.0 - Tracheostomy</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck19" name="gastrostomy" [(ngModel)]="step9.gastrostomy"></div>
      <label for="AnnualCheck19">Z93.1 - Gastrostomy</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck20" name="colostomy" [(ngModel)]="step9.colostomy" ></div>
      <label for="AnnualCheck20">Z93.3 - Colostomy</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck21" name="absenceToe" [(ngModel)]="step9.absenceToe" ></div>
      <label for="AnnualCheck21">Z89.4 - Acquired absence of toe(s), foot, and ankle</label>
    </div>
  </div>
  <div class="wrap-box">
    <div class="wrap-radio">
      <div><input type="checkbox" id="AnnualCheck22" name="absenceLog" [(ngModel)]="step9.absenceLog"></div>
      <label for="AnnualCheck22">Z89.5 - Acquired absence of leg below knee</label>
    </div>
  </div>

</div>
<div class="wrap-box">
  <h2 class="blue-header">Plan of care</h2>
</div>
<div class="">
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Assessment</label>
    </div>

    <textarea class="custom-input" name="assessment" [(ngModel)]="step9.assessment"></textarea>
  </div>
  <div class="wrap-box">
    <div>
      <label class="label-font-14">Plan</label>
    </div>
    <textarea class="custom-input" name="plan" [(ngModel)]="step9.plan"></textarea>
  </div>
</div>
<div class="wrap-box" *ngIf="isClinicView">
  <button class="btn-save" (click)="save()">Save and continue</button>
</div>
