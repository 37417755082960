import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy, PopStateEvent } from '@angular/common';
import 'rxjs/add/operator/filter';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import PerfectScrollbar from 'perfect-scrollbar';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-user-layout',
    templateUrl: './user-layout.component.html',
    styleUrls: ['./user-layout.component.scss']
})
export class UserLayoutComponent implements OnInit {
    private _router: Subscription;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];

    constructor(public location: Location, private router: Router) { }

    ngOnInit() {
        const isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

        if (isWindows && !document.getElementsByTagName('body')[0].classList.contains('sidebar-mini')) {
            // if we are on windows OS we activate the perfectScrollbar function

            document.getElementsByTagName('body')[0].classList.add('perfect-scrollbar-on');
        } else {
            document.getElementsByTagName('body')[0].classList.remove('perfect-scrollbar-off');
        }
        const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
        const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');

        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                if (event.url != this.lastPoppedUrl)
                    this.yScrollStack.push(window.scrollY);
            } else if (event instanceof NavigationEnd) {
                if (event.url == this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                } else
                    window.scrollTo(0, 0);
            }
        });
        this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            elemMainPanel.scrollTop = 0;
            elemSidebar.scrollTop = 0;
        });
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            let ps = new PerfectScrollbar(elemMainPanel);
            ps = new PerfectScrollbar(elemSidebar);
        }

        const windowWidth: number = window.innerWidth;
        let sidebarElement: HTMLElement | null = document.querySelector('.sidebar');
        let sidebarResponsiveElement: HTMLElement | null = document.querySelector('body > .navbar-collapse');
        let sidebarImgContainerElement: HTMLElement | null = sidebarElement?.querySelector('.sidebar-background');

        if (windowWidth > 767) {
            const fixedPluginDropdown: HTMLElement | null = document.querySelector('.fixed-plugin .dropdown');
            if (fixedPluginDropdown?.classList.contains('show-dropdown')) {
                fixedPluginDropdown.classList.add('open');
            }
        }

        const fixedPluginLink: HTMLElement | null = document.querySelector('.fixed-plugin a');
        fixedPluginLink?.addEventListener('click', (event: MouseEvent) => {
            // Alex if we click on switch, stop propagation of the event, so the dropdown will not be hide, otherwise we set the  section active
            const eventTarget = event.currentTarget as HTMLElement;
            if (eventTarget.classList.contains('switch-trigger')) {
                event.stopPropagation();
            }
        });

        const fixedPluginBadges: NodeListOf<HTMLElement> = document.querySelectorAll('.fixed-plugin .badge');
        fixedPluginBadges.forEach((badge) => {
            badge.addEventListener('click', () => {
                const fullPageBackgroundElement: HTMLElement | null = document.querySelector('.full-page-background');

                fixedPluginBadges.forEach((b) => b.classList.remove('active'));
                badge.classList.add('active');

                const newColor = badge.dataset.color;

                if (sidebarElement) {
                    sidebarElement.setAttribute('data-color', newColor!);
                }

                if (sidebarResponsiveElement) {
                    sidebarResponsiveElement.setAttribute('data-color', newColor!);
                }
            });
        });

        const fixedPluginImgHolders: NodeListOf<HTMLElement> = document.querySelectorAll('.fixed-plugin .img-holder');
        fixedPluginImgHolders.forEach((imgHolder) => {
            imgHolder.addEventListener('click', () => {
                const fullPageBackgroundElement: HTMLElement | null = document.querySelector('.full-page-background');

                fixedPluginImgHolders.forEach((h) => h.parentElement?.classList.remove('active'));
                imgHolder.parentElement?.classList.add('active');

                const newImage = imgHolder.querySelector('img')?.getAttribute('src');

                if (sidebarImgContainerElement) {
                    sidebarImgContainerElement.style.display = 'none';
                    sidebarImgContainerElement.style.backgroundImage = `url("${newImage}")`;
                    sidebarImgContainerElement.style.display = 'block';
                }

                if (fullPageBackgroundElement) {
                    fullPageBackgroundElement.style.display = 'none';
                    fullPageBackgroundElement.style.backgroundImage = `url("${newImage}")`;
                    fullPageBackgroundElement.style.display = 'block';
                }

                if (sidebarResponsiveElement) {
                    sidebarResponsiveElement.style.backgroundImage = `url("${newImage}")`;
                }
            });
        });
    }
    ngAfterViewInit() {
        this.runOnRouteChange();
    }
    isMaps(path) {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        titlee = titlee.slice(1);
        if (path == titlee) {
            return false;
        }
        else {
            return true;
        }
    }
    runOnRouteChange(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
            const ps = new PerfectScrollbar(elemMainPanel);
            ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

}
