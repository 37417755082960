<div class="sidebar ps" data-color="azure" data-background-color="lightgray" data-image="../assets/img/sidebar.jpg">

  <div class="logo">
    <a class="simple-text logo-mini" href="#">
      <div class="logo-img"><img src="./assets/img/logo2.png" /></div>
    </a>
    <a href="#" class="simple-text logo-normal">
      Lara Health
    </a>
  </div>
  <div class="sidebar-wrapper">
    <div class="user">
      <div class="photo">
        <img src="../assets/img/avatar.png" />
      </div>
      <div class="user-info">
        <a data-toggle="collapse" href="#collapseExample" class="username">
          <span *ngIf="currentUser">
            {{currentUser.displayName}}
            <b class="caret"></b>
          </span>
        </a>
        <div class="collapse" id="collapseExample">
          <ul class="nav">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/users/clinic']">
                <span class="sidebar-mini"> CP </span>
                <span class="sidebar-normal"> Clinic profile </span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link isDisabled">
                <span class="sidebar-mini"> MP </span>
                <span class="sidebar-normal"> My profile </span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="logout()">
                <span class="sidebar-mini"> LO </span>
                <span class="sidebar-normal"> Logout </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <ul class="nav">
      <li routerLinkActive="active" class="nav-item " [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" [routerLink]="['/']">
          <i class="material-icons">dashboard</i>
          <p>Dashboard</p>
        </a>
      </li>
      <li routerLinkActive="active" class="nav-item" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" [routerLink]="['/patients']">
          <i class="material-icons">group</i>
          <p>
            Patients
            <!--<span class="badge badge-info right ">New</span> -->
          </p>
        </a>
      </li>
      <li routerLinkActive="active" class="nav-item">
        <a class="nav-link" [routerLink]="['/enrollment']">
          <i class="material-icons">group_add</i>
          <p>Enrollment</p>
        </a>
      </li>
      <li routerLinkActive="active" class="nav-item">
        <a class="nav-link" [routerLink]="['/patients/allmessages']">
          <i class="material-icons">email</i>
          <p>
            Messages
            <!--
            <span class="badge badge-info right ">New</span> -->
          </p>
        </a>
      </li>
      <li routerLinkActive="active" class="nav-item" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" [routerLink]="['/patients/alltasks']">
          <i class="material-icons">assignment</i>
          <p>
            Tasks
            <!-- <span class="badge badge-info right ">New</span> -->
          </p>
        </a>
      </li>
      <li routerLinkActive="active" class="nav-item">
        <a class="nav-link" [routerLink]="['/billing']">
          <i class="material-icons">monetization_on</i>
          <p>
            Billing
            <span class="badge badge-warning right "></span>
          </p>
        </a>
      </li>
      <li routerLinkActive="active" class="nav-item">
        <a class="nav-link" [routerLink]="['/forms']">
          <i class="material-icons">date_range</i>
          <p>
           Forms
            <span class="badge badge-warning right "></span>
          </p>
        </a>
      </li>
      <li routerLinkActive="active" class="nav-item" *ngIf="canManageUser">
        <a class="nav-link" [routerLink]="['/users']">
          <i class="material-icons">contacts</i>
          <p>Staff / Users</p>
        </a>
      </li>
      <li routerLinkActive="active" class="nav-item" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" [routerLink]="['/specialitycare']">
          <i class="material-icons">
            monitor_heart
          </i>
          <p>
            Speciality Care
            <!-- <span class="badge badge-info right ">New</span> -->
          </p>
        </a>
      </li>
      <!--   <li class="nav-item">
        <a class="nav-link" [routerLink]="['/']">
          <i class="material-icons">description</i>
          <p>Documents</p>
        </a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link " href="https://larahealth.zendesk.com/ " target="_blank">
          <i class="material-icons">help</i>
          <p>FAQ/Support</p>
        </a>
      </li>
    </ul>
  </div>
  <div class="sidebar-background" style="background-image: url(../assets/img/sidebar.jpg) "></div>
</div>
