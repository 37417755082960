import { Injectable } from '@angular/core';
import { User, UserModel, UserSearch } from '../shared/models/user.model';
import { createUsers } from '../testing/users.data';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  patientUrl = environment.patientUrl;
  userBaseUrl = environment.userApiUrl;

  users: User[] = [];
  private readonly CLINICS = 'CLINICS';
  private readonly ClinicDetails = 'ClinicDetails';
  private readonly CURRENT_USER = 'currentUser';
  userUrl: string;
  clinicUrl: string;
  refUrl: string;

  constructor(private http: HttpClient) {

      this.refUrl = `${this.patientUrl}/api/ref`

  }
  storeClinics(clinics: string) {
    localStorage.setItem(this.CLINICS, clinics);
    this.setClinicDetails();
  }

  getClinics() {
    return JSON.parse(localStorage.getItem(this.CLINICS));
  }

  getCurrentClinic(): string {
    return JSON.parse(localStorage.getItem(this.CLINICS))[0].Code;
  }

  getClinicDetails() {

    //  return this.http.get(this.patientUrl + "/api/clinic/"+this.getCurrentClinic());
    var cachedClinic = JSON.parse(localStorage.getItem(this.ClinicDetails));
    if (cachedClinic) {
      let observerResult = new Observable(o => {

        ;
        o.next(cachedClinic);
        o.complete();
      });
      return observerResult;
    }
    else {
      this.clinicUrl = `${this.patientUrl}/api/clinic/${this.getCurrentClinic()}`;
      return this.http.get(this.clinicUrl);
    }

  }

  setClinicDetails() {
    this.clinicUrl = `${this.patientUrl}/api/clinic/${this.getCurrentClinic()}`;
    this.http.get(this.clinicUrl).subscribe(
      data => {
        if (data) {
          localStorage.setItem(this.ClinicDetails, JSON.stringify(data));
        }
      });

  }

  getUsers(userSearch: UserSearch, pageIndex = 0, pageSize = 10, sort: any, searchTerm: string, skipPagniation: boolean): Observable<any> {

    var _pageIndex = pageIndex + 1;

    var _sort = "firstName";
    var _direction = ""
    if (sort && sort.active) {
      _sort = sort.active;
      if (sort._direction)
        _direction = sort._direction == "asc" ? "" : "-";
    }
    this.userUrl = `${this.patientUrl}/api/clinic/${this.getCurrentClinic()}/user`;

    var url = `${this.userUrl}/all?PageSortModel.PageSize=${pageSize}&PageSortModel.PageNumber=${_pageIndex}&PageSortModel.Sort=${_direction}${_sort}&PageSortModel.SearchTerm=${searchTerm}&skipPagniation=${skipPagniation}`;

    return this.http.get(url);

  }


  getRoleslist(): Observable<any> {
    var url = `${this.refUrl}/role?PageSortModel.PageSize=100&PageSortModel.PageNumber=1&PageSortModel.Sort=-name`;
    return this.http.get(url);
  }
  getJobtitlelist(): Observable<any> {
    var url = `${this.refUrl}/jobtitle?PageSortModel.PageSize=100&PageSortModel.PageNumber=1&PageSortModel.Sort=-name`;
    return this.http.get(url);
  }


  getUser(id: any) {
    let observerResult = new Observable(o => {
      const res: any = this.users.find(x => x.id == id);
      o.next(res);
      o.complete();
    });
    return observerResult;

  }

  saveUser(user: UserModel) {
    this.userUrl = `${this.patientUrl}/api/clinic/${this.getCurrentClinic()}/user`;
    return this.http.post(this.userUrl,
      {
        "firstName": user.firstName,
        "lastName": user.lastName,
        "email": user.email,
        "phoneNo": user.phoneNo,
        "jobtitleId": user.jobtitleId,
        "roleId": user.roleId,
        "isMFA": user.isMFA
      });
  }

  updateUser(user: UserModel) {
    this.userUrl = `${this.patientUrl}/api/clinic/${this.getCurrentClinic()}/user`;
    return this.http.put(`${this.userUrl}/${user.externalId}`,
      {
        "firstName": user.firstName,
        "lastName": user.lastName,
        "email": user.email,
        "phoneNo": user.phoneNo,
        "jobtitleId": user.jobtitleId,
        "roleId": user.roleId,
        "isMFA": user.isMFA,
        "statusId": user.statusId
      });
  }


  getCurrentUser() {
    var currentUser = localStorage.getItem(this.CURRENT_USER)
    // console.log(currentUser);
    return JSON.parse(currentUser) as User;
  }
  setCurrentUser(user: any) {
    localStorage.setItem(this.CURRENT_USER, JSON.stringify(user));
  }
  removeCurrentUser() {
    localStorage.removeItem(this.CURRENT_USER);
  }


}
