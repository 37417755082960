import { Component } from '@angular/core';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {

  constructor(private _ngxZendeskWebwidgetService: NgxZendeskWebwidgetService) {
  }
  title = 'LaraHealth';
}
