import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@app/users/user.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    dashboardSummaryBS$ = new BehaviorSubject<any>(null);

    dashboardUrl: string;
    patientBaseUrl = environment.patientUrl


    constructor(private http: HttpClient, private userService: UserService) {

   //     this.dashboardUrl = `${this.patientBaseUrl}/api/clinic/${this.userService.getCurrentClinic()}/dashboardsummary`;

    }

    getDashboardSummary(): Observable<any> {
        this.dashboardUrl = `${this.patientBaseUrl}/api/clinic/${this.userService.getCurrentClinic()}/dashboardsummary`;
        return this.http.get(this.dashboardUrl);

    }
    getDashboardSummaryFromCache(summary: any) {
        this.dashboardSummaryBS$.next(summary);
    }


}